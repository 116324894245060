/* <editor-fold desc="@group Webix"> */
input::-ms-clear { display: none; }
.webix_view {
  font-family: StolzlRegular,sans-serif;
  font-size: 14px;
  color: #354866;
  -webkit-font-smoothing: antialiased;
  cursor: default;
  overflow: hidden;
  border: 0px solid #e1e1e1;
  background-color: #ffffff;
  white-space: normal;
  -webkit-appearance: none;
}
.webix_view.webix_form {
  background-color: #f6f6f6;
  /*.webix_view{
		background-color: @backColor;
	}*/
}
.webix_view.webix_fieldset
{
  background-color: #f6f6f6;
}
body {
  -webkit-tap-highlight-color: transparent !important;
}
/*
	webix_layout_{type}
	known types are: line, clean, wide, space;
*/
.webix_abslayout {
  position: relative;
}
.webix_abslayout > .webix_view {
  position: absolute;
}
.webix_layout_space {
  background-color: #f6f6f6;
}
.webix_layout_wide {
  background-color: #f6f6f6;
}
.webix_layout_line,
.webix_multiview,
.webix_layout_clean,
.webix_layout_accordion {
  background: transparent;
}
.webix_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 81;
  text-align: center;
  /*   padding-top: 10px; */
  background-color: rgba(255,255,255,0.7);
}
.webix_view > .webix_disabled {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ddd;
  opacity: 0.4;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=40);
  z-index: 1;
}
.webix_disabled_view {
  overflow: hidden !important;
  position:relative;
}
body.webix_full_screen {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.webix_noselect,
.webix_noselect div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_selectable {
  -moz-user-select: text;
  -webkit-user-select: text;
  user-select: text;
}
.webix_template {
  height: 100%;
  /* padding: 5px; */
  /*padding-left: 5px 2px;*/
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_clean {
  background: transparent;
}
.webix_clean .webix_template {
  padding: 0px;
}
.webix_section {
  padding-left: 30px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAUCAIAAADDbMD2AAAABnRSTlMA/wD/AP83WBt9AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAHElEQVR4nGP4//8/EwMDA158/vx5BmLUMTAwAADGhQiMQPqPwgAAAABJRU5ErkJggg==");
  background-repeat: repeat-x;
  background-position: 11.25px;
  color: #444;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 45px;
}
.webix_section > .webix_template {
  padding: 0px 8px;
  background-color: #ffffff;
  display: inline;
}
.webix_layout_space > .webix_section > .webix_template {
  padding: 0px 8px;
  background-color: #dddddd;
}
.webix_layout_wide > .webix_section > .webix_template {
  padding: 0px 8px;
  background-color: #dddddd;
}
.webix_layout_line > .webix_section > .webix_template,
.webix_layout_clean > .webix_section > .webix_template {
  padding: 0px 8px;
  background-color: #ffffff;
}
.webix_header > div {
  padding-left: 10px;
  color: #ffffff;
  font-size: 18px;
  background: #3498db;
  /*.webix_el_button input, .webix_el_toggle input {
		border: none;

	}*/
  /*.webixtype_prev:active, .webixtype_next:active, .webixtype_base:active, .webix_inp_counter_next:active, .webix_inp_counter_prev:active{
		background-color: @barButtonActiveColor;
	}*/
  line-height: 43px;
  line-height: 45px;
  padding-top: 0;
  padding-bottom: 0;
}
.webix_header > div .webix_el_button,
.webix_header > div .webix_el_label,
.webix_header > div .webix_inp_label {
  color: #ffffff;
}
.webix_header > div .webix_el_label {
  font-size: 18px;
}
.webix_header > div .webixtype_base,
.webix_header > div .webixtype_prev,
.webix_header > div .webixtype_next,
.webix_header > div .webix_inp_counter_next,
.webix_header > div .webix_inp_counter_prev,
.webix_header > div .webix_segment_0,
.webix_header > div .webix_segment_1,
.webix_header > div .webix_segment_N {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
}
.webix_header > div .webix_el_arrow.webixtype_next_arrow {
  border-left: none;
  border-bottom: none;
}
.webix_header > div .webix_el_arrow.webixtype_prev_arrow {
  border-right: none;
  border-bottom: none;
}
.webix_header > div .webixtype_base:hover,
.webix_header > div .webix_inp_counter_next:hover,
.webix_header > div .webix_inp_counter_prev:hover,
.webix_header > div .webix_segment_0:hover,
.webix_header > div .webix_segment_1:hover,
.webix_header > div .webix_segment_N:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_header > div .webixtype_base:active,
.webix_header > div .webix_inp_counter_next:active,
.webix_header > div .webix_inp_counter_prev:active,
.webix_header > div .webix_segment_0:active,
.webix_header > div .webix_segment_1:active,
.webix_header > div .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_header > div .webixtype_base:focus,
.webix_header > div .webix_inp_counter_next:focus,
.webix_header > div .webix_inp_counter_prev:focus,
.webix_header > div .webix_segment_0:focus,
.webix_header > div .webix_segment_1:focus,
.webix_header > div .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
}
.webix_header > div .webix_inp_static,
.webix_header > div input[type=text] {
  border-color: #ffffff;
}
.webix_header > div .webix_inp_static:focus,
.webix_header > div input[type=text]:focus {
  border-color: #ffffff;
  box-shadow: none;
}
.webix_header > div .webix_input_icon {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
  color: #666666;
}
.webix_header > div .webix_input_icon:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_header > div .webix_input_icon:active,
.webix_header > div .webix_input_icon:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_header > div .webix_pressed .webixtype_base,
.webix_header > div .webix_pressed .webixtype_prev,
.webix_header > div .webix_pressed .webixtype_next,
.webix_header > div .webix_pressed .webix_el_arrow,
.webix_header > div .webix_pressed .webix_inp_counter_next,
.webix_header > div .webix_pressed .webix_inp_counter_prev,
.webix_header > div .webix_pressed .webix_input_icon,
.webix_header > div .webix_pressed .webix_segment_0,
.webix_header > div .webix_pressed .webix_segment_1,
.webix_header > div .webix_pressed .webix_segment_N {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_header > div .webix_selected.webix_segment_0,
.webix_header > div .webix_selected.webix_segment_1,
.webix_header > div .webix_selected.webix_segment_N {
  border-color: #d9d9d9;
  background-color: #27ae60;
  color: #ffffff;
}
.webix_header > div .webix_selected.webix_segment_0:hover,
.webix_header > div .webix_selected.webix_segment_1:hover,
.webix_header > div .webix_selected.webix_segment_N:hover {
  background-color: #24a259;
}
.webix_header > div .webix_selected.webix_segment_0:active,
.webix_header > div .webix_selected.webix_segment_1:active,
.webix_header > div .webix_selected.webix_segment_N:active {
  background-color: #229955;
}
.webix_header > div .webix_selected.webix_segment_0:focus,
.webix_header > div .webix_selected.webix_segment_1:focus,
.webix_header > div .webix_selected.webix_segment_N:focus {
  background-color: #229955;
}
.webix_header > div .webix_segment_1,
.webix_header > div .webix_segment_N,
.webix_header > div .webix_selected.webix_segment_1,
.webix_header > div .webix_selected.webix_segment_N {
  margin-left: 0;
  border-left: none;
}
.webix_header > div .webix_img_btn:active,
.webix_header > div .webix_img_btn_top:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_header > div .webix_img_btn:focus,
.webix_header > div .webix_img_btn_top:focus {
  background-color: #2489cc;
}
.webix_header > div .webix_img_btn:hover,
.webix_header > div .webix_img_btn_top:hover {
  background-color: #268fd5;
}
.webix_header > div .webix_icon_button {
  border-radius: 6px;
}
.webix_header > div .webix_img_btn_top .webix_img_btn_text,
.webix_header > div .webix_img_btn_top .webix_icon,
.webix_header > div .webix_el_icon,
.webix_header > div .webix_img_btn,
.webix_header > div .webix_icon_btn,
.webix_header > div .webix_icon_button {
  color: #ffffff;
}
.webix_header > div .webix_img_btn_abs .webix_icon,
.webix_header > div .webix_img_btn_abs .webix_img_btn_text {
  color: #ffffff;
}
.webix_header > div .webix_img_btn_abs {
  color: #ffffff;
}
.webix_header > div .webix_el_box .webix_img_btn_abs:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_header > div .webix_el_icon .webix_icon_button:focus {
  background-color: rgba(0, 0, 0, 0.07);
}
.webix_header > div .webix_el_box .webix_img_btn_abs:hover,
.webix_header > div .webix_el_icon .webix_icon_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_header > div .webix_control {
  border-color: #3498db;
}
.webix_header > div .webix_icon_button .webix_icon {
  font-size: 23px;
  height: 27px;
  width: 27px;
  color: #ffffff;
}
.webix_header > div .webix_img_btn,
.webix_header > div .webix_img_btn_top {
  border-width: 0;
}
.webix_spacer {
  background: #f6f6f6;

}
.webix_view_animate {
  position: relative;
  width: 100%;
  height: 100%;
}
x\:ui {
  display: none;
}
.webix_flexlayout {
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
}
.webix_flexlayout > div {
  display: block !important;
}
.webix_view_align {
  background: transparent;
}
/*Focusing*/
.webix_view div:focus,
.webix_view span:focus,
.webix_view a.webix_list_item:focus,
.webix_view.webix_window:focus {
  outline-color: #bfbfbf;
  outline-style: dotted;
  outline-width: 1px;
}
textarea,
input,
select,
button,
.webix_view div.webix_inp_static:focus {
  outline: none;
}
.webix_view div:active,
.webix_view span:active,
.webix_view a.webix_list_item:active {
  outline: none;
}
.webix_resize_area {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.webix_resize_handle_x .webix_handle_content,
.webix_resize_handle_y .webix_handle_content {
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.1);
  border: 1px dotted #ebebeb;
}
.webix_resize_handle_x {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: e-resize;
  width: 1px;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0px;
}
.webix_resize_handle_x .webix_handle_content {
  border-width: 0px 1px;
}
.webix_resize_origin_x {
  cursor: e-resize;
  width: 3px;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0px;
}
.webix_resize_handle_y {
  cursor: n-resize;
  height: 1px;
  width: 100%;
  position: absolute;
  z-index: 2;
  left: 0px;
}
.webix_resize_handle_y .webix_handle_content {
  border-width: 1px 0px;
}
.webix_resize_origin_y {
  cursor: n-resize;
  height: 3px;
  width: 100%;
  position: absolute;
  z-index: 2;
  left: 0px;
}
.webix_resizer.webix_disabled_view {
  cursor: default;
}
.webix_resizer_vy {
  cursor: n-resize;
  margin-top: 0px !important;
  border: 0px !important;
  padding: 0px !important;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAADAQMAAABCowZuAAAABlBMVEUAAACkvtSA7tmIAAAAAXRSTlMAQObYZgAAAA9JREFUCB1jXMUABoxAGgAJaAFXPIkJqAAAAABJRU5ErkJggg==") no-repeat center center;
}
.webix_resizer_vx {
  cursor: e-resize;
  margin-left: 0px !important;
  border: 0px !important;
  padding: 0px !important;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAXAQMAAAD0oWdxAAAABlBMVEUAAACkvtSA7tmIAAAAAXRSTlMAQObYZgAAAA5JREFUeF5jWMDAQDwGAKyuB4FpGQdzAAAAAElFTkSuQmCC") no-repeat center center;
}
.webix_resizer_y {
  cursor: n-resize;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAADAQMAAABCowZuAAAABlBMVEUAAACkvtSA7tmIAAAAAXRSTlMAQObYZgAAAA9JREFUCB1jXMUABoxAGgAJaAFXPIkJqAAAAABJRU5ErkJggg==") no-repeat center center;
  background-color: #f6f6f6;
}
.webix_resizer_x {
  cursor: e-resize;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAXAQMAAAD0oWdxAAAABlBMVEUAAACkvtSA7tmIAAAAAXRSTlMAQObYZgAAAA5JREFUeF5jWMDAQDwGAKyuB4FpGQdzAAAAAElFTkSuQmCC") no-repeat center center;
  background-color: #f6f6f6;
}
.webix_accordionitem_body {
  position: relative;
}
.webix_accordionitem_header {
  border-bottom: 1px solid #dddddd;
  position: relative;
  z-index: 2;
  color: #354866;
  font-size: 16px;
  background: #f6f6f6;
  /*.webix_el_button input, .webix_el_toggle input {
		border: none;

	}*/
  /*.webixtype_prev:active, .webixtype_next:active, .webixtype_base:active, .webix_inp_counter_next:active, .webix_inp_counter_prev:active{
		background-color: @barButtonActiveColor;
	}*/
  line-height: 27px;
  cursor: pointer;
  overflow: hidden;
}
.webix_accordionitem_header .webix_el_button,
.webix_accordionitem_header .webix_el_label,
.webix_accordionitem_header .webix_inp_label {
  color: #ffffff;
}
.webix_accordionitem_header .webix_el_label {
  font-size: 18px;
}
.webix_accordionitem_header .webixtype_base,
.webix_accordionitem_header .webixtype_prev,
.webix_accordionitem_header .webixtype_next,
.webix_accordionitem_header .webix_inp_counter_next,
.webix_accordionitem_header .webix_inp_counter_prev,
.webix_accordionitem_header .webix_segment_0,
.webix_accordionitem_header .webix_segment_1,
.webix_accordionitem_header .webix_segment_N {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
}
.webix_accordionitem_header .webix_el_arrow.webixtype_next_arrow {
  border-left: none;
  border-bottom: none;
}
.webix_accordionitem_header .webix_el_arrow.webixtype_prev_arrow {
  border-right: none;
  border-bottom: none;
}
.webix_accordionitem_header .webixtype_base:hover,
.webix_accordionitem_header .webix_inp_counter_next:hover,
.webix_accordionitem_header .webix_inp_counter_prev:hover,
.webix_accordionitem_header .webix_segment_0:hover,
.webix_accordionitem_header .webix_segment_1:hover,
.webix_accordionitem_header .webix_segment_N:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_accordionitem_header .webixtype_base:active,
.webix_accordionitem_header .webix_inp_counter_next:active,
.webix_accordionitem_header .webix_inp_counter_prev:active,
.webix_accordionitem_header .webix_segment_0:active,
.webix_accordionitem_header .webix_segment_1:active,
.webix_accordionitem_header .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_accordionitem_header .webixtype_base:focus,
.webix_accordionitem_header .webix_inp_counter_next:focus,
.webix_accordionitem_header .webix_inp_counter_prev:focus,
.webix_accordionitem_header .webix_segment_0:focus,
.webix_accordionitem_header .webix_segment_1:focus,
.webix_accordionitem_header .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
}
.webix_accordionitem_header .webix_inp_static,
.webix_accordionitem_header input[type=text] {
  border-color: #ffffff;
}
.webix_accordionitem_header .webix_inp_static:focus,
.webix_accordionitem_header input[type=text]:focus {
  border-color: #ffffff;
  box-shadow: none;
}
.webix_accordionitem_header .webix_input_icon {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
  color: #666666;
}
.webix_accordionitem_header .webix_input_icon:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_accordionitem_header .webix_input_icon:active,
.webix_accordionitem_header .webix_input_icon:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_accordionitem_header .webix_pressed .webixtype_base,
.webix_accordionitem_header .webix_pressed .webixtype_prev,
.webix_accordionitem_header .webix_pressed .webixtype_next,
.webix_accordionitem_header .webix_pressed .webix_el_arrow,
.webix_accordionitem_header .webix_pressed .webix_inp_counter_next,
.webix_accordionitem_header .webix_pressed .webix_inp_counter_prev,
.webix_accordionitem_header .webix_pressed .webix_input_icon,
.webix_accordionitem_header .webix_pressed .webix_segment_0,
.webix_accordionitem_header .webix_pressed .webix_segment_1,
.webix_accordionitem_header .webix_pressed .webix_segment_N {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_accordionitem_header .webix_selected.webix_segment_0,
.webix_accordionitem_header .webix_selected.webix_segment_1,
.webix_accordionitem_header .webix_selected.webix_segment_N {
  border-color: #d9d9d9;
  background-color: #27ae60;
  color: #ffffff;
}
.webix_accordionitem_header .webix_selected.webix_segment_0:hover,
.webix_accordionitem_header .webix_selected.webix_segment_1:hover,
.webix_accordionitem_header .webix_selected.webix_segment_N:hover {
  background-color: #24a259;
}
.webix_accordionitem_header .webix_selected.webix_segment_0:active,
.webix_accordionitem_header .webix_selected.webix_segment_1:active,
.webix_accordionitem_header .webix_selected.webix_segment_N:active {
  background-color: #229955;
}
.webix_accordionitem_header .webix_selected.webix_segment_0:focus,
.webix_accordionitem_header .webix_selected.webix_segment_1:focus,
.webix_accordionitem_header .webix_selected.webix_segment_N:focus {
  background-color: #229955;
}
.webix_accordionitem_header .webix_segment_1,
.webix_accordionitem_header .webix_segment_N,
.webix_accordionitem_header .webix_selected.webix_segment_1,
.webix_accordionitem_header .webix_selected.webix_segment_N {
  margin-left: 0;
  border-left: none;
}
.webix_accordionitem_header .webix_img_btn:active,
.webix_accordionitem_header .webix_img_btn_top:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_accordionitem_header .webix_img_btn:focus,
.webix_accordionitem_header .webix_img_btn_top:focus {
  background-color: #2489cc;
}
.webix_accordionitem_header .webix_img_btn:hover,
.webix_accordionitem_header .webix_img_btn_top:hover {
  background-color: #268fd5;
}
.webix_accordionitem_header .webix_icon_button {
  border-radius: 6px;
}
.webix_accordionitem_header .webix_img_btn_top .webix_img_btn_text,
.webix_accordionitem_header .webix_img_btn_top .webix_icon,
.webix_accordionitem_header .webix_el_icon,
.webix_accordionitem_header .webix_img_btn,
.webix_accordionitem_header .webix_icon_btn,
.webix_accordionitem_header .webix_icon_button {
  color: #ffffff;
}
.webix_accordionitem_header .webix_img_btn_abs .webix_icon,
.webix_accordionitem_header .webix_img_btn_abs .webix_img_btn_text {
  color: #ffffff;
}
.webix_accordionitem_header .webix_img_btn_abs {
  color: #ffffff;
}
.webix_accordionitem_header .webix_el_box .webix_img_btn_abs:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_accordionitem_header .webix_el_icon .webix_icon_button:focus {
  background-color: rgba(0, 0, 0, 0.07);
}
.webix_accordionitem_header .webix_el_box .webix_img_btn_abs:hover,
.webix_accordionitem_header .webix_el_icon .webix_icon_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_accordionitem_header .webix_control {
  border-color: #3498db;
}
.webix_accordionitem_header .webix_icon_button .webix_icon {
  font-size: 23px;
  height: 27px;
  width: 27px;
  color: #ffffff;
}
.webix_accordionitem_header .webix_img_btn,
.webix_accordionitem_header .webix_img_btn_top {
  border-width: 0;
}
.webix_accordionitem,
.webix_accordionitem.vertical,
.webix_accordionitem.horizontal
{
  transition:width 0.5s;
}
/* IE8 */
.webix_ie.horizontal > .webix_accordionitem_header.collapsed {
  -ms-writing-mode: tb-lr;
}
.webix_ie.horizontal > .webix_accordionitem_header.collapsed .webix_accordionitem_label {
  padding-left: 0;
  padding-top: 10px;
}
.webix_ie.horizontal > .webix_accordionitem_header.collapsed .webix_accordionitem_button {
  margin: 0;
}
.webix_accordionitem_label {
  height: 100%;
  padding-left: 10px;
}
.webix_accordionitem_button {
  float: right;
  position: relative;
  height: 11px;
  width: 11px;
  margin: 15px 13.5px;
}
/*
.webix_accordionitem.vertical > .webix_accordionitem_header .webix_accordionitem_button{
	.accIconUp;
}
.webix_accordionitem.vertical.collapsed > .webix_accordionitem_header .webix_accordionitem_button{
	.accIconDown;
}

.webix_accordionitem.horizontal  > .webix_accordionitem_header .webix_accordionitem_button{
	.accIconLeft;
}
.webix_accordionitem.horizontal:last-child  > .webix_accordionitem_header .webix_accordionitem_button{
	.accIconRight;
}
.webix_accordionitem.horizontal.collapsed:last-child  > .webix_accordionitem_header .webix_accordionitem_button{
	.accIconDown;
}
.webix_accordionitem.horizontal.collapsed  > .webix_accordionitem_header{
	.webix_accordionitem_button{
		.accIconUp;
	}
}*/
.webix_carousel {
  position: relative;
}
.webix_nav_panel {
  bottom: 15px;
  height: auto;
  line-height: 8px;
  overflow: hidden;
  position: absolute;
  text-align: center;
}
.webix_nav_item {
  display: inline-block;
  height: 8px;
  padding: 6px 5px 6px 4px;
  width: 8px;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_nav_item div {
  border-radius: 50%;
  display: block;
  height: 8px;
  width: 8px;
}
.webix_nav_inactive div {
  border: 1px solid rgba(0, 0, 0, 0.15);
  filter: alpha(opacity=60);
  opacity: 0.6;
  background: #ffffff;
}
.webix_nav_active div {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
}
.webix_nav_button_prev,
.webix_nav_button_next {
  cursor: pointer;
  display: block;
  height: 24px;
  position: absolute;
  width: 24px;
}
.webix_nav_button_prev .webix_nav_button_inner,
.webix_nav_button_next .webix_nav_button_inner {
  height: 100%;
  width: 100%;
}
.webix_nav_button_prev .webix_nav_button_inner {
  opacity: 0.8;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowNDNEOTBEMTYyNzQxMUUzOEU1N0MyQTEyMzIxMDVFQSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowNDNEOTBEMjYyNzQxMUUzOEU1N0MyQTEyMzIxMDVFQSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA0M0Q5MENGNjI3NDExRTM4RTU3QzJBMTIzMjEwNUVBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjA0M0Q5MEQwNjI3NDExRTM4RTU3QzJBMTIzMjEwNUVBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+yGPRLgAAAPBJREFUeNpi+P//vycQP/tPfQAy05MRxGBgYJBkoA14DrLgPwMNARMDjcGgteAINN5CCaokI3UcB2I+UNQBsTghxaRacAaI+aH62IB4MzUtuAjEwlA9zEC8hhhNxFpwFYhFkQxfTqyriLHgFhBLQtWC8s18UsKUkAV3gVgGyfBZpKYIQhbYQtWAcBg5BRIhC26j+WAOtS0AgRvQ9A6L4IXUtgAELqOlopXUtgAEzgOxIFQPKxCvo7YFIHAKLSdvo7YFIHAUiHmgeiUIKSanNLUC4i1ALAHENoQUD48a7TkNzX8BsiAFxKCB4U+BOBkgwAAsAiGcWMs58QAAAABJRU5ErkJggg==");
}
.webix_nav_button_next .webix_nav_button_inner {
  opacity: 0.8;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpEODJBRDc2RTYyNzMxMUUzQTMxN0IxNjAwN0JBOUE4OSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpEODJBRDc2RjYyNzMxMUUzQTMxN0IxNjAwN0JBOUE4OSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkQ4MkFENzZDNjI3MzExRTNBMzE3QjE2MDA3QkE5QTg5IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkQ4MkFENzZENjI3MzExRTNBMzE3QjE2MDA3QkE5QTg5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+crpRPgAAAOhJREFUeNpi+P//vycQP/tPfQAy05MRxGBgYJBkoA14DrLgPwMNARMDjcGgsCAMGkdHyLKBiNQgDoomIOYH4uOkJiViLNgMxGxIlpyhtgUgsAaImaHqhYH4IrUtAIHlSJaIAvFValsAAvOBmBGqTxKIb1HbAhCYhWSJDBDfobYFIBAO1QvCNtS2YA6SD6SB+DY1LViIFNFiQHydmnGwEi0VXaZmKloHxKxQ9YJAfJ6a+WAbWk4+Re2cLAFVxwPER0lNEcSUpjZALAHEW4DYitTCdHjUaM9paP4LkAUpIAYNDH8KxMkAAQYAlwMhmq8XxigAAAAASUVORK5CYII=");
}
.webix_nav_panel_corner {
  right: 73px;
}
.webix_nav_button_corner.webix_nav_button_prev {
  bottom: 13px;
  right: 37px;
}
.webix_nav_button_corner.webix_nav_button_prev .webix_nav_button_inner:hover {
  opacity: 1;
}
.webix_nav_button_corner.webix_nav_button_next {
  bottom: 13px;
  right: 7px;
}
.webix_nav_button_corner.webix_nav_button_next .webix_nav_button_inner:hover {
  opacity: 1;
}
.webix_nav_panel_side {
  margin: 0 auto;
  left: 0;
  width: 100%;
}
.webix_nav_button_side {
  height: 34px;
  width: 21px;
  top: 50%;
  margin-top: -17px;
}
.webix_nav_button_side.webix_nav_button_next {
  left: auto;
  right: 15px;
}
.webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner {
  opacity: 0.8;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTc4MTkxODY2MzBGMTFFMzk1MUQ4RTNCRjNCMUVFRUMiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTc4MTkxODU2MzBGMTFFMzk1MUQ4RTNCRjNCMUVFRUMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA4NjlGM0NFNjE4OTExRTNBRjMwOTNFODNBRTJCRENBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjA4NjlGM0NGNjE4OTExRTNBRjMwOTNFODNBRTJCRENBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+D/KEcQAAAOhJREFUeNqs1jsOwjAMBuCKhR2uxsLGxg1a+hD0wVGYGJC4DgssnAFVQjLukKWk/WM7lv4OqfQ1UdLWCRGtOTdOz3lwtpzEkuFyp/9KreiX/JVa0DdNV6ZF9zRfmQYdcgbwQYNGhccDXQzYN4jgXIOa4bkntgAuNKgaDtlNMRx69hoAlxpUBEvfawRXGnRIjeBFIq8e3F9KZ5mDWXbS5QeBEjQYDEULALbSwy8GEYrARvpBUYNTaGkBfagZHKMIrKU/vioW6NANAE+aZuIVE3RoHxN06NUDHq2t5Ipz4Xw4T87O2vT+BBgAeQQKvezNqP0AAAAASUVORK5CYII=");
}
.webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner:hover {
  opacity: 1;
}
.webix_nav_button_side.webix_nav_button_prev {
  left: 15px;
  right: auto;
}
.webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner {
  opacity: 0.8;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjIzRTVCQkY2MzBGMTFFM0FDNTBDNzdDRkQ0NTI0NzciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjIzRTVCQkU2MzBGMTFFM0FDNTBDNzdDRkQ0NTI0NzciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA4NjlGM0NFNjE4OTExRTNBRjMwOTNFODNBRTJCRENBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjA4NjlGM0NGNjE4OTExRTNBRjMwOTNFODNBRTJCRENBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+wRT1eAAAAM9JREFUeNqs1jsKwlAQBdDBxlL3ZmVlmRXYaaVbCYg7kti4BQu1e85AhBDfZz73wm1ewsmfDKWUKNgNd+C+uVfuOgoe0n8uaFDyQoOSOxqU7NDgUfaDgxZUDWpRE6hBzWALdYE11A2W0BCYQ8PgHIWAUxQG/lAoKF0Q0ZLq+ZA1o35qnO3eevlweL4AgXOL5yhc2hCCa0d0w63744I1T9MMa989E2z5/NSw9Retgj3DRAveeseeGnyLDGgl+BkdJXNwH0WlHfcxDr09d/UVYADVjwptZ8mPvQAAAABJRU5ErkJggg==");
}
.webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner:hover {
  opacity: 1;
}
.webix_carousel > .webix_scroll_x,
.webix_carousel > .webix_scroll_y {
  background-color: rgba(0, 0, 0, 0);
}
.webix_dark .webix_nav_inactive div {
  background-color: #95a5a6;
}
.webix_dark .webix_nav_active div {
  background-color: #7f8c8d;
}
.webix_dark .webix_nav_button_prev .webix_nav_button_inner {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3gcdCSIURgotfwAAASxJREFUSMfNlbErhlEUh5/vJZOSQSijshgsFiklhdj0lcHw5SP7uX+A8we4d1c+g2RgsIjIxmCxGCzKRO9bMjCSWE5WpXPrO9MZbs9T5577u7XNrTgPtIBBfKsEmkUmOMZsFZngv5KCzNW2gmsbbT2H4AZY0CAVcOUtuAXmNMi7xtQF7HgK7oBZDfKmMXUAB8Cil+AemNEgrwbfB5a8LvnB4C8aU83Gsuy1RY/AtAYpDb4NNDzXtKFBnqyvA+ve72BXYxqy/shyy1UwDFxqTP0a5BvYAPa8X/KISfo0yBewChx6R8UocKEx9ZpkBTj2zqIx4Fxj6tEgn7aqZ95RMQ6cakzdGuTDxuUedhPAicY0AEz+dbjzn3E9ZX9ue3w4ZUZ+VQBrQJUB/gw0fwC/UlVpnH2f9AAAAABJRU5ErkJggg==");
}
.webix_dark .webix_nav_button_next .webix_nav_button_inner {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3gcdCSENCUzWfAAAAS1JREFUSMfN1TFLXEEUhuFnr8FKCBaGiK2FRQobG7GSgBG2E8IWFmE3wX7uD3B+QOb2QpaAiMWmsAkJBtMEizQ2FmmCXcIuhBRpDZI0g7XCXNhTD+8L53znTGf/ddrGEIvK1hiDqiW4zBxWLcFvJZWWayoEz3Mbz9sSfIl1mKCLr20I3sTUzMY6/MEzXJQWdHEcUzOTJVu4LD3kHRxlyW88xbfSKerldnViHX5lyffSMX2BgywZYxNXpffgVY6uWIcfWVpUMMQIYmqW8Lak4BB7sQ7/Ymoe4QzLpQQj9GMdbmJqFvAZK6WGfILdDJ/HJzwpFdOP6MU6/I2peYhTrJZctH6sw3VMzRw+YK30qdiIqXmM91i/b+Qe3OHNu6n/cMYt8icVXmLSAvwnBv8B+EFU7qlZ1xIAAAAASUVORK5CYII=");
}
.webix_dark .webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3gcdCSI77dsQJgAAARxJREFUSMet1jFqAkEUxvH/rkIqLVN5ES2CjScQG6tUESx3SWOMLKgggt7BC6SxsnIr9w4ewAsEEis3RTBE3N3ZmfemHB4/HsPMm89L0xTJilbrLrAAGsAWeKkKwTEw/bfVAy6ea6cZ4HV9+8ogwMm6UwMI8Owrg5MoDDa+MjgF8LXBUqgtaERdwELUFcxFJWAmKgXvUA3wBtUC/1BNEKBCra4KXjt9MNScbadYJd7t9nGSVIGnnJpOnCRf7VbzYNMpURi8A7OCumW0Wr9aodrw7T39hedS+P5FhcFYCme/fSGcP6UEcPE8dYTNk98BLvdHWcLlf1ML2CVMzIC3gpK+U5YywEdJQMuDP50CmuGMt55C6B0AI+AR+ACGPw26r6m12WOpAAAAAElFTkSuQmCC");
}
.webix_dark .webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3gcdCSE1IU5u4gAAAW1JREFUSMet1rtOwzAUgOG/5jbBwsQD8BAwIAa2TggegjmRgELbuFeo1GRlQEJMMDN264CUiYdgg0dolzIkFUEk9nHSbLGtT76cc+xaMA53gUegDnwCgfa9Vyp8CngCToFNYB940WF0WRWt57SPqsAK+C7oG+kwuiqLdg3992Xgtelk8jGN4w3gqGDMyTSOZ8eHB+8uM0X7XhMYGMbd6TC6dkIzcH8VsMr+aN9rCeCGEyqEhzZY5TWmcM8C3zihKdy2wIMiWJmWURZWtk1P4a4FvnVCUziwwP0srKQBLYSbALXFYuGU1zqMNBAYhrRUiSI0s/RvKcdZNoChcQt8Tz5TKSg/fQdQFqdJcJvAXha0Z1QCDixgW577drCbBxZXKRkYyOtpkm6lwf+VPwH7VcC/d5Qd7EjA39s0KQQ2UIvvfbZ3zoEHU2Rp3+u4vlDGqwSX6N4qwSX6ltMelAUB1oELYA6cAV/poTxXeZ/+AOFqn1e/Gh7yAAAAAElFTkSuQmCC");
}
.webix_list_item {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2px 10px;
  line-height: 29px;
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
  text-align: left;
  border-color: #ebebeb;
}
.webix_list_item.webix_invalid {
  background-color: #ffeeee;
}
.webix_list_item.webix_selected {
  color: #2f3438;
  background: #f3f3f3;
}
.webix_list .webix_list_item.webix_selected {
  border-bottom: 1px solid #ebebeb;
  padding: 2px 10px;
}
.webix_group {
  position: relative;
}
.webix_group_back,
.webix_group_back.webix_selected {
  position: relative;
  background: #d2e3ef;
  font-size: 15px;
  color: #4a4a4a;
}
.webix_arrow_icon {
  position: absolute;
  top: 50%;
  bottom: 50%;
  margin-top: -12px;
  right: 15px;
  width: 9px;
  height: 22px;
  line-height: 22px;
  color: #666666;
  font-size: 18px;
  font-family: 'Font Awesome 5 Pro';
}
.webix_arrow_icon:before {
  content: '\f105';
}
.webix_group_back {
  padding-left: 29px;
}
.webix_group_back .webix_arrow_icon {
  left: 11px;
}
.webix_group_back .webix_arrow_icon:before {
  content: '\f104';
}
.dhx_pagelist {
  position: relative;
}
.webix_list_count {
  float: right;
  width: 25px;
  font-size: 14px;
  text-align: center;
  margin-right: 45px;
  box-shadow: 0 1px 0 #000000;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.6);
}
.webix_unit_header {
  padding: 0 10px;
  line-height: 19px;
  text-align: left;
  background-color: #d2e3ef;
}
.webix_list-x .webix_list_item {
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #ebebeb;
  border-bottom: none;
  border-color: #ebebeb;
}
.webix_list-x .webix_list_item.webix_selected {
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  padding-left: 9px;
}
.webix_list-x .webix_scroll_cont {
  display: inline;
}
.webix_view.webix_list-x {
  white-space: nowrap;
}
/* Style for disabled items */
.webix_list_item.webix_disabled {
  color: #8b949e;
}
.webix_list_item.webix_disabled .webix_submenu_icon {
  visibility: hidden;
}
/*button of pager*/
.webix_view.webix_pager {
  padding: 3px 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
}
.webix_pager_item,
.webix_pager_item_selected {
  padding: 0 6px;
  text-align: center;
  height: 100%;
  width: 32px;
  border: 1px solid #dddddd;
  margin: 0 2px;
  cursor: pointer;
  background-color: white;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: StolzlRegular,sans-serif;
  font-size: 15px;
}
/*selected  button of pager*/
.webix_pager_item_selected {
  cursor: default;
  background-color: #27ae60;
  border: 1px solid #ebebeb;
}
.webix_animation .webix_ss_header {
  position: relative;
  z-index: 10;
}
.webix_animation .webix_ss_footer,
.webix_animation .webix_vscroll_x {
  position: absolute;
  bottom: 0px;
  z-index: 10;
}
/*helper for allowing selection*/
.webix_allow_selection {
  -moz-user-select: text;
  -webkit-user-select: text;
  user-select: text;
}
/*default item in dataview*/
.webix_dataview_item {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2px 10px;
  line-height: 29px;
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
  text-align: left;
  border-color: #ebebeb;
  border-right: 1px solid #ebebeb;
}
/*default item in selected state*/
.webix_dataview_item.webix_selected {
  color: #ffffff;
  background: #27ae60;
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}
/*chart container*/
.webix_chart {
  position: relative;
  font-family: StolzlRegular,sans-serif;
  font-size: 12px;
  background-color:white;
  color: #000;
  overflow: hidden;
  z-index: 1;
}
.webix_chart canvas {
  position: absolute;
}
/*labels*/
.webix_canvas_text {
  position: absolute;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  z-index: 100;
  background-color:rgba(255,255,255,0.7);
}
/*map*/
.webix_map_img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border: 0px;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  opacity: 0;
}
/*scales*/
.webix_axis_item_y {
  position: absolute;
  height: 18px;
  line-height: 18px;
  /*line-height:10px;*/
  margin-top: -4px;
  text-align: right;
}
.webix_axis_item_x {
  padding-top: 2px;
}
.webix_axis_title_x {
  text-align: center;
  font-size: 13px;
}
.webix_axis_title_y {
  text-align: center;
  font-family: Verdana;
  font-size: 13px;
  /*safari*/
  -webkit-transform: rotate(-90deg);
  /*firefox*/
  -moz-transform: rotate(-90deg);
  /*opera*/
  -o-transform: rotate(-90deg);
  /*IE9*/
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  padding-left: 3px;
}
.webix_ie_filter {
  /*IE8*/
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
/*legend block*/
.webix_chart_legend {
  position: absolute;
  z-index: 1000;
}
.webix_chart_legend_item {
  height: 18px;
  line-height: 18px;
  padding: 2px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}
.webix_chart_legend_item.hidden {
  color: #e6e6e6;
}
.webix_axis_item_y.webix_radar {
  color: #555;
  font-size: 11px;
  padding-right: 3px;
  height: 13px;
  line-height: 13px;
  margin-top: 0px;
}
.webix_canvas_text.webix_axis_radar_title {
  margin-top: 0px;
  padding-top: 0px;
  color: #000;
  font-size: 12px;
}
.webix_axis_item_y,
.webix_axis_item_x {
  color: #000;
}
/*data frame*/
.webix_chart_frame {
  background-color: #a0cfef;
  height: 100%;
  opacity: 0.3;
  position: absolute;
  top: 0;
  z-index: 150;
  border-left: 1px solid #3498db;
  border-right: 1px solid #3498db;
  cursor: w-resize;
}
.webix_chart_resizer {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAICAYAAAAx8TU7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTJCMjdFNENBRTg4MTFFMjk2NjJGMTJENjkyNDA2NTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTJCMjdFNERBRTg4MTFFMjk2NjJGMTJENjkyNDA2NTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MkIyN0U0QUFFODgxMUUyOTY2MkYxMkQ2OTI0MDY1OSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MkIyN0U0QkFFODgxMUUyOTY2MkYxMkQ2OTI0MDY1OSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Powz/icAAAAaSURBVHjaYmBgYDBjgAA4zcSABQysIECAAQBn+ACx2PqtbAAAAABJRU5ErkJggg==");
  background-position: 3px center;
  background-repeat: no-repeat;
  border-radius: 2px;
  height: 20px;
  bottom: 0;
  width: 11px;
  z-index: 200;
  position: absolute;
  background-color: #fff;
  border: 1px solid #cccccc;
  cursor: w-resize;
}
.webix_wresize_cursor {
  cursor: w-resize;
}
.webix_vscroll_x {
  height: 0px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
}
.webix_vscroll_y {
  width: 0px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.webix_vscroll_body {
  width: 16px;
  height: 16px;
}
.webix_scroll_x {
  width: 50px;
  height: 4px !important;
  left: 1px;
  bottom: 3px;
  background-color: silver;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  border-radius: 4px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_scroll_y {
  width: 4px !important;
  height: 50px;
  top: 1px;
  right: 3px;
  background-color: silver;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  border-radius: 4px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_c_scroll_y {
  position: absolute;
  z-index: 3;
  width: 10px;
  right: 0px;
}
.webix_c_scroll_y > div {
  -moz-transition: width 0.3s 0.2s, margin 0.3s 0.2s;
  -webkit-transition: width 0.3s 0.2s, margin 0.3s 0.2s;
  -o-transition: width 0.3s 0.2s, margin 0.3s 0.2s;
  -ms-transition: width 0.3s 0.2s, margin 0.3s 0.2s;
  width: 4px;
  height: 100%;
  background-color: silver;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  margin: 0px 3px;
}
.webix_c_scroll_y:hover > div,
.webix_c_scroll_y.webix_scroll_active div {
  width: 10px;
  margin: 0px;
}
.webix_c_scroll_x {
  position: absolute;
  z-index: 3;
  height: 10px;
  bottom: 0px;
}
.webix_c_scroll_x > div {
  -moz-transition: height 0.3s 0.2s, margin 0.3s 0.2s;
  -webkit-transition: height 0.3s 0.2s, margin 0.3s 0.2s;
  -o-transition: height 0.3s 0.2s, margin 0.3s 0.2s;
  -ms-transition: height 0.3s 0.2s, margin 0.3s 0.2s;
  height: 4px;
  width: 100%;
  background-color: silver;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  margin: 3px 0px;
}
.webix_c_scroll_x:hover > div,
.webix_c_scroll_x.webix_scroll_active div {
  height: 10px;
  margin: 0px;
}
.webix_c_scroll_bar_y,
.webix_c_scroll_bar_x {
  position: absolute;
  background: rgba(0, 0, 0, 0.05);
}
.webix_c_scroll_bar_y {
  height: 100%;
  width: 10px;
  right: 0px;
}
.webix_c_scroll_bar_x {
  width: 100%;
  height: 10px;
  bottom: 0px;
}
.webix_scroll_cont {
  width: 100%;
  height: auto;
  overflow: hidden;
  clear: both;
}
.webix_scroll_touch_ie {
  touch-action: none;
}
.webix_dt_editor {
  position: absolute;
  z-index: 2;
  overflow: visible;
  background: white;
}
.webix_dt_editor input,
.webix_dt_editor select {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* to look perfect in IE it must be 1/9/0/9 */
  padding: 1px 8px 0px 9px;
  margin: 0px;
  border: 1px solid #ffd700;
  height: 100%;
  font-family: StolzlRegular,sans-serif;
  font-size: 15px;
  color: #666666;
}
.webix_dt_editor input[type="checkbox"] {
  margin: 6px 8px 4px 14px;
  height: auto;
  width: auto;
}
.webix_dt_editor select {
  padding-right: 0px;
  padding-left: 5px;
}
.webix_clipbuffer {
  width: 2000px;
  height: 1px;
  padding: 0;
  margin: 0;
  border: 0;
  left: 0px;
  top: 0px;
  white-space: pre-wrap;
  position: fixed;
  filter: alpha(opacity=0);
  opacity: 0;
  pointer-events: none;
  cursor: default;
}
.webix_message_area {
  position: fixed;
  bottom: 45px !important;
  right: 5px;
  width: 250px;
  z-index: 1000;
}
.webix_info {
  min-width: 120px;
  padding: 4px 4px 4px 20px;
  font-family: Tahoma, StolzlRegular,sans-serif;
  z-index: 10000;
  overflow: hidden;
  margin: 5px;
  margin-bottom: 10px;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}
.webix_no_transition {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.webix_info.hidden {
  height: 0px;
  min-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-width: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: hidden;
}
.webix_modal_box {
  overflow: hidden;
  display: inline-block;
  min-width: 250px;
  width: 250px;
  text-align: center;
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #c8c8c8;
  font-family: StolzlRegular,sans-serif;
  z-index: 20000;
  border-radius: 0px;
  border: 1px solid #ffffff;
  outline: none;
}
.webix_popup_title {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-width: 0px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAoCAMAAAAIaGBFAAAAhFBMVEVwcHBubm5sbGxqampoaGhmZmZlZWVjY2NhYWFfX19dXV1bW1taWlpYWFhWVlZUVFRSUlJRUVFPT09NTU1LS0tJSUlHR0dGRkZERERCQkJAQEA+Pj49PT09PT0+Pj5AQEBBQUFDQ0NERERGRkZHR0dJSUlKSkpMTExMTEw5OTk5OTk5OTkny8YEAAAAQklEQVQImQXBCRJCAAAAwKVSQqdyjSPXNP7/QLsIhA6OTiJnF7GrRCpzc/fw9PKW+/gqlCq1RqvTG/yMJrPF6m/bAVEhAxxnHG0oAAAAAElFTkSuQmCC);
  background-image: -webkit-linear-gradient(top, #707070 1%, #3d3d3d 70%, #4c4c4c 97%, #393939 97%);
  background-image: -moz-linear-gradient(top, #707070 1%, #3d3d3d 70%, #4c4c4c 97%, #393939 97%);
}
.webix_info,
.webix_popup_button,
.webix_button {
  cursor: pointer;
}
.webix_popup_text {
  overflow: hidden;
}
.webix_popup_controls {
  border-radius: 6px;
  padding: 5px 10px 10px;
}
.webix_popup_button,
.webix_button {
  height: 30px;
  line-height: 30px;
  display: inline-block;
  margin: 0 5px;
  border-radius: 6px;
}
.webix_popup_button div {
  border-radius: 6px;
}
.webix_popup_button {
  min-width: 100px;
}
div.webix_modal_cover {
  background-color: #000;
  cursor: default;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=10);
  opacity: 0.1;
  position: fixed;
  z-index: 19999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border: none;
  zoom: 1;
}
.webix_info img,
.webix_modal_box img {
  float: left;
  margin-right: 20px;
}
.webix_alert_error .webix_popup_title,
.webix_confirm_error .webix_popup_title {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAsCAIAAAArRUU2AAAATklEQVR4nIWLuw2AMBBDjVuQiBT2oWbRDATrnB0KQOJoqPzRe3BrHI6dcBASYREKovtK6/6DsDOX+stN+3H1YX9ciRgnYq5EWYhS2dftBIuLT4JyIrPCAAAAAElFTkSuQmCC);
}
.webix_alert_error,
.webix_confirm_error {
  border: 1px solid #ff0000;
}
/*Skin section*/
.webix_button,
.webix_popup_button {
  border: 1px solid #dddddd;
}
.webix_button input,
.webix_popup_button div
{
  color: white;
  font-size: 13px;
  padding: 0px 12px;
  font-family: StolzlRegular,sans-serif;
  outline: none;
  background: #0079a5;
  box-shadow: none;
  border-width: 0px;
  border-radius: 2px;
}
.webix_popup_button.confirm div {
  font-size: 13px;
  font-family: StolzlRegular,sans-serif;
  color: white;
  outline: none;
  padding: 0px 12px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  background-color: #ff0000;
  border-width: 0px;
  border-radius: 2px;
}
.webix_popup_title {
  color: #fff;
  text-shadow: 1px 1px #000;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
}
.webix_popup_text {
  margin: 0 0 5px 0;
  padding-top: 25px;
  font-size: 15px;
  color: #666666;
  min-height: 60px;
}
.webix_info,
.webix_error {
  font-size: 15px;
  color: #354866;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 0px;
  background-color: #f6f6f6;
  border-radius: 2px;
}
.webix_info div {
  padding: 12px 12px 12px 12px;
  background-color: #f6f6f6;
  border-radius: 2px;
  border: 1px solid #f6f6f6;
}
.webix_error
{
  background: #ff0000;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.webix_error div {
  background-color: #ff0000;
  border: 1px solid #ff0000;
  border-radius:2px;
  color: #FFF;
  padding: 12px 12px 12px 12px;
}
.webix_drag_zone {
  position: absolute;
  box-shadow: 1px 1px 5px #dddddd;
  background-color: #ffffff;
  font-family: StolzlRegular,sans-serif;
  font-size: 15px;
  color: #666666;
  pointer-events: none;
}
.webix_drag_zone .borderless {
  padding: 2px 20px 2px 2px;
}
.webix_drag_over,
.webix_drop_zone {
  background-color: #e6e6e6;
}
.webix_transparent {
  opacity: 0;
}
.webix_column {
  position: absolute;
  top: 0px;
}
.webix_cell {
  overflow: hidden;
  line-height: 31px;
  white-space: nowrap;
}
.webix_table_checkbox.checked
{
  color:#168073;
}
.webix_table_checkbox.notchecked
{
  color:#ff8a65;
}
.webix_hcell {
  width: 100%;
  overflow: hidden;
}
.webix_ss_body {
  overflow: hidden;
  position: relative;
}
.webix_hs_left,
.webix_hs_center,
.webix_hs_right,
.webix_ss_center,
.webix_ss_right,
.webix_ss_left {
  overflow: hidden;
  float: left;
}
.webix_ss_center,
.webix_ss_right {
  position: relative;
}
.webix_ss_header table,
.webix_ss_footer table {
  table-layout: fixed;
}
.webix_ss_center_scroll {
  height: 100%;
  position: relative;
  overflow: hidden;
  min-height: 1px;
}
.webix_ss_hscroll {
  bottom: 0px;
}
.webix_ss_vscroll {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}
.webix_ss_vscroll_footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-left: 1px solid #ffffff;
  border-top: 1px solid #dddddd;
  z-index: 1;
}
.webix_ss_vscroll_header {
  position: absolute;
  top: 0px;
  right: 0px;
  border-left: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
  z-index: 1;
}
.webix_dtable {
  position: relative;
  cursor: default;
  z-index: 0;
}
.webix_ss_footer,
.webix_ss_header {
  clear: both;
  overflow: hidden;
}
.webix_table_cell,
.webix_view.webix_table_cell,
.webix_block_selection,
.webix_ss_header td,
.webix_ss_footer td,
.webix_hcell,
.webix_cell {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_block_selection {
  position: absolute;
}
.webix_ss_sort_asc,
.webix_ss_sort_desc {
  position: absolute;
  right: 3px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_hcell.webix_ss_filter {
  padding: 3px 5px 3px 5px;
}
.webix_ss_filter select,
.webix_ss_filter input {
  width: 100%;
  font-size: inherit;
  color: #666666 !important;
  height: 39px;
  margin-top: -2px;
  padding: 0 3px;
  vertical-align: top;
  border: 1px solid #dddddd;
  line-height: 32px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: StolzlRegular,sans-serif;
}
.webix_richfilter {
  margin-top: 1px;
  line-height: 32px;
}
.webix_ss_filter select {
  padding: 3px;
}
/*headers and footers*/
.webix_ss_header,
.webix_ss_header TD,
.webix_ss_vscroll_header,
.webix_dd_drag_column {
  font-size: 15px;
  color: #4a4a4a;
  background: #f6f6f6;
}
.webix_ss_footer TD,
.webix_ss_vscroll_footer {
  background: #fafafa;
}
/*content in header and footer*/
.webix_hcell {
  padding: 0px 8px 0px 8px;
  text-align: left;
}
/*borders in header and footer*/
.webix_ss_header td[role="presentation"]
{
  border-right: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
}
.webix_ss_footer td {
  border-right: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
}
/*remove rightmost borders*/
.webix_hs_right td.webix_last,
.webix_hs_center td.webix_last {
  border-right-width: 0px;
}
/*right inner border*/
.webix_column.webix_last > div {
  border-right-width: 0px;
}
/*borders between split areas*/
.webix_ss_right .webix_column.webix_first > div,
.webix_hs_right td.webix_first {
  border-left: 1px solid #dddddd;
}
.webix_ss_left .webix_column.webix_last > div {
  border-right: 1px solid #dddddd;
}
.webix_last_topcell.webix_cell {
  border-bottom: 1px solid #dddddd;
}
/*normal data cell of the table*/
.webix_column > div,
.webix_table_cell {
  border-right: 1px solid #ebebeb;
  padding: 0 10px;
  border-bottom: 1px solid #ebebeb;
}
.webix_column {
  text-align: left;
}
/*alter styles for even and odd rows*/
/*header selection in column mode*/
.webix_sel_hcell,
.webix_dd_over_column {
  background-color: #c9eaf5;
}
.webix_block_selection {
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid #ebebeb;
}
.webix_dd_drag {
  white-space: nowrap;
  overflow: hidden;
  border-left: 1px solid #ebebeb;
  height: 34px;
}
.webix_dd_drag > div {
  display: inline-block;
  vertical-align: top;
  background: #ffffff;
  border-top: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 5px 10px;
  height: 34px;
  line-height: 26px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_dd_drag_column {
  padding: 5px 10px 5px 10px;
}
.webix_column > .webix_drag_over.webix_cell {
  background-color: #e6e6e6;
  color: #666666;
}
.webix_ss_header th,
.webix_ss_header td,
.webix_ss_header table {
  padding: 0px;
  margin: 0px;
}
.webix_cell.webix_invalid {
  background-color: #ffeeee !important;
}
.webix_column > .webix_cell.webix_invalid:nth-child(even) {
  background-color: #ffeeee;
}
.webix_invalid_cell {
  position: relative;
}
.webix_invalid_cell:after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 0px;
  height: 1px;
  border-top: 8px solid #f44;
  border-left: 8px solid transparent;
}
/*selection*/
.webix_column > div.webix_cell_select,
.webix_column > div.webix_column_select,
.webix_column > div.webix_row_select {
  color: #2f3438;
  background: #e0e0e0;
}
/*extra borders, for selection in cell mode*/
.webix_column > div.webix_cell_select {
  border-bottom: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  padding-top: 0px;
  padding-left: 9px;
}
.webix_ss_right .webix_column.webix_first > div.webix_cell_select {
  padding-left: 10px;
}
/*extra borders, for selection in row mode*/
.webix_column > div.webix_row_select,
.webix_column > div.webix_invalid.webix_row_select:nth-child(even) {
  border-bottom: 1px solid #ebebeb;
  padding-top: 0px;
}
/*extra borders for selection in column mode*/
.webix_column > div.webix_column_select {
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  padding-left: 9px;
}
.webix_column > .webix_cell.webix_invalid.webix_cell_select:nth-child(even) {
  color: #ffffff;
  background: #27ae60;
}
.webix_dtable_colrow,
.webix_dtable_subrow,
.webix_dtable_subview {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: white;
  width: 100%;
  padding-right: 28px;
  padding-left: 10px;
  padding-top: 1px;
  border-bottom: 1px solid #ebebeb;
}
.webix_dtable_subrow {
  border-top: 1px solid #ebebeb;
  text-align: right;
}
.webix_dtable_subview {
  background: #ebebeb;
  border-top: 1px solid #ebebeb;
  padding: 0px 0px 0px 0px;
  padding-left: 10px;
  box-sizing: border-box;
}
.number .webix_cell {
  text-align: right;
}
.webix_rotate {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  line-height: normal;
}
.webix_measure_rotate {
  line-height: normal;
  white-space: normal;
  padding: 10px;
}
.webix_span_layer {
  background: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 0px;
  width: 0px;
  overflow: visible;
}
.webix_dtable_span {
  position: absolute;
  background: #fff;
}
.webix_dtable_span.webix_selected {
  color: #ffffff;
  background: #27ae60;
}
.webix_hcell span.webix_input_icon {
  background-color: #b0cee3;
}
.webix_area_selection_layer {
  position: absolute;
  z-index: 1;
}
.webix_area_selection {
  position: absolute;
  background: #666666;
}
.webix_area_selection_top,
.webix_area_selection_bottom {
  height: 2px;
}
.webix_area_selection_left,
.webix_area_selection_right {
  width: 2px;
}
.webix_area_selection_handle {
  position: absolute;
  width: 6px;
  height: 6px;
  border: 1px solid #fff;
  background: #666666;
  cursor: crosshair;
  margin: -6px 0 0 -6px;
}
.webix_topcell {
  position: relative;
  background: #fff;
  z-index: 1;
}
.webix_window {
  position: absolute;
  box-shadow: 1px 3px 4px #c8c8c8;
}
.webix_win_body {
  overflow: hidden;
}
.webix_window,
.webix_win_content,
.webix_win_body,
.webix_win_body > .webix_view
{
  border-radius: 2px;
  background-color:#f6f6f6;
}
.webix_win_body > .webix_view .webix_cal_month {
  border-radius: 0px;
}
.webix_win_head {
  border-bottom: 1px solid silver;
}
.webix_win_head > .webix_view > .webix_template {
  color: #ffffff;
  font-size: 18px;
  background: #3498db;
  /*.webix_el_button input, .webix_el_toggle input {
		border: none;

	}*/
  /*.webixtype_prev:active, .webixtype_next:active, .webixtype_base:active, .webix_inp_counter_next:active, .webix_inp_counter_prev:active{
		background-color: @barButtonActiveColor;
	}*/
  padding: 0px;
  text-align: center;
  line-height: 45px;
}
.webix_win_head > .webix_view > .webix_template .webix_el_button,
.webix_win_head > .webix_view > .webix_template .webix_el_label,
.webix_win_head > .webix_view > .webix_template .webix_inp_label {
  color: #ffffff;
}
.webix_win_head > .webix_view > .webix_template .webix_el_label {
  font-size: 18px;
}
.webix_win_head > .webix_view > .webix_template .webixtype_base,
.webix_win_head > .webix_view > .webix_template .webixtype_prev,
.webix_win_head > .webix_view > .webix_template .webixtype_next,
.webix_win_head > .webix_view > .webix_template .webix_inp_counter_next,
.webix_win_head > .webix_view > .webix_template .webix_inp_counter_prev,
.webix_win_head > .webix_view > .webix_template .webix_segment_0,
.webix_win_head > .webix_view > .webix_template .webix_segment_1,
.webix_win_head > .webix_view > .webix_template .webix_segment_N {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
}
.webix_win_head > .webix_view > .webix_template .webix_el_arrow.webixtype_next_arrow {
  border-left: none;
  border-bottom: none;
}
.webix_win_head > .webix_view > .webix_template .webix_el_arrow.webixtype_prev_arrow {
  border-right: none;
  border-bottom: none;
}
.webix_win_head > .webix_view > .webix_template .webixtype_base:hover,
.webix_win_head > .webix_view > .webix_template .webix_inp_counter_next:hover,
.webix_win_head > .webix_view > .webix_template .webix_inp_counter_prev:hover,
.webix_win_head > .webix_view > .webix_template .webix_segment_0:hover,
.webix_win_head > .webix_view > .webix_template .webix_segment_1:hover,
.webix_win_head > .webix_view > .webix_template .webix_segment_N:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_win_head > .webix_view > .webix_template .webixtype_base:active,
.webix_win_head > .webix_view > .webix_template .webix_inp_counter_next:active,
.webix_win_head > .webix_view > .webix_template .webix_inp_counter_prev:active,
.webix_win_head > .webix_view > .webix_template .webix_segment_0:active,
.webix_win_head > .webix_view > .webix_template .webix_segment_1:active,
.webix_win_head > .webix_view > .webix_template .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_win_head > .webix_view > .webix_template .webixtype_base:focus,
.webix_win_head > .webix_view > .webix_template .webix_inp_counter_next:focus,
.webix_win_head > .webix_view > .webix_template .webix_inp_counter_prev:focus,
.webix_win_head > .webix_view > .webix_template .webix_segment_0:focus,
.webix_win_head > .webix_view > .webix_template .webix_segment_1:focus,
.webix_win_head > .webix_view > .webix_template .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
}
.webix_win_head > .webix_view > .webix_template .webix_inp_static,
.webix_win_head > .webix_view > .webix_template input[type=text] {
  border-color: #ffffff;
}
.webix_win_head > .webix_view > .webix_template .webix_inp_static:focus,
.webix_win_head > .webix_view > .webix_template input[type=text]:focus {
  border-color: #ffffff;
  box-shadow: none;
}
.webix_win_head > .webix_view > .webix_template .webix_input_icon {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
  color: #666666;
}
.webix_win_head > .webix_view > .webix_template .webix_input_icon:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_win_head > .webix_view > .webix_template .webix_input_icon:active,
.webix_win_head > .webix_view > .webix_template .webix_input_icon:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_win_head > .webix_view > .webix_template .webix_pressed .webixtype_base,
.webix_win_head > .webix_view > .webix_template .webix_pressed .webixtype_prev,
.webix_win_head > .webix_view > .webix_template .webix_pressed .webixtype_next,
.webix_win_head > .webix_view > .webix_template .webix_pressed .webix_el_arrow,
.webix_win_head > .webix_view > .webix_template .webix_pressed .webix_inp_counter_next,
.webix_win_head > .webix_view > .webix_template .webix_pressed .webix_inp_counter_prev,
.webix_win_head > .webix_view > .webix_template .webix_pressed .webix_input_icon,
.webix_win_head > .webix_view > .webix_template .webix_pressed .webix_segment_0,
.webix_win_head > .webix_view > .webix_template .webix_pressed .webix_segment_1,
.webix_win_head > .webix_view > .webix_template .webix_pressed .webix_segment_N {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_0,
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_1,
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_N {
  border-color: #d9d9d9;
  background-color: #27ae60;
  color: #ffffff;
}
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_0:hover,
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_1:hover,
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_N:hover {
  background-color: #24a259;
}
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_0:active,
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_1:active,
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_N:active {
  background-color: #229955;
}
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_0:focus,
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_1:focus,
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_N:focus {
  background-color: #229955;
}
.webix_win_head > .webix_view > .webix_template .webix_segment_1,
.webix_win_head > .webix_view > .webix_template .webix_segment_N,
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_1,
.webix_win_head > .webix_view > .webix_template .webix_selected.webix_segment_N {
  margin-left: 0;
  border-left: none;
}
.webix_win_head > .webix_view > .webix_template .webix_img_btn:active,
.webix_win_head > .webix_view > .webix_template .webix_img_btn_top:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_win_head > .webix_view > .webix_template .webix_img_btn:focus,
.webix_win_head > .webix_view > .webix_template .webix_img_btn_top:focus {
  background-color: #2489cc;
}
.webix_win_head > .webix_view > .webix_template .webix_img_btn:hover,
.webix_win_head > .webix_view > .webix_template .webix_img_btn_top:hover {
  background-color: #268fd5;
}
.webix_win_head > .webix_view > .webix_template .webix_icon_button {
  border-radius: 6px;
}
.webix_win_head > .webix_view > .webix_template .webix_img_btn_top .webix_img_btn_text,
.webix_win_head > .webix_view > .webix_template .webix_img_btn_top .webix_icon,
.webix_win_head > .webix_view > .webix_template .webix_el_icon,
.webix_win_head > .webix_view > .webix_template .webix_img_btn,
.webix_win_head > .webix_view > .webix_template .webix_icon_btn,
.webix_win_head > .webix_view > .webix_template .webix_icon_button {
  color: #ffffff;
}
.webix_win_head > .webix_view > .webix_template .webix_img_btn_abs .webix_icon,
.webix_win_head > .webix_view > .webix_template .webix_img_btn_abs .webix_img_btn_text {
  color: #ffffff;
}
.webix_win_head > .webix_view > .webix_template .webix_img_btn_abs {
  color: #ffffff;
}
.webix_win_head > .webix_view > .webix_template .webix_el_box .webix_img_btn_abs:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_win_head > .webix_view > .webix_template .webix_el_icon .webix_icon_button:focus {
  background-color: rgba(0, 0, 0, 0.07);
}
.webix_win_head > .webix_view > .webix_template .webix_el_box .webix_img_btn_abs:hover,
.webix_win_head > .webix_view > .webix_template .webix_el_icon .webix_icon_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_win_head > .webix_view > .webix_template .webix_control {
  border-color: #3498db;
}
.webix_win_head > .webix_view > .webix_template .webix_icon_button .webix_icon {
  font-size: 23px;
  height: 27px;
  width: 27px;
  color: #ffffff;
}
.webix_win_head > .webix_view > .webix_template .webix_img_btn,
.webix_win_head > .webix_view > .webix_template .webix_img_btn_top {
  border-width: 0;
}
/*----------------------------*/
/* DO NOT CHANGE STYLES BELOW */
/*----------------------------*/
.webix_win_content {
  height: auto;
  width: auto;
  overflow: hidden;
}
.webix_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=20);
  opacity: 0.2;
}
.webix_point_top,
.webix_point_right,
.webix_point_bottom,
.webix_point_left {
  position: absolute;
}
.webix_point_top,
.webix_point_bottom {
  width: 13px;
  height: 7px;
}
.webix_point_left,
.webix_point_right {
  width: 7px;
  height: 13px;
}
.webix_point_top,
.webix_point_bottom,
.webix_point_right,
.webix_point_left {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAArCAIAAADHSy4DAAAABnRSTlMAAAAAAABupgeRAAAACXBIWXMAAABPAAAATwFjiv3XAAAAhElEQVR4nMXVQQoAIQhAUe9/FC/pLAYayUm/EeT6YWGmoqpWharKG7n+XK5nt9L/LurMeV27oZHrReN0M6Oupr5S1GU0vhN1J7L27hp1QYXX1WtE5UxnNXI0ep7+pEGRpr95ooWObbXUm1npXWkFaF3vTqj9c+h8pVOb7gK6Yeje4tvwARi4FgcAh2V/AAAAAElFTkSuQmCC");
}
.webix_point_top {
  background-position: 0 -36px;
}
.webix_point_bottom {
  background-position: 0 0;
}
.webix_point_right {
  background-position: 0 -22px;
}
.webix_point_left {
  background-position: 0 -9px;
}
.webix_resize_handle {
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: 0px;
  line-height: 15px;
  right: -1px;
  text-align: center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAABZJREFUeAFjIAUwUshlpJDLSIhLGAAACQ4AFk79JaMAAAAASUVORK5CYII=);
  cursor: nw-resize;
  z-index:2;
}
.webix_resize_frame {
  position: absolute;
  filter: alpha(opacity=10);
  opacity: 0.1;
  background: #3498db;
  display: block;
  border: 1px solid #196090;
  box-shadow: 1px 3px 6px #000;
}
.webix_resize_cursor {
  cursor: nw-resize;
}
/*basis*/
.webix_tree .webix_scroll_cont > .webix_tree_leaves {
  padding: 9px 10px 9px 9px;
}
.webix_tree_item {
  clear: both;
  height: 28px;
  line-height: 28px;
  white-space: nowrap;
}
.webix_tree_item.webix_selected {
  background: transparent;
}
.webix_tree_item.webix_selected span
{
  color: #2e2e38;
}
.webix_tree_checkbox {
  margin: 1px 0 0;
  padding: 0px;
  float: left;
  vertical-align: middle;
  height: 100%;
  width: 13px;
}
.webix_indeterminate {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3gcCCSwfRyGesAAAAAxpVFh0Q29tbWVudAAAAAAAvK6ymQAAAExJREFUKM9jLCgo+M9AAmBhYGBgaGtrY+Tk5MSrsLCwkIGBgeE/EwOJgGQNLMgcJSUlBgYGBnQ/Md67d498G+jsB6hbGQfWSYykJg0AUMsPVoNrFX0AAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: left center;
  height: 100%;
  width: 13px;
  margin-top: 0 !important;
}
.webix_measure_size input,
.webix_measure_size textarea,
.webix_measure_size select {
  width: 5px;
}
.webix_measure_size div {
  margin-left: -5px;
  float: none;
}
.webix_measure_size div,
.webix_measure_size a {
  display: inline-block !important;
}
.webix_tree_close,
.webix_tree_open,
.webix_tree_none,
.webix_tree_folder,
.webix_tree_file,
.webix_tree_folder_open {
  float: left;
  width: 20px;
  height: 100%;
  cursor: pointer;
  margin: 0px 0px 0px 0px;
  background-repeat: no-repeat;
}
.webix_tree_drag
{
  margin-right:-20px;
}
.webix_tree_close,
.webix_tree_open,
.webix_tree_none {
  background-position: center center;
}
.webix_cell .webix_tree_close,
.webix_cell .webix_tree_open,
.webix_cell .webix_tree_none {
  background-color: transparent;
}
.webix_tree_folder,
.webix_tree_file,
.webix_tree_folder_open {
  background-position: 0 center ;
}
.webix_tree_file,
.webix_tree_folder,
.webix_tree_folder_open {
  margin: 0px 2px;
}
.webix_tree_leaves .webix_tree_leaves {
  margin-left: 18px;
}
.webixLineTree .webix_tree_leaves {
  margin-left: 0px;
}
.webix_tree_close {
  background-image: url("data:image/gif;base64,R0lGODlhEgASALMJAMrKyt3d3ejp6d7f3+/v75aWlvf39////wAAAP///wAAAAAAAAAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6M0I5RTczQjVDMDdBMTFFMTgxRjc4Mzk4M0Q3MjVFQzAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6M0I5RTczQjZDMDdBMTFFMTgxRjc4Mzk4M0Q3MjVFQzAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozQjlFNzNCM0MwN0ExMUUxODFGNzgzOTgzRDcyNUVDMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozQjlFNzNCNEMwN0ExMUUxODFGNzgzOTgzRDcyNUVDMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACH5BAEAAAkALAAAAAASABIAAARJMMlJq704661B+SAIXAVhnKhBFKSZnmuLImhslXPN3ibi+6pdBXc4IIpB2YkGE1IKAoL0ICUInJNCYMDtDgJYiScUGnHO6LQkAgA7");
}
.webix_tree_open {
  background-image: url("data:image/gif;base64,R0lGODlhEgASALMJAMrKyt3d3ejp6d7f3+/v75aWlvf39wAAAP///////wAAAAAAAAAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MzY0QzNGM0VDMDdBMTFFMUE3MDlCNUM2QjU1NDA5RjgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MzY0QzNGM0ZDMDdBMTFFMUE3MDlCNUM2QjU1NDA5RjgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozNjRDM0YzQ0MwN0ExMUUxQTcwOUI1QzZCNTU0MDlGOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozNjRDM0YzREMwN0ExMUUxQTcwOUI1QzZCNTU0MDlGOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACH5BAEAAAkALAAAAAASABIAAARDMMlJq704661B+SAIXAVhnKhBFKSZnmv7wqxVzmpd3Uff5zKEUAi0uV4xm4DAbBIEOkohMKhaB4HoxBMKjTjgsFgSAQA7");
}
.webix_tree_file {
  background-image: url("data:image/gif;base64,R0lGODlhEgASAIABAI6Pj////yH5BAEKAAEALAAAAAASABIAAAInjI+JwM26XAtPyTnTfU5veGyXJ2ZhaRpoSq0AicKlLNJftNojyCMFADs=");
}
.webix_tree_folder {
  background-image: url("data:image/gif;base64,R0lGODlhEgASAIABAI6Pj////yH5BAEKAAEALAAAAAASABIAAAIhjI+py90AI3Syvoqj27mfnn2gZYxkYEpiCqypa27yTC8FADs=");
}
.webix_tree_folder_open {
  background-image: url("data:image/gif;base64,R0lGODlhEgASAIABAI6Pj////yH5BAEKAAEALAAAAAASABIAAAIjjI+py90AI3SyvorjkQ7pDAJBSJaTaGpjKq7pxsJmR9c2UwAAOw==");
}
.webix_tree_img {
  background: transparent;
  float: left;
  width: 25px;
  height: 28px;
  background-position: 0 0;
}
.webix_tree_plus2 {
  background-image: url("data:image/gif;base64,R0lGODlhEgAYAMIDAFVVVYiIiMHBwf///////////////////yH5BAEKAAQALAAAAAASABgAAAM8SLoq/LDFKWl0FuLMNq8faAVkWVLBoK5DgK7A6k6pGqtzlAI83wpAIKM2uLVeNhmSdaSZTKGodEqtWjkJADs=");
  background-repeat: no-repeat;
}
.webix_tree_plus3 {
  background-image: url("data:image/gif;base64,R0lGODlhEgAYAMIDAFVVVYiIiMHBwf///////////////////yH5BAEKAAQALAAAAAASABgAAANCSLoq/LDFKWl0FuLMNq8faAVkWVLBoK5DgK7A6k6pGqtzlAI83wpAIKM2uLVeNhmSdaSZTB9PNLSQcqwZrEVL4SYAADs=");
}
.webix_tree_plus4 {
  background-image: url("data:image/gif;base64,R0lGODlhEgAYAMIDAFVVVYiIiMHBwf///////////////////yH5BAEKAAQALAAAAAASABgAAAM5SLrc/jDKSau9mIbNeQxDKA7BJwJiCYEhGqoPCMwzKdw3ww4uabap38i36nQqgkwSs7w0LU+kMpIAADs=");
}
.webix_tree_minus2 {
  background-image: url("data:image/gif;base64,R0lGODlhEgAYAMIDAFVVVYiIiMHBwf///////////////////yH5BAEKAAQALAAAAAASABgAAAM5SLoq/LDFKWl0FuLMNq8faAVkWVLBoK5DgLKrO6Vw+wI43go8z9AwWQTIEkKIMZTJFGo6n9ColJMAADs=");
  background-repeat: no-repeat;
}
.webix_tree_minus3 {
  background-image: url("data:image/gif;base64,R0lGODlhEgAYAMIDAFVVVYiIiMHBwf///////////////////yH5BAEKAAQALAAAAAASABgAAAM+SLoq/LDFKWl0FuLMNq8faAVkWVLBoK5DgLKrO6Vw+wI43go8z9AwWQTIEkKIMZTJ9PE0QwsnR5qhWqwUbAIAOw==");
}
.webix_tree_minus4 {
  background-image: url("data:image/gif;base64,R0lGODlhEgAYAMIDAFVVVYiIiMHBwf///////////////////yH5BAEKAAQALAAAAAASABgAAAM1SLrc/jDKSau9mIbNeQxDKA7BN4olBJ6kCbwvKcwzs57pc4+5s6OfTqciyBQxx0vSsiQaIwkAOw==");
}
.webix_tree_line1 {
  background-image: url("data:image/gif;base64,R0lGODlhGQAcAIABAMHBwf///yH5BAEKAAEALAAAAAAZABwAAAIxjI8Gyc3r4pP0wCovdnpPz4Gh+JFIZ6KkKrKg68GbjNGVTeGZmeiRP+IphBbi0EgrAAA7");
}
.webix_tree_line2 {
  background-image: url("data:image/gif;base64,R0lGODlhGQAcAIABAMHBwf///yH5BAEKAAEALAAAAAAZABwAAAIpjI8Gyc3r4pP0wCovdnpPz4Gh+JFIZ6KkKgLuC7/mTNf2jef6zvc+XgAAOw==");
  background-repeat: no-repeat;
}
.webix_tree_line3 {
  background-image: url("data:image/gif;base64,R0lGODlhGQAcAIABAMHBwf///yH5BAEKAAEALAAAAAAZABwAAAI1jI8Gyc3r4pP0wCovdnpPz4Gh+JFIZ6KkKgLuC78VC9KeveGYPpsJTwFmfCeixahABoQRXQEAOw==");
}
.webix_tree_line4 {
  background-image: url("data:image/gif;base64,R0lGODlhGQAcAIABAMHBwf///yH5BAEKAAEALAAAAAAZABwAAAIjjI+py+0Po5y02ouz3rxDAIZi+HGAiW6nmmqr22avHGMzUwAAOw==");
}
.webix_tree_blank {
  background-image: url(data:image/gif;base64,R0lGODlhAQABAPABAP///wAAACH5BAEKAAAALAAAAAABAAEAAAICRAEAOw==);
}
.webix_tree_img {
  background-color: #ffffff;
}
.webix_cal_month {
  height: 22px;
  line-height: 20px;
  text-align: center;
  position: relative;
  font-size: 14px;
  font-weight: bold;
  padding-top: 17px;
  padding-bottom: 5px;
  box-sizing: content-box;
}
.webix_cal_prev_button,
.webix_cal_next_button {
  cursor: pointer;
  position: absolute;
  top: 17px;
  width: 30px;
  height: 28px;
  background-repeat: no-repeat;
  background-position: 11.5px 4px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_cal_prev_button {
  left: 17px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAALCAYAAACzkJeoAAAASUlEQVR42p2QMQoAQQgD79EZ0Mqn5xCu2cPdYgUbJ0rMMxXgLeiuKq8gsJAz8weQ0QiwJEfECnr4wdlEnwJ68+yyhReC+c85oRecj0Um+pmo9wAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
}
.webix_cal_next_button {
  right: 17px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAALCAYAAACzkJeoAAAARElEQVR42qXPsQoAIAhF0T76PdDJT7cUmtQhcmk4N8QVQ9JXN2bmgWOgqp+BiCRG2AYAMoi3/LxYdyagAnhA6G93TrABZaJFJjrFY8IAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
}
.webix_view > .webix_cal_header {
  margin: 0px 17px 10px 17px;
  height: auto;
}
.webix_view > .webix_cal_header div {
  float: left;
  text-align: center;
  height: 19px;
  font-size: 11px;
  color: #666666;
  border-bottom: #cecece 1px solid ;
  margin-bottom: 7px;
}
.webix_cal_body {
  margin: 0px 17px 0px 17px;
}
.webix_cal_body .webix_cal_row {
  clear: both;
}
.webix_cal_body .webix_cal_row > div {
  float: left;
  text-align: center;
  height: 100%;
}
.webix_cal_body .webix_selected,
.webix_cal_body .webix_cal_select,
.webix_cal_body .webix_cal_select.webix_cal_today {
  color: #ffffff;
  background: #7393bb;
}
.webix_cal_day,
.webix_cal_week_num {
  /*line-height: 29px;*/
  cursor: pointer;
  font-size: 12px;
  color: #666666;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_cal_week_num {
  color: #bfbfbf;
  cursor: default;
  margin-right: 0px;
  border-right: 1px solid #cecece;
}
.webix_cal_event {
  font-weight: bold;
  color: #C66200;
}
.webix_cal_outside {
  color: #cccccc;
}
.webix_cal_inactive {
  background: #888;
}
.webix_cal_inactive {
  background: #888;
}
.webix_cal_day_disabled,
.webix_cal_day_disabled.webix_cal_event {
  color: #cccccc;
  background: #f0f0f0;
}
.webix_hours .webix_cal_day_disabled,
.webix_minutes .webix_cal_day_disabled,
.webix_hours .webix_cal_day_disabled.webix_cal_event,
.webix_minutes .webix_cal_day_disabled.webix_cal_event {
  background: #ffffff;
  font-weight: normal;
  text-decoration: line-through;
}
.webix_cal_month_name {
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_cal_block {
  float: left;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_selected {
  background: #27ae60;
  color: #ffffff;
}
.webix_cal_footer {
  text-align: center;
  font-size: 12px;
  color: #444;
  margin: 4px 17px;
  padding-top: 2px;
  cursor: pointer;
}
.webix_cal_icons {
  float: right;
  padding: 4px 4px;
}
.webix_cal_time {
  padding: 2px 4px;
}
.webix_cal_time_icons {
  float: left;
}
.webix_cal_icons .webix_cal_icon {
  text-align: center;
  padding: 2px 4px;
}
.webix_cal_icon:hover,
.webix_cal_time:hover,
.webix_cal_month_name:hover {
  text-decoration: underline;
  color: #276fb1;
}
.webix_cal_month_name.webix_readonly:hover {
  text-decoration: none;
  color: #666666;
  cursor: default;
}
.webix_time_header {
  padding: 17px 17px 1px 17px;
}
.webix_time_header .webix_cal_hours,
.webix_time_header .webix_cal_minutes {
  display: inline-block;
  font-size: 14px;
  text-align: center;
  height: 26px;
  line-height: 22px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #dddddd;
}
.webix_time_header .webix_cal_minutes {
  padding-left: 1px;
  border-left: none;
}
.webix_time_header .webix_cal_hours {
  border-right: none !important;
}
.webix_cal_body .webix_hours {
  float: left;
  border-right: 1px solid #cecece;
  padding-right: 1px;
}
.webix_cal_body .webix_minutes {
  float: left;
  padding-left: 1px;
}
.webix_cal_block_empty {
  float: left;
  text-align: center;
}
.webix_time_footer {
  text-align: center;
  font-size: 12px;
  padding: 7px 17px 0 17px;
  cursor: pointer;
}
.webix_cal_done {
  margin-top: 3px;
  padding: 2px 7px;
  font-size: 12px;
  border-radius: 6px;
  border: 1px solid #dddddd;
  font-size: 15px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #3498db;
  border-bottom: 1px solid #268fd5;
  outline: none;
  background: #27ae60;
  color: #ffffff;
  border-color: #27ae60;
  border-bottom: 1px solid #209150;
  box-shadow: none;
  float: right;
}
.webix_cal_blurred_hours {
  background-color: #f7f7f7;
}
.webix_property_line {
  clear: both;
  width: 100%;
  min-height: 24px;
  height: auto;
}
.webix_property_label,
.webix_property_value {
  height: 24px;
  line-height: 24px;
  padding-left: 10px;
  float: left;
  border-bottom: 1px solid #dddddd;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  height: 28px;
  line-height: 27px;
}
.webix_property_label {
  border-right: 1px solid #dddddd;
}
.webix_property_label_line {
  padding-left: 10px;
  color: #2e2e38;
  font-size: 12px;
  font-weight:bold;
  background-color: #f1f1f1;
  /*.webix_el_button input, .webix_el_toggle input {
		border: none;

	}*/
  /*.webixtype_prev:active, .webixtype_next:active, .webixtype_base:active, .webix_inp_counter_next:active, .webix_inp_counter_prev:active{
		background-color: @barButtonActiveColor;
	}*/
  border-bottom: 1px solid #ebebeb;
  line-height: 23px;
}
.webix_property_label_line .webix_el_button,
.webix_property_label_line .webix_el_label,
.webix_property_label_line .webix_inp_label {
  color: #ffffff;
}
.webix_property_label_line .webix_el_label {
  font-size: 18px;
}
.webix_property_label_line .webixtype_base,
.webix_property_label_line .webixtype_prev,
.webix_property_label_line .webixtype_next,
.webix_property_label_line .webix_inp_counter_next,
.webix_property_label_line .webix_inp_counter_prev,
.webix_property_label_line .webix_segment_0,
.webix_property_label_line .webix_segment_1,
.webix_property_label_line .webix_segment_N {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
}
.webix_property_label_line .webix_el_arrow.webixtype_next_arrow {
  border-left: none;
  border-bottom: none;
}
.webix_property_label_line .webix_el_arrow.webixtype_prev_arrow {
  border-right: none;
  border-bottom: none;
}
.webix_property_label_line .webixtype_base:hover,
.webix_property_label_line .webix_inp_counter_next:hover,
.webix_property_label_line .webix_inp_counter_prev:hover,
.webix_property_label_line .webix_segment_0:hover,
.webix_property_label_line .webix_segment_1:hover,
.webix_property_label_line .webix_segment_N:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_property_label_line .webixtype_base:active,
.webix_property_label_line .webix_inp_counter_next:active,
.webix_property_label_line .webix_inp_counter_prev:active,
.webix_property_label_line .webix_segment_0:active,
.webix_property_label_line .webix_segment_1:active,
.webix_property_label_line .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_property_label_line .webixtype_base:focus,
.webix_property_label_line .webix_inp_counter_next:focus,
.webix_property_label_line .webix_inp_counter_prev:focus,
.webix_property_label_line .webix_segment_0:focus,
.webix_property_label_line .webix_segment_1:focus,
.webix_property_label_line .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
}
.webix_property_label_line .webix_inp_static,
.webix_property_label_line input[type=text] {
  border-color: #ffffff;
}
.webix_property_label_line .webix_inp_static:focus,
.webix_property_label_line input[type=text]:focus {
  border-color: #ffffff;
  box-shadow: none;
}
.webix_property_label_line .webix_input_icon {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
  color: #666666;
}
.webix_property_label_line .webix_input_icon:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_property_label_line .webix_input_icon:active,
.webix_property_label_line .webix_input_icon:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_property_label_line .webix_pressed .webixtype_base,
.webix_property_label_line .webix_pressed .webixtype_prev,
.webix_property_label_line .webix_pressed .webixtype_next,
.webix_property_label_line .webix_pressed .webix_el_arrow,
.webix_property_label_line .webix_pressed .webix_inp_counter_next,
.webix_property_label_line .webix_pressed .webix_inp_counter_prev,
.webix_property_label_line .webix_pressed .webix_input_icon,
.webix_property_label_line .webix_pressed .webix_segment_0,
.webix_property_label_line .webix_pressed .webix_segment_1,
.webix_property_label_line .webix_pressed .webix_segment_N {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_property_label_line .webix_selected.webix_segment_0,
.webix_property_label_line .webix_selected.webix_segment_1,
.webix_property_label_line .webix_selected.webix_segment_N {
  border-color: #d9d9d9;
  background-color: #27ae60;
  color: #ffffff;
}
.webix_property_label_line .webix_selected.webix_segment_0:hover,
.webix_property_label_line .webix_selected.webix_segment_1:hover,
.webix_property_label_line .webix_selected.webix_segment_N:hover {
  background-color: #24a259;
}
.webix_property_label_line .webix_selected.webix_segment_0:active,
.webix_property_label_line .webix_selected.webix_segment_1:active,
.webix_property_label_line .webix_selected.webix_segment_N:active {
  background-color: #229955;
}
.webix_property_label_line .webix_selected.webix_segment_0:focus,
.webix_property_label_line .webix_selected.webix_segment_1:focus,
.webix_property_label_line .webix_selected.webix_segment_N:focus {
  background-color: #229955;
}
.webix_property_label_line .webix_segment_1,
.webix_property_label_line .webix_segment_N,
.webix_property_label_line .webix_selected.webix_segment_1,
.webix_property_label_line .webix_selected.webix_segment_N {
  margin-left: 0;
  border-left: none;
}
.webix_property_label_line .webix_img_btn:active,
.webix_property_label_line .webix_img_btn_top:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_property_label_line .webix_img_btn:focus,
.webix_property_label_line .webix_img_btn_top:focus {
  background-color: #2489cc;
}
.webix_property_label_line .webix_img_btn:hover,
.webix_property_label_line .webix_img_btn_top:hover {
  background-color: #268fd5;
}
.webix_property_label_line .webix_icon_button {
  border-radius: 6px;
}
.webix_property_label_line .webix_img_btn_top .webix_img_btn_text,
.webix_property_label_line .webix_img_btn_top .webix_icon,
.webix_property_label_line .webix_el_icon,
.webix_property_label_line .webix_img_btn,
.webix_property_label_line .webix_icon_btn,
.webix_property_label_line .webix_icon_button {
  color: #ffffff;
}
.webix_property_label_line .webix_img_btn_abs .webix_icon,
.webix_property_label_line .webix_img_btn_abs .webix_img_btn_text {
  color: #ffffff;
}
.webix_property_label_line .webix_img_btn_abs {
  color: #ffffff;
}
.webix_property_label_line .webix_el_box .webix_img_btn_abs:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_property_label_line .webix_el_icon .webix_icon_button:focus {
  background-color: rgba(0, 0, 0, 0.07);
}
.webix_property_label_line .webix_el_box .webix_img_btn_abs:hover,
.webix_property_label_line .webix_el_icon .webix_icon_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_property_label_line .webix_control {
  border-color: #3498db;
}
.webix_property_label_line .webix_icon_button .webix_icon {
  font-size: 23px;
  height: 27px;
  width: 27px;
  color: #ffffff;
}
.webix_property_label_line .webix_img_btn,
.webix_property_label_line .webix_img_btn_top {
  border-width: 0;
}
.webix_property_col_val {
  width: 100%;
  height: 100%;
  -moz-user-select: text;
  -webkit-user-select: text;
  user-select: text;
}
.webix_property_col_ind {
  float: right;
  border: 1px solid #dddddd;
  border-radius: 2px;
  width: 30px;
  cursor: pointer;
  height: 13px;
  margin: 3px 10px 3px 0;
}
.webix_property_value {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.webix_property_check {
  margin-top: 5px;
}
/*style used by tooltip's container*/
.webix_tooltip {
  display: none;
  position: absolute;
  z-index: 10000;
  background-color: #ffffff;
  padding: 5px 10px;
  border: 1px solid #dddddd;
  font-family: StolzlRegular,sans-serif;
  font-size: 15px;
  color: #666666;
  border-radius: 0px;
  box-shadow: 1px 1px 0px 0px #CCC;
}
.webix_color_row {
  clear: both;
  white-space: nowrap;
}
.webix_color_palette {
  text-align: left;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_color_row,
.webix_color_row div,
.webix_color_selector {
  line-height: 0;
  font-size: 0;
}
.webix_color_row div {
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: default;
}
.webix_color_selector {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  border: 2px solid #f3f3f3;
}
.webix_daterange .webix_view {
  background-color: #ffffff;
}
.webix_daterange .webix_range_0 .webix_cal_next_button,
.webix_daterange .webix_range_N .webix_cal_prev_button {
  visibility: hidden;
}
.webix_daterange .webix_range_1 .webix_cal_next_button,
.webix_daterange .webix_range_1 .webix_cal_prev_button {
  visibility: hidden;
}
.webix_daterange .webix_range_1 .webix_cal_next_button:hover,
.webix_daterange .webix_range_1 .webix_cal_prev_button:hover {
  cursor: default;
}
.webix_daterange .webix_range_1 .webix_cal_month_name:hover {
  text-decoration: none;
  color: inherit;
  cursor: default;
}
.webix_daterange .webix_range_left .webix_cal_next_button:hover,
.webix_daterange .webix_range_right .webix_cal_prev_button:hover {
  cursor: default;
}
.webix_cal_range {
  background-color: #e1e8f0;
}
.webix_cal_range_start {
  border-radius: 2px 0 0 2px;
}
.webix_cal_range_end {
  border-radius: 0 2px 2px 0;
}
.webix_cal_range_start,
.webix_cal_range_end,
.webix_cal_event.webix_cal_range_start,
.webix_cal_event.webix_cal_range_end,
.webix_cal_body .webix_cal_today.webix_cal_range_start,
.webix_cal_body .webix_cal_today.webix_cal_range_end {
  background-color: #0079a5;
  color: #ffffff;
}
.webix_cal_outside.webix_cal_range,
.webix_cal_body .webix_cal_outside.webix_cal_range_start,
.webix_cal_body .webix_cal_outside.webix_cal_range_end,
.webix_cal_body .webix_cal_outside.webix_cal_today.webix_cal_range_start,
.webix_cal_body .webix_cal_outside.webix_cal_today.webix_cal_range_end {
  background-color: transparent;
  color: #cccccc;
}
.webix_daterange .webix_cal_icons {
  text-align: right;
  padding: 0 4px;
}
.webix_daterange .webix_cal_icons .webix_cal_icon {
  border-top: 1px solid #dddddd;
}
.webix_daterange .webix_cal_time {
  display: none;
}
.webix_daterange .webix_cal_timepicker .webix_range_time_start {
  display: block;
}
.webix_daterange .webix_range_timepicker .webix_cal_time {
  display: block;
  padding: 5px 15px;
}
.webix_daterange .webix_range_timepicker .webix_range_time_start {
  float: left;
}
.webix_daterange .webix_range_timepicker .webix_range_time_end {
  float: right;
}
.webix_daterange .webix_range_footer .webix_cal_footer {
  padding: 0;
}
.webix_view.webix_toolbar .webix_el_button {
  background: transparent;
}
.webix_el_button input,
.webix_el_toggle input,
.webix_el_button button,
.webix_el_toggle button,
.webixbutton {
  border-radius: 2px;
  border: 1px solid #dddddd;
  height: 100%;
  width: 100%;
  cursor: pointer;
  color: #ffffff;
  font-size: 12px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #0079a5;
  outline: none;
  -webkit-appearance: none;
}
.webix_layout_form .webix_el_button button
{
  border-color: #dadada;
  background-color: #f1f1f1;
  color: #787574;
}
.webix_layout_form .webix_el_button button:active,
.webix_layout_form .webix_el_button button:hover:active
{
  color: #fff;
  border: 1px solid #41546d;
  background-color: #04597a;
  box-shadow:none;
}
.webix_layout_form .webix_el_button button:hover
{
  color: #354866;
  background-color: #e0e0e0;
  border-color: #c8c8c8;
}
.webix_el_button .webix_img_btn,
.webix_el_toggle .webix_img_btn,
.webix_el_button .webix_img_btn_top,
.webix_el_toggle .webix_img_btn_top {
  color: #666666;
}
.webix_el_button .webix_img_btn .webix_img_btn_text,
.webix_el_toggle .webix_img_btn .webix_img_btn_text,
.webix_el_button .webix_img_btn_top .webix_img_btn_text,
.webix_el_toggle .webix_img_btn_top .webix_img_btn_text {
  color: #666666;
}
.webix_el_button .webix_img_btn,
.webix_el_toggle .webix_img_btn,
.webix_el_button .webix_img_btn_top,
.webix_el_toggle .webix_img_btn_top {
  border: 0;
}
.webixtype_base,
.webixtype_prev,
.webixtype_next,
.webixbutton {
  background: #0079a5;
  color: #ffffff;
  font-size: 15px;
  padding: auto 2px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #0079a5;
  outline: none;
  padding: 0px;
  border: 0px;
}
.webixtype_form {
  font-size: 13px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #04597a;
  outline: none;
  background: #04597a;
  color: #ffffff;
  box-shadow: none;
}
.webix_el_button .webixtype_danger {
  background: #ce5545;
  border: 1px solid #ce5545;
  border-bottom: 1px solid #b13e2f;
  color: #fff;
}
.webix_el_button .webix_el_box,
.webix_el_toggle .webix_el_box {
  position: relative;
}
.webix_el_button .webix_el_arrow,
.webix_el_toggle .webix_el_arrow {
  position: absolute;
  z-index: 1;
}
.webixtype_prev,
.webixtype_next {
  position: absolute;
  z-index: 2;
  top: 0px;
}
input.webixtype_prev {
  border-left-width: 0;
  border-radius: 0px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
input.webixtype_next {
  border-right-width: 0;
  border-radius: 0px;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.webix_el_arrow {
  width: 18px;
  height: 18px;
  font-size: 15px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #3498db;
  border-bottom: 1px solid #268fd5;
  outline: none;
  color: #ffffff;
  background: #3498db;
  border-bottom: 1px solid #2386c8;
  border: 1px solid #dddddd;
}
.webixtype_prev_arrow {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
.webixtype_next_arrow {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.webix_img_btn_abs_top .webix_icon,
.webix_img_btn_top .webix_icon {
  font-size: 22px;
  position: absolute;
  top: 8px;
  left: 50%;
}
.webix_img_btn_abs .webix_icon {
  margin-left: 8px;
}
.webix_img_btn_abs.webix_img_btn_abs_top .webix_icon,
.webix_img_btn_top .webix_icon {
  margin-left: -10px;
}
.webix_img_btn,
.webix_img_btn_top {
  vertical-align: top;
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0;
}
.webix_img_btn,
.webix_img_btn_abs {
  cursor: pointer;
  text-align: left;
  background-repeat: no-repeat;
  background-position: 2px center;
  height: 100%;
  font-size: 15px;
}
.webix_img_btn .webix_icon,
.webix_img_btn_abs .webix_icon {
  color: #ffffff;
}
.webix_img_btn_abs,
.webix_img_btn {
  padding: 0;
}
.webix_img_btn_abs .webix_image,
.webix_img_btn .webix_image {
  background-repeat: no-repeat;
  background-position: center center;
  float: left;
  vertical-align: top;
}
.webix_img_btn_top .webix_image {
  background-repeat: no-repeat;
  background-position: center top;
}
.webix_img_btn_abs.webix_img_btn_abs_top .webix_image {
  background-position: center 5px;
}
/*.webix_img_btn:hover, .webix_img_btn_top:hover{
	background-color: #ddd;
	background-color: rgba(250,250,250,0.5);
};*/
/*.webix_img_btn:active, .webix_img_btn_top:active{
	background-color: #ddd;
	background-color: rgba(100,100,100,0.1);
};*/
.webix_img_btn_top {
  text-align: center;
  background-repeat: no-repeat;
  background-position: center 5px;
}
.webix_img_btn_text {
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.webix_img_btn_abs {
  /*position:absolute;
	top:0px; left:0px;
	width:100%;*/
  background-position: center center;
}
.webix_img_btn_top {
  background-position: center top;
  vertical-align: top;
}
.webix_img_btn_abs .webix_img_btn_text {
  bottom: 8px;
}
.webix_pressed .webix_img_btn,
.webix_pressed .webix_img_btn_top {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_pressed .webix_img_btn_abs + input,
.webix_pressed .webixtype_base {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_control .webix_disabled_box .webixtype_base,
.webix_control .webix_disabled_box .webixtype_prev,
.webix_control .webix_disabled_box .webixtype_next,
.webix_control .webix_disabled_box .webixtype_danger,
.webix_control .webix_disabled_box .webixtype_form,
.webix_control .webix_disabled_box .webix_el_arrow,
.webix_control .webix_disabled_box .webix_img_btn {
  color: #aaaaaa;
  /*background: #e9e9e9;*/
  border: 1px solid #e9e9e9;
  text-shadow: none;
}
.webix_control .webix_disabled_box .webix_img_btn_text {
  color: #aaaaaa;
}
.webix_control .webix_disabled_box .webix_image {
  opacity: 0.4;
}
.webix_disabled_view.webix_control .webix_icon_btn,
.webix_disabled_view.webix_control .webix_icon {
  color: #aaaaaa;
}
.webix_layout_toolbar.webix_toolbar .webix_disabled_view.webix_control .webix_icon,
.webix_layout_toolbar.webix_toolbar .webix_disabled_view.webix_control .webix_disabled_box .webix_img_btn_text,
.webix_layout_toolbar.webix_toolbar .webix_disabled_view.webix_control .webix_icon_btn {
  color: #aaaaaa;
}
.webixbutton {
  width: auto;
  height: auto;
  padding: 8px 15px;
}
.webix_richtext_editor {
  height: 100%;
}
.webix_template .webix_richtext_editor:focus {
  outline: none;
}
.webix_richtext_container .webix_template {
  padding: 10px;
  overflow-x: auto;
  border: solid #ccc;
  border-width: 0px 1px 1px;
  border-radius: 0px 0px 6px 6px;
}
.webix_control {
  background: transparent;
  overflow:visible;
}
.webix_control input,
.webix_control button,
.webix_control textarea,
.webix_el_label,
.webix_inp_label,
.webix_inp_top_label,
.webix_inp_bottom_label {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: StolzlRegular,sans-serif;
}
.webix_el_box {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 3px 2px;
  height: 100%;
  width: 100%;
  font-size: 12px;
  overflow: hidden;
}
.webix_el_text input,
.webix_el_search input,
.webix_el_combo input,
.webix_el_datepicker input,
.webix_el_colorpicker input {
  height: 100%;
  padding: 0px 10px;
  border: 1px solid #dddddd;
  color: #2f3438;
  font-size: 12px;
  background: #ffffff;
  font-family: StolzlRegular,sans-serif;
  border-color: #cccccc;
  /*.altBackColor;
	.altBackBorderColor;*/
  -webkit-appearance: none;
  border-radius: 0;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #787574;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #787574;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #787574;
}
:-moz-placeholder { /* Firefox 18- */
  color: #787574;
}

.webix_ss_filter input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  font-family: 'Font Awesome 5 Pro';
  color: #959fb0 !important;
}
.webix_ss_filter input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-family: 'Font Awesome 5 Pro';
  color: #959fb0 !important;
}
.webix_ss_filter input::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-family: 'Font Awesome 5 Pro';
  color: #959fb0 !important;
}
.webix_ss_filter input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Font Awesome 5 Pro';
  color: #959fb0 !important;
}
.webix_ss_filter input::-ms-input-placeholder { /* Microsoft Edge */
  font-family: 'Font Awesome 5 Pro';
  color: #959fb0 !important;
}
.webix_ss_filter input::placeholder { /* Most modern browsers */
  font-family: 'Font Awesome 5 Pro';
  color: #959fb0 !important;
}


.webix_inp_label,
.webix_inp_top_label,
.webix_label_right {
  color: #2f3438;
  text-transform: none;
  font-size: 13px;
}
/*.webix_toolbar .webix_inp_label{
	padding-top:(@inputHeight - 11 )/2;
}*/
.webix_inp_label {
  float: left;
  padding-top: 1px;
  padding-left: 3px;
  padding-right: 7.5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color:#2f3438;
}
.webix_inp_top_label {
  float: none;
  padding: 3px 2px;
  padding-bottom: 0px;
  white-space:nowrap;
  overflow:hidden;
}
.webix_inp_bottom_label {
  font-size: 13px;
  color: #737373;
}
.webix_label_right {
  padding-left: 7.5px;
}
.webix_el_checkbox .webix_label_right {
  display: inline-block;
  padding-top: 2px;
}
.webix_el_label {
  color: #666666;
  line-height: 32px;
  padding: 3px 2px;
  color: #2f3438;
  text-transform: none;
  font-size: 13px;

}
.webix_toolbar .webix_el_label {
  /* padding-left: 10px; */
  text-transform: none;
}
.webix_el_select select {
  height: 100%;
  font-size: 15px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #dddddd;
}
body:not(:-moz-handler-blocked) .webix_el_select select {
  padding: .2em;
}
.webix_el_group,
.webix_all_segments {
  height: 100%;
  white-space: nowrap;
}
.webix_inp_counter_next,
.webix_inp_counter_prev,
.webix_inp_counter_value {
  border: 1px solid #dddddd;
  cursor: pointer;
  -webkit-appearance: none;
  width: 20px;
  height: 100%;
  vertical-align: top;
}
.webix_inp_counter_value {
  font-size: 15px;
  width: 50px;
  color: #666666;
  text-align: center;
  border-left-width: 0px;
  border-right-width: 0px;
  -webkit-appearance: none;
  border-radius: 0;
}
/*body:not(:-moz-handler-blocked) .webix_inp_counter_value{
	 position:relative; top:-1px;
}*/
.webix_inp_counter_prev {
  padding-left: 6px;
  font-size: 15px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #3498db;
  border-bottom: 1px solid #268fd5;
  outline: none;
  color: #ffffff;
  background: #3498db;
  border-bottom: 1px solid #2386c8;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.webix_inp_counter_next {
  padding-left: 6px;
  font-size: 15px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #3498db;
  border-bottom: 1px solid #268fd5;
  outline: none;
  color: #ffffff;
  background: #3498db;
  border-bottom: 1px solid #2386c8;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.webix_el_textarea textarea {
  border: 1px solid #dddddd;
  height: 100%;
  margin: 0;
  padding: 5px;
  color: #666666;
  resize: none;
  font-size: 13px;
  font-family: StolzlRegular,sans-serif;
  padding-left: 10px;
  padding-right: 10px;
}
.webix_el_textarea .webix_inp_label {
  padding-top: 9.5px;
}
.webix_segment_0,
.webix_segment_1 {
  border: 1px solid #dddddd;
  border-right-width: 0px;
  height: 100%;
}
.webix_segment_0 {
  -webkit-border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.webix_segment_N {
  border: 1px solid #dddddd;
  height: 100%;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.webix_segment_N:first-child {
  -webkit-border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.webix_segment_0,
.webix_segment_1,
.webix_segment_N {
  -webkit-appearance: none;
  background: #3498db;
  border-bottom: 1px solid #2386c8;
  color: #ffffff;
  font-size: 15px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #3498db;
  border-bottom: 1px solid #268fd5;
  outline: none;
  cursor: pointer;
  padding: 0;
}
.webix_segment_0.webix_selected,
.webix_segment_1.webix_selected,
.webix_segment_N.webix_selected {
  color: #ffffff;
  background: #27ae60;
  border-color: #27ae60;
}
.webix_inp_static {
  border: 1px solid #dddddd;
  background: white;
  overflow: hidden;
  padding: 0px 10px;
  height: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
.webix_hcell.webix_div_filter .webix_inp_static
{
  border-color:#c7d2e5;
  border-top:1px solid white;
  border-left:1px solid white;
  border-right:1px solid white;
}
.webix_inp_static .webix_placeholder {
  color: #787574;
}
.webix_el_combo,
.webix_el_richselect,
.webix_el_datepicker,
.webix_el_colorpicker,
.webix_el_search {
  font-size: 15px;
}
.webix_el_combo .webix_el_box,
.webix_el_richselect .webix_el_box,
.webix_el_datepicker .webix_el_box,
.webix_el_colorpicker .webix_el_box,
.webix_el_search .webix_el_box {
  color: #666666;
  position: relative;
}
.webix_el_combo input,
.webix_el_richselect input,
.webix_el_datepicker input,
.webix_el_colorpicker input,
.webix_el_search input,
.webix_el_combo .webix_inp_static,
.webix_el_richselect .webix_inp_static,
.webix_el_datepicker .webix_inp_static,
.webix_el_colorpicker .webix_inp_static,
.webix_el_search .webix_inp_static {
  color:#2f3438;
  padding-right: 24px;
  background: #ffffff;
  font-family: StolzlRegular,sans-serif;
  border-color: #dadada;
  /*.altBackColor;
	.altBackBorderColor;*/
  -webkit-appearance: none;
}
.webix_el_combo .webix_input_icon,
.webix_el_richselect .webix_input_icon,
.webix_el_datepicker .webix_input_icon,
.webix_el_colorpicker .webix_input_icon,
.webix_el_search .webix_input_icon {
  right: 2px;
  background: transparent;
  z-index: 1;
  position: absolute;
}
.webix_el_combo .webix_input_icon,
.webix_el_richselect .webix_input_icon {
  padding-left: 7px;
  width: 19px;
}
.webix_el_datepicker .webix_input_icon,
.webix_el_colorpicker .webix_input_icon,
.webix_el_search .webix_input_icon {
  padding-left: 5px;
  width: 21px;
}
/*Remove button padding in FF*/
.webix_view button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.webix_el_radio .webix_inp_label {
  line-height: normal !important;
  padding-top: 6.5px;
}
.webix_inp_radio_border input,
.webix_el_checkbox input {
  margin: 0px;
  padding: 0px;
  float: left;
}
.webix_line_break {
  clear: both;
}
.webix_custom_checkbox,
.webix_custom_radio {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_radio_option {
  display: table;
  float: left;
  height: 32px;
  margin-right: 20px;
}
.webix_inp_radio_border,
.webix_el_radio .webix_label_right {
  display: table-cell;
  vertical-align: middle;
}
.webix_invalid .webix_el_box input,
.webix_invalid .webix_custom_radio,
.webix_invalid .webix_el_box .webix_inp_static,
.webix_invalid .webix_el_box select,
.webix_invalid .webix_el_box textarea,
input.webix_invalid,
select.webix_invalid,
textarea.webix_invalid {
  background-color: #fff6f6;
  border-color: #fea5a8;
}
.webix_invalid .webix_inp_bottom_label {
  font-size: 13px;
  color: #fd595f;
}
.webix_el_colorpicker .webix_input_icon {
  padding-left: 0;
  top: 7px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 6px;
}
.webix_el_search .webix_el_box {
  position: relative;
}
.webix_el_search input {
  padding-right: 30px;
}
.webix_el_search span {
  position: absolute;
  right: 19px;
  cursor: pointer;
  padding-left: 7px;
}
.webix_view.webix_control > .webix_disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  opacity: 0;
}
.webix_disabled_view.webix_control .webix_image {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.webix_view.webix_control .webix_disabled_box label,
.webix_view.webix_control .webix_disabled_box .webix_input_icon,
.webix_view.webix_control .webix_disabled_box button {
  color: #aaaaaa;
}
.webix_view.webix_control .webix_disabled_box input,
.webix_view.webix_control .webix_disabled_box select,
.webix_view.webix_control .webix_disabled_box textarea,
.webix_view.webix_control .webix_disabled_box .webix_inp_static {
  color: #aaaaaa;
  background: #eeeeee;
}
.webix_view.webix_control .webix_disabled_box .webix_inp_counter_prev,
.webix_view.webix_control .webix_disabled_box .webix_inp_counter_next,
.webix_view.webix_control .webix_disabled_box .webix_segment_0,
.webix_view.webix_control .webix_disabled_box .webix_segment_1,
.webix_view.webix_control .webix_disabled_box .webix_segment_N {
  color: #aaaaaa;
  background: #e9e9e9;
  border: 1px solid #e9e9e9;
  text-shadow: none;
}
.webix_disabled_top_label {
  color: #aaaaaa;
}
.webix_el_label.webix_required > div:after,
.webix_required label:after,
label.webix_required:after
{
  padding-left: 4px;
  content: "*";
  color: red;
}
.webix_multicombo .webix_inp_static {
  padding: 0 3px;
}
.webix_el_text .webix_multicombo_input {
  background-color: transparent;
  border: 0;
  float: left;
  vertical-align: middle;
  padding: 0px;
  color: #666666;
  font-size: 15px;
  font-family: StolzlRegular,sans-serif;
  margin-left: 7px;
}
.webix_el_text .webix_multicombo_input:focus {
  border: 0;
  box-shadow: none;
}
.webix_multicombo_listbox {
  width: auto;
  line-height: 1;
  margin: 0;
  padding: 0;
  list-style: none;
}
.webix_multicombo_value,
.webix_multicombo_tag {
  padding: 0 5px 0 7px;
  background-color: #f2f2f2;
  display: inline-block;
  margin: 3px;
  float: left;
  border-radius: 6px;
}
.webix_multicombo_tag {
  min-width: 15px;
  text-align: center;
  padding: 0 5px 0 5px;
}
.webix_multicombo_delete {
  margin-left: 10px;
  padding: 0 5px ;
  color: #404040;
  cursor: pointer;
}
.webix_multilist .webix_selected {
  background: #f2f2f2;
  color: #666666;
}
.webix_el_tabbar {
  background: transparent;
}
.webix_all_tabs {
  height: 100%;
  display: table;
}
.webix_tab_filler {
  display: table-cell;
  background: transparent;
  font-size: 1px;
  border-bottom: 1px solid #dddddd;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_tab_filler:first-child {
  border-right: 1px solid #dddddd;
}
.webix_item_tab:first-child {
  border-left: 1px solid #dddddd;
}
.webix_item_tab {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #dadada;
  border-left-width: 0px;
  background: #f6f6f6;
  color: #354866;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 0px;
  box-sizing: border-box;
}
.webixtype_bottom .webix_item_tab {
  border-radius: 0;
}
.webix_item_tab.webix_selected {
  border-bottom-width: 0px;
  background: white;
  color: #354866;
  border-top-color: #187093;
  font-weight: bold;
}
.webixtype_bottom .webix_item_tab.webix_selected {
  border-bottom-width: 1px ;
  color: #ffffff;
  background: #27ae60;
}
.webix_before_all_tabs {
  width: 100%;
}
.webix_after_all_tabs {
  width: 100%;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_view.webix_control .webix_disabled_box .webix_item_tab {
  color: #aaaaaa;
  background: #e9e9e9;
  border: 1px solid #e9e9e9;
  text-shadow: none;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn .webix_icon_btn {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 3px;
}
.webix_el_tabbar .webixtype_icon .webix_item_tab,
.webix_el_tabbar .webixtype_iconTop .webix_item_tab,
.webix_el_tabbar .webixtype_image .webix_item_tab {
  padding: 3px 0;
  border-radius: 0;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn_top,
.webix_el_tabbar .webixtype_iconTop .webix_img_btn_top,
.webix_el_tabbar .webixtype_image .webix_img_btn_top {
  background-position: center 0;
  position: relative;
  height: 100%;
}
.webix_tab_more_icon {
  cursor: pointer;
  display: table-cell;
  overflow: hidden;
  text-align: center;
  box-sizing: border-box;
  vertical-align: middle;
  padding: 0 3px;
}
.webix_tab_more_icon .webix_icon {
  width: 100%;
}
.webix_tab_list .webix_list_item:last-child {
  border-bottom: none;
}
.webix_icon.webix_tab_close {
  font-size: 12px;
  float: right;
  padding: 4px;
  margin-top: -4px;
}
.webix_menu,
.webix_menu-x {
  background: #dddddd;
}
.webix_menu .webix_list_item:hover,
.webix_menu-x .webix_list_item:hover,
.webix_menu .webix_list_item:active,
.webix_menu-x .webix_list_item:active,
.webix_menu .webix_list_item:focus,
.webix_menu-x .webix_list_item:focus {
  background: rgba(100, 100, 100, 0.1);
}
.webix_menu .webix_list_item,
.webix_menu-x .webix_list_item {
  border: none;
  text-decoration: none;
  background: transparent;
  height: 34px;
  color:#354866;
}
.webix_menu .webix_list_item .webix_submenu_icon,
.webix_menu-x .webix_list_item .webix_submenu_icon {
  float: right;
  text-align: right;
  position: relative;
  margin-left: 4px;
}
.webix_menu-x .webix_list_item:first-child {
  margin-left: 0px;
}
.webix_menu-x .webix_submenu_icon {
  top: 13px;
}
.webix_menu-x {
  background-color: white;
  border: none;
  background: transparent;
}
.webix_menu-x .webix_list_item {
  display: inline-block;
  line-height: 30px;
}
.webix_menu-x .webix_list_item {
  border-right: 0px solid #fff;
  border-left: 0px;
  background: #3498db;
  color: #fff;
  font-size: 15px;
}
.webix_menu-x .webix_list_item .webix_submenu_icon:before {
  content: '\f107';
}
.webix_menu-x .webix_list_item .webix_icon_btn {
  color: #fff;
  text-align: left;
}
.webix_menu-x .webix_list_item:hover,
.webix_menu-x .webix_list_item:active,
.webix_menu-x .webix_list_item:focus {
  background-color: #268fd5;
}
.webix_menu-x .webix_list_item:first-child {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.webix_menu-x .webix_list_item:last-child {
  border-right: none;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
/* Submenu */
.webix_menu {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 0px;
}
.webix_menu .webix_list_item {
  display: block;
}
.webix_menu .webix_context_separator {
  height: 3px;
  padding: 1px 3px 0 3px;
}
.webix_menu .webix_context_separator .sep_line {
  height: 1px;
  border-top: 1px solid #dddddd;
}
.webix_menu .webix_submenu_icon {
  top: 11px;
}
.webix_menu .webix_context_spacer {
  height: 34px;
}
.webix_menu_link {
  color: inherit;
  text-decoration: none;
  display: block;
  height: 100%;
}
/* Toolbar */
.webix_layout_toolbar {
  color: #ffffff;
  font-size: 18px;
  background: #3498db;
  /*.webix_el_button input, .webix_el_toggle input {
		border: none;

	}*/
  /*.webixtype_prev:active, .webixtype_next:active, .webixtype_base:active, .webix_inp_counter_next:active, .webix_inp_counter_prev:active{
		background-color: @barButtonActiveColor;
	}*/
}
.webix_layout_toolbar .webix_el_button,
.webix_layout_toolbar .webix_el_label,
.webix_layout_toolbar .webix_inp_label {
  color: #ffffff;
}
.webix_layout_toolbar .webix_el_label {
  font-size: 18px;
}
.webix_layout_toolbar .webixtype_base,
.webix_layout_toolbar .webixtype_prev,
.webix_layout_toolbar .webixtype_next,
.webix_layout_toolbar .webix_inp_counter_next,
.webix_layout_toolbar .webix_inp_counter_prev,
.webix_layout_toolbar .webix_segment_0,
.webix_layout_toolbar .webix_segment_1,
.webix_layout_toolbar .webix_segment_N {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
}
.webix_layout_toolbar .webix_el_arrow.webixtype_next_arrow {
  border-left: none;
  border-bottom: none;
}
.webix_layout_toolbar .webix_el_arrow.webixtype_prev_arrow {
  border-right: none;
  border-bottom: none;
}
.webix_layout_toolbar .webixtype_base:hover,
.webix_layout_toolbar .webix_inp_counter_next:hover,
.webix_layout_toolbar .webix_inp_counter_prev:hover,
.webix_layout_toolbar .webix_segment_0:hover,
.webix_layout_toolbar .webix_segment_1:hover,
.webix_layout_toolbar .webix_segment_N:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_layout_toolbar .webixtype_base:active,
.webix_layout_toolbar .webix_inp_counter_next:active,
.webix_layout_toolbar .webix_inp_counter_prev:active,
.webix_layout_toolbar .webix_segment_0:active,
.webix_layout_toolbar .webix_segment_1:active,
.webix_layout_toolbar .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: none;
}
.webix_layout_toolbar .webixtype_base:focus,
.webix_layout_toolbar .webix_inp_counter_next:focus,
.webix_layout_toolbar .webix_inp_counter_prev:focus,
.webix_layout_toolbar .webix_segment_0:focus,
.webix_layout_toolbar .webix_segment_1:focus,
.webix_layout_toolbar .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
}
.webix_layout_toolbar .webix_inp_static,
.webix_layout_toolbar input[type=text] {
  border-color: #ffffff;
}
.webix_layout_toolbar .webix_inp_static:focus,
.webix_layout_toolbar input[type=text]:focus {
  border-color: #ffffff;
  box-shadow: none;
}
.webix_layout_toolbar .webix_input_icon {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
  color: #666666;
}
.webix_layout_toolbar .webix_input_icon:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_layout_toolbar .webix_input_icon:active,
.webix_layout_toolbar .webix_input_icon:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_layout_toolbar .webix_pressed .webixtype_base,
.webix_layout_toolbar .webix_pressed .webixtype_prev,
.webix_layout_toolbar .webix_pressed .webixtype_next,
.webix_layout_toolbar .webix_pressed .webix_el_arrow,
.webix_layout_toolbar .webix_pressed .webix_inp_counter_next,
.webix_layout_toolbar .webix_pressed .webix_inp_counter_prev,
.webix_layout_toolbar .webix_pressed .webix_input_icon,
.webix_layout_toolbar .webix_pressed .webix_segment_0,
.webix_layout_toolbar .webix_pressed .webix_segment_1,
.webix_layout_toolbar .webix_pressed .webix_segment_N {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_layout_toolbar .webix_selected.webix_segment_0,
.webix_layout_toolbar .webix_selected.webix_segment_1,
.webix_layout_toolbar .webix_selected.webix_segment_N {
  border-color: #d9d9d9;
  background-color: #27ae60;
  color: #ffffff;
}
.webix_layout_toolbar .webix_selected.webix_segment_0:hover,
.webix_layout_toolbar .webix_selected.webix_segment_1:hover,
.webix_layout_toolbar .webix_selected.webix_segment_N:hover {
  background-color: #24a259;
}
.webix_layout_toolbar .webix_selected.webix_segment_0:active,
.webix_layout_toolbar .webix_selected.webix_segment_1:active,
.webix_layout_toolbar .webix_selected.webix_segment_N:active {
  background-color: #229955;
}
.webix_layout_toolbar .webix_selected.webix_segment_0:focus,
.webix_layout_toolbar .webix_selected.webix_segment_1:focus,
.webix_layout_toolbar .webix_selected.webix_segment_N:focus {
  background-color: #229955;
}
.webix_layout_toolbar .webix_segment_1,
.webix_layout_toolbar .webix_segment_N,
.webix_layout_toolbar .webix_selected.webix_segment_1,
.webix_layout_toolbar .webix_selected.webix_segment_N {
  margin-left: 0;
  border-left: none;
}
.webix_layout_toolbar .webix_img_btn:active,
.webix_layout_toolbar .webix_img_btn_top:active {
}
.webix_layout_toolbar .webix_img_btn:focus,
.webix_layout_toolbar .webix_img_btn_top:focus {
  background-color: #2489cc;
}
.webix_layout_toolbar .webix_img_btn:hover,
.webix_layout_toolbar .webix_img_btn_top:hover {
  background-color: #268fd5;
}
.webix_layout_toolbar .webix_icon_button {
  border-radius: 6px;
}
.webix_layout_toolbar .webix_img_btn_top .webix_img_btn_text,
.webix_layout_toolbar .webix_img_btn_top .webix_icon,
.webix_layout_toolbar .webix_el_icon,
.webix_layout_toolbar .webix_img_btn,
.webix_layout_toolbar .webix_icon_btn,
.webix_layout_toolbar .webix_icon_button {
  color: #ffffff;
}
.webix_layout_toolbar .webix_img_btn_abs .webix_icon,
.webix_layout_toolbar .webix_img_btn_abs .webix_img_btn_text {
  color: #ffffff;
}
.webix_layout_toolbar .webix_img_btn_abs {
  color: #ffffff;
}
.webix_layout_toolbar .webix_el_box .webix_img_btn_abs:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_layout_toolbar .webix_el_icon .webix_icon_button:focus {
  background-color: rgba(0, 0, 0, 0.07);
}
.webix_layout_toolbar .webix_el_box .webix_img_btn_abs:hover,
.webix_layout_toolbar .webix_el_icon .webix_icon_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_layout_toolbar .webix_control {
  border-color: #3498db;
}
.webix_layout_toolbar .webix_icon_button .webix_icon {
  font-size: 23px;
  height: 27px;
  width: 27px;
  color: #ffffff;
}
.webix_layout_toolbar .webix_img_btn,
.webix_layout_toolbar .webix_img_btn_top {
  border-width: 0;
}
.webix_toolbar.webix_layout_subbar {
  color: #ffffff;
  font-size: 18px;
  background: #3498db;
  /*.webix_el_button input, .webix_el_toggle input {
		border: none;

	}*/
  /*.webixtype_prev:active, .webixtype_next:active, .webixtype_base:active, .webix_inp_counter_next:active, .webix_inp_counter_prev:active{
		background-color: @barButtonActiveColor;
	}*/
}
.webix_toolbar.webix_layout_subbar .webix_el_button,
.webix_toolbar.webix_layout_subbar .webix_el_label,
.webix_toolbar.webix_layout_subbar .webix_inp_label {
  color: #ffffff;
}
.webix_toolbar.webix_layout_subbar .webix_el_label {
  font-size: 18px;
}
.webix_toolbar.webix_layout_subbar .webixtype_base,
.webix_toolbar.webix_layout_subbar .webixtype_prev,
.webix_toolbar.webix_layout_subbar .webixtype_next,
.webix_toolbar.webix_layout_subbar .webix_inp_counter_next,
.webix_toolbar.webix_layout_subbar .webix_inp_counter_prev,
.webix_toolbar.webix_layout_subbar .webix_segment_0,
.webix_toolbar.webix_layout_subbar .webix_segment_1,
.webix_toolbar.webix_layout_subbar .webix_segment_N {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
}
.webix_toolbar.webix_layout_subbar .webix_el_arrow.webixtype_next_arrow {
  border-left: none;
  border-bottom: none;
}
.webix_toolbar.webix_layout_subbar .webix_el_arrow.webixtype_prev_arrow {
  border-right: none;
  border-bottom: none;
}
.webix_toolbar.webix_layout_subbar .webixtype_base:hover,
.webix_toolbar.webix_layout_subbar .webix_inp_counter_next:hover,
.webix_toolbar.webix_layout_subbar .webix_inp_counter_prev:hover,
.webix_toolbar.webix_layout_subbar .webix_segment_0:hover,
.webix_toolbar.webix_layout_subbar .webix_segment_1:hover,
.webix_toolbar.webix_layout_subbar .webix_segment_N:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_toolbar.webix_layout_subbar .webixtype_base:active,
.webix_toolbar.webix_layout_subbar .webix_inp_counter_next:active,
.webix_toolbar.webix_layout_subbar .webix_inp_counter_prev:active,
.webix_toolbar.webix_layout_subbar .webix_segment_0:active,
.webix_toolbar.webix_layout_subbar .webix_segment_1:active,
.webix_toolbar.webix_layout_subbar .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_toolbar.webix_layout_subbar .webixtype_base:focus,
.webix_toolbar.webix_layout_subbar .webix_inp_counter_next:focus,
.webix_toolbar.webix_layout_subbar .webix_inp_counter_prev:focus,
.webix_toolbar.webix_layout_subbar .webix_segment_0:focus,
.webix_toolbar.webix_layout_subbar .webix_segment_1:focus,
.webix_toolbar.webix_layout_subbar .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
}
.webix_toolbar.webix_layout_subbar .webix_inp_static,
.webix_toolbar.webix_layout_subbar input[type=text] {
  border-color: #ffffff;
}
.webix_toolbar.webix_layout_subbar .webix_inp_static:focus,
.webix_toolbar.webix_layout_subbar input[type=text]:focus {
  border-color: #ffffff;
  box-shadow: none;
}
.webix_toolbar.webix_layout_subbar .webix_input_icon {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
  color: #666666;
}
.webix_toolbar.webix_layout_subbar .webix_input_icon:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_toolbar.webix_layout_subbar .webix_input_icon:active,
.webix_toolbar.webix_layout_subbar .webix_input_icon:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_toolbar.webix_layout_subbar .webix_pressed .webixtype_base,
.webix_toolbar.webix_layout_subbar .webix_pressed .webixtype_prev,
.webix_toolbar.webix_layout_subbar .webix_pressed .webixtype_next,
.webix_toolbar.webix_layout_subbar .webix_pressed .webix_el_arrow,
.webix_toolbar.webix_layout_subbar .webix_pressed .webix_inp_counter_next,
.webix_toolbar.webix_layout_subbar .webix_pressed .webix_inp_counter_prev,
.webix_toolbar.webix_layout_subbar .webix_pressed .webix_input_icon,
.webix_toolbar.webix_layout_subbar .webix_pressed .webix_segment_0,
.webix_toolbar.webix_layout_subbar .webix_pressed .webix_segment_1,
.webix_toolbar.webix_layout_subbar .webix_pressed .webix_segment_N {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_0,
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_1,
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_N {
  border-color: #d9d9d9;
  background-color: #27ae60;
  color: #ffffff;
}
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_0:hover,
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_1:hover,
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_N:hover {
  background-color: #24a259;
}
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_0:active,
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_1:active,
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_N:active {
  background-color: #229955;
}
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_0:focus,
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_1:focus,
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_N:focus {
  background-color: #229955;
}
.webix_toolbar.webix_layout_subbar .webix_segment_1,
.webix_toolbar.webix_layout_subbar .webix_segment_N,
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_1,
.webix_toolbar.webix_layout_subbar .webix_selected.webix_segment_N {
  margin-left: 0;
  border-left: none;
}
.webix_toolbar.webix_layout_subbar .webix_img_btn:active,
.webix_toolbar.webix_layout_subbar .webix_img_btn_top:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_toolbar.webix_layout_subbar .webix_img_btn:focus,
.webix_toolbar.webix_layout_subbar .webix_img_btn_top:focus {
  background-color: #2489cc;
}
.webix_toolbar.webix_layout_subbar .webix_img_btn:hover,
.webix_toolbar.webix_layout_subbar .webix_img_btn_top:hover {
  background-color: #268fd5;
}
.webix_toolbar.webix_layout_subbar .webix_icon_button {
  border-radius: 6px;
}
.webix_toolbar.webix_layout_subbar .webix_img_btn_top .webix_img_btn_text,
.webix_toolbar.webix_layout_subbar .webix_img_btn_top .webix_icon,
.webix_toolbar.webix_layout_subbar .webix_el_icon,
.webix_toolbar.webix_layout_subbar .webix_img_btn,
.webix_toolbar.webix_layout_subbar .webix_icon_btn,
.webix_toolbar.webix_layout_subbar .webix_icon_button {
  color: #ffffff;
}
.webix_toolbar.webix_layout_subbar .webix_img_btn_abs .webix_icon,
.webix_toolbar.webix_layout_subbar .webix_img_btn_abs .webix_img_btn_text {
  color: #ffffff;
}
.webix_toolbar.webix_layout_subbar .webix_img_btn_abs {
  color: #ffffff;
}
.webix_toolbar.webix_layout_subbar .webix_el_box .webix_img_btn_abs:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_toolbar.webix_layout_subbar .webix_el_icon .webix_icon_button:focus {
  background-color: rgba(0, 0, 0, 0.07);
}
.webix_toolbar.webix_layout_subbar .webix_el_box .webix_img_btn_abs:hover,
.webix_toolbar.webix_layout_subbar .webix_el_icon .webix_icon_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_toolbar.webix_layout_subbar .webix_control {
  border-color: #3498db;
}
.webix_toolbar.webix_layout_subbar .webix_icon_button .webix_icon {
  font-size: 23px;
  height: 27px;
  width: 27px;
  color: #ffffff;
}
.webix_toolbar.webix_layout_subbar .webix_img_btn,
.webix_toolbar.webix_layout_subbar .webix_img_btn_top {
  border-width: 0;
}
.webix_toolbar .webix_layout_toolbar,
.webix_toolbar .webix_layout_line,
.webix_toolbar .webix_layout_clean {
  background: transparent;
}
.webix_hidden_upload,
.webix_el_button .webix_hidden_upload {
  height: 1px;
  width: 1px;
  border: 0 !important;
  font-size: 1px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}
.webix_upload_client,
.webix_upload_error,
.webix_upload_server {
  width: 60px;
  text-align: center;
  float: right;
}
.error_icon,
.cancel_icon {
  display: inline-block;
  background-repeat: no-repeat;
}
.error_icon {
  width: 18px;
  height: 16px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAQCAYAAAAbBi9cAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjZFMDdENjlDNjE2QzExRTI5QzhBQUU2OUI3RDY4RTFCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjZFMDdENjlENjE2QzExRTI5QzhBQUU2OUI3RDY4RTFCIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NkUwN0Q2OUE2MTZDMTFFMjlDOEFBRTY5QjdENjhFMUIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NkUwN0Q2OUI2MTZDMTFFMjlDOEFBRTY5QjdENjhFMUIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4ZJnE7AAABdklEQVR42mK80aLPgAcwA/EcIOYB4mgg/oWuQL36AphmYcAPYvl5WBOYmBgY3n/6fQTIn4hLIRMeQ0CuaBHgY2EQ4GMF8euAWIgcg6qF+NmkOdiZGdhYmRiEBdhAhrSQapAKEBcJC7DCBUQE2UBUGhAbkGLQBDEhdjYmJkaGtHVMDD4LmBk+/2RgEBdhBwX+ZGINcuLiZPYW5Ie45tknRrAhIFqAl5WBh4vFBijsR8ggkI29IA0wwMsOo/+DaWjA9wIxGz6D0oAKDXi5EalCkvc/2DApPgifm5OZQZCPFRyGuAwCxwqya0AAZADMVTAgyA92TDUQS2MzqAUUxcAgRjPoP9hVyICVhREUi6B01g4TY4RmES0gvqSuyMPMQAK4ef/LXyBlAswmF2DWd4kJs2M1pGgLIvrRAXJyAIWqNzcXi7cgHysDqQAUnt9//LW52WoQBfLaDRlxDnWgYQzkAKBBDI+ef38E0n3nycsf6gyUgUcAAQYA9DZJKVXGocQAAAAASUVORK5CYII=");
}
.webix_upload_server {
  color: green;
}
.webix_remove_upload {
  float: right;
}
.cancel_icon {
  margin-top: 8px;
  width: 14px;
  height: 14px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAQAAAC1QeVaAAAAsElEQVR4XoVQUQbDQBScAywIuUcpKCWglBJCDlBKf0vpbymhd5mDhIDcoAcoJQSEsn2G5KmWzMDsPG92ZyGAgVfWfBtrUwEOZnwwOu2UjaPCbScLAEzYmzxyxafMgaUlRXMTsJJ1B7jgy0Y7gHt5FdgyimeAS+a6ZpDTQkLkCQbmk9OBHaPv+p6x99jLFFuOsTMPSlXl8FVlrSopFBP/sIQAbn6+bwsHA29sNGhMBQgf1cri4JYEbDkAAAAASUVORK5CYII=");
}
.webix_upload_flash {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
.webix_drop_file {
  background: #f4f4f4;
  position: relative;
}
.webix_drop_file::before {
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 100%;
  text-align: center;
  pointer-events: none;
}
.webix_fieldset fieldset {
  margin: 0px;
  padding: 5px 8px;
  background-color:#f6f6f6;
}
.webix_fieldset legend {
  color: #666666;
  font-size: 12px;
}
.webix_forminput fieldset {
  border: none;
  margin: 0px;
  padding: 0px;
}
.webix_forminput legend {
  color: #666666;
  line-height: 32px;
  padding: 3px 2px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: StolzlRegular,sans-serif;
  float: left;
  padding-right: 7.5px;
}
.webix_slider_box {
  position: relative;
  padding: 0px 10px;
  height: 100%;
  padding-top: 13px;
  float: left;
  border-radius: 5px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 15px;
}
.webix_slider_box .webix_slider_left {
  height: 10px;
  float: left;
  width: 100px;
  background: #f7fbfe;
  border: 1px solid #eaeaea;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_slider_box .webix_slider_right {
  height: 10px;
  float: left;
  width: 100px;
  background: #fff;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #eaeaea;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_slider_box .webix_slider_handle {
  z-index: 1;
  position: absolute;
  width: 15px;
  height: 15px;
  top: 9px;
  border-radius: 8px;
}
.webix_slider_box .webix_slider_left {
  height: 6px;
  border: 1px solid #cccccc;
  background: #3498db;
}
.webix_slider_box .webix_slider_right {
  height: 6px;
  border-color: #cccccc;
}
.webix_slider_box .webix_slider_handle {
  width: 16px;
  height: 16px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
}
.webix_rangeslider .webix_slider_box .webix_slider_left {
  position: absolute;
}
.webix_rangeslider .webix_slider_box .webix_slider_active {
  z-index: 1;
}
.webix_rangeslider .webix_slider_box .webix_slider_right {
  border-radius: 5px;
}
.webix_slider_alt .webix_slider_box .webix_slider_left {
  border: 1px solid #e1e1e1;
}
.webix_slider_alt .webix_slider_box .webix_slider_right {
  border: 1px solid #e1e1e1;
}
.webix_slider_alt .webix_slider_box .webix_slider_handle {
  width: 11px;
  margin: 0 2px;
  height: 20px;
  top: 6.5px;
  border-radius: 2px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAICAYAAAAx8TU7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTJCMjdFNENBRTg4MTFFMjk2NjJGMTJENjkyNDA2NTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTJCMjdFNERBRTg4MTFFMjk2NjJGMTJENjkyNDA2NTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MkIyN0U0QUFFODgxMUUyOTY2MkYxMkQ2OTI0MDY1OSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MkIyN0U0QkFFODgxMUUyOTY2MkYxMkQ2OTI0MDY1OSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Powz/icAAAAaSURBVHjaYmBgYDBjgAA4zcSABQysIECAAQBn+ACx2PqtbAAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
  background-position: 3px center;
}
.webix_slider_alt .webix_slider_box .webix_slider_left {
  border: 1px solid #cccccc;
  background: #3498db;
}
.webix_slider_alt .webix_slider_box .webix_slider_right {
  border-color: #cccccc;
}
.webix_slider_alt .webix_slider_box .webix_slider_handle {
  background-color: #ffffff;
  border: 1px solid #cccccc;
}
.webix_slider_title {
  margin-bottom: -11px;
  text-align: center;
}
.webix_progress_top,
.webix_progress_bottom {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 6px;
  overflow: hidden;
  z-index: 20;
  background: #f2f2f2;
}
.webix_progress_bottom {
  bottom: 0px;
  top: auto;
  height: 17px;
}
.webix_progress_top .webix_progress_state,
.webix_progress_bottom .webix_progress_state {
  width: 0px;
  height: 6px;
  background: #27ae60;
  -moz-transition: width 3s;
  transition: width 3s;
}
.webix_progress_bottom .webix_progress_state {
  height: 17px;
}
.webix_progress_icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 20;
}
.webix_progress_icon .webix_progress_state {
  font-family: 'Font Awesome 5 Pro';
  font-size: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  display: inline-block;
  color: #b3b3b3;
}
.webix_gage {
  height: 100%;
  width: 100%;
  transform: rotate(180deg);
}
.webix_gage_label {
  font-size: 0.9em;
  text-align: center;
  margin-top: 10px;
}
.webix_gage_placeholder {
  margin-top: -20px;
}
.webix_gage_info {
  text-align: center;
  font-size: 0.7em;
  position: relative;
  margin: 0 auto 10px auto;
}
.webix_gage_min_range {
  left: -74px;
}
.webix_gage_max_range {
  right: -74px;
}
.webix_gage_min_range,
.webix_gage_max_range {
  display: inline-block;
  margin-top: 20px;
  position: absolute;
  text-align: center;
  width: 150px;
}
.webix_gage_range_info {
  font-size: 1.2em;
  line-height: 1;
}
.webix_gage-value {
  font-size: 2.2em;
  color: #404040;
}
.webix_gage_gradient_point_animated {
  transition: transform 1.3s linear;
}
.webix_gage_animated {
  stroke: #00ff00;
  animation: gage_dash 1.3s linear forwards;
  transition: stroke 1.3s linear, stroke-dasharray 1.3s linear;
}
.webix_gage_animated_first_load {
  stroke: #00ff00;
  animation: gage_dash 1.3s linear forwards;
  transition: stroke 1.3s linear;
}
@keyframes gage_dash {
  to {
    stroke-dashoffset: 0;
  }
}
.webix_bullet_name {
  width: 100%;
}
.webix_bullet_header {
  font-weight: bold;
  font-size: 15px;
}
.webix_bullet_subheader {
  font-size: 15px;
}
.webix_bullet_scale {
  font-size: 12px;
  font-weight: bold;
}
.webix_organogram canvas {
  position: absolute;
}
.webix_organogram_item {
  position: absolute;
  z-index: 1;
  text-align: center;
  border-radius: 4px;
  background-color: #e3f2fd;
  border: 1px solid #bbdefb;
  padding: 7px 7px 10px 7px;
  box-sizing: border-box;
}
.webix_organogram_item.webix_selected {
  background-color: #27ae60;
  border-color: #27ae60;
}
.webix_organogram_list {
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  height: auto;
  border: 1px solid #dddddd;
  box-sizing: border-box;
}
.webix_organogram_list .webix_organogram_list_item {
  border: none;
  text-align: left;
  position: static;
  padding: 5px;
  border-radius: 0px;
  box-sizing: border-box;
}
.webix_icon,
.webix_icon_btn,
.webix_input_icon,
.fa {
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  padding-right: 5px;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-fw {
  width: 1.28571429em;
  text-align: center;
}
.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.85714286em;
}
.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left {
  margin-right: .3em;
}
.fa.fa-pull-right {
  margin-left: .3em;
}
/* Deprecated as of 4.4.0 */
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.fa.pull-left {
  margin-right: .3em;
}
.fa.pull-right {
  margin-left: .3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #ffffff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
	readers do not read off random characters that represent icons */
.fa-glass:before {
  content: "\f000";
}
.fa-music:before {
  content: "\f001";
}
.fa-search:before {
  content: "\f002";
}
.fa-envelope-o:before {
  content: "\f2b6";
}
.fa-heart:before {
  content: "\f004";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-o:before {
  content: "\f006";
}
.fa-user:before {
  content: "\f007";
}
.fa-film:before {
  content: "\f008";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-check:before {
  content: "\f00c";
}
.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\f00d";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-signal:before {
  content: "\f012";
}
.fa-gear:before,
.fa-cog:before {
  content: "\f013";
}
.fa-trash-o:before {
  content: "\f014";
}
.fa-home:before {
  content: "\f015";
}
.fa-file-o:before {
  content: "\f016";
}
.fa-clock-o:before {
  content: "\f017";
}
.fa-road:before {
  content: "\f018";
}
.fa-download:before {
  content: "\f019";
}
.fa-arrow-circle-o-down:before {
  content: "\f01a";
}
.fa-arrow-circle-o-up:before {
  content: "\f01b";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-play-circle-o:before {
  content: "\f01d";
}
.fa-bolt-circle:before{
  content: "\f0e7";
}
.cloud_download_btn:before{
  content: "\f381";
}
.fa-cloud-download-alt:before {
   content: "\f0ed";
}
.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e";
}
.fa-refresh:before {
  content: "\f021";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-lock:before {
  content: "\f023";
}
.fa-flag:before {
  content: "\f024";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-print:before {
  content: "\f02f";
}
.fa-camera:before {
  content: "\f030";
}
.fa-font:before {
  content: "\f031";
}
.fa-bold:before {
  content: "\f032";
}
.fa-italic:before {
  content: "\f033";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-list:before {
  content: "\f03a";
}
.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-video-camera:before {
  content: "\f03d";
}
.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "\f03e";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-tint:before {
  content: "\f043";
}
.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044";
}
.fa-share-square-o:before {
  content: "\f045";
}
.fa-check-square-o:before {
  content: "\f046";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-play:before {
  content: "\f04b";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-eject:before {
  content: "\f052";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-times-circle-o:before {
  content: "\f05c";
}
.fa-check-circle-o:before {
  content: "\f05d";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-mail-forward:before,
.fa-share:before {
  content: "\f064";
}
.fa-expand:before {
  content: "\f065";
}
.fa-compress:before {
  content: "\f066";
}
.fa-plus:before {
  content: "\f067";
}
.fa-minus:before {
  content: "\f068";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-plane:before {
  content: "\f072";
}
.fa-calendar:before {
  content: "\f073";
}
.fa-random:before {
  content: "\f074";
}
.fa-comment:before {
  content: "\f075";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-key:before {
  content: "\f084";
}
.fa-gears:before,
.fa-cogs:before {
  content: "\f085";
}
.fa-comments:before {
  content: "\f086";
}
.fa-thumbs-o-up:before {
  content: "\f087";
}
.fa-thumbs-o-down:before {
  content: "\f088";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-heart-o:before {
  content: "\f08a";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-linkedin-square:before {
  content: "\f08c";
}
.fa-thumb-tack:before {
  content: "\f08d";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-upload:before {
  content: "\f093";
}
.fa-lemon-o:before {
  content: "\f094";
}
.fa-phone:before {
  content: "\f095";
}
.fa-square-o:before {
  content: "\f0c8";
}
.fa-bookmark-o:before {
  content: "\f097";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}
.fa-github:before {
  content: "\f09b";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-feed:before,
.fa-rss:before {
  content: "\f09e";
}
.fa-hdd-o:before {
  content: "\f0a0";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-hand-o-right:before {
  content: "\f0a4";
}
.fa-hand-o-left:before {
  content: "\f0a5";
}
.fa-hand-o-up:before {
  content: "\f0a6";
}
.fa-hand-o-down:before {
  content: "\f0a7";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-group:before,
.fa-users:before {
  content: "\f0c0";
}
.fa-chain:before,
.fa-link:before {
  content: "\f0c1";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4";
}
.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "\f0c9";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-plus:before {
  content: "\f0d5";
}
.fa-money:before {
  content: "\f0d6";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-down:before,
.fa-sort-desc:before {
  content: "\f0dd";
}
.fa-sort-up:before,
.fa-sort-asc:before {
  content: "\f0de";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2";
}
.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3";
}
.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-comment-o:before {
  content: "\f0e5";
}
.fa-comments-o:before {
  content: "\f0e6";
}
.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea";
}
.fa-lightbulb-o:before {
  content: "\f0eb";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-bell-o:before {
  content: "\f0a2";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cutlery:before {
  content: "\f0f5";
}
.fa-file-text-o:before {
  content: "\f0f6";
}
.fa-building-o:before {
  content: "\f0f7";
}
.fa-hospital-o:before {
  content: "\f0f8";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b";
}
.fa-circle-o:before {
  content: "\f10c";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-circle:before {
  content: "\f111";
}
.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-folder-o:before {
  content: "\f114";
}
.fa-folder-open-o:before {
  content: "\f115";
}
.fa-smile-o:before {
  content: "\f118";
}
.fa-frown-o:before {
  content: "\f119";
}
.fa-meh-o:before {
  content: "\f11a";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-keyboard-o:before {
  content: "\f11c";
}
.fa-flag-o:before {
  content: "\f11d";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-code:before {
  content: "\f121";
}
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122";
}
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-crop:before {
  content: "\f125";
}
.fa-code-fork:before {
  content: "\f126";
}
.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127";
}
.fa-question:before {
  content: "\f128";
}
.fa-info:before {
  content: "\f129";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-shield:before {
  content: "\f132";
}
.fa-calendar-o:before {
  content: "\f133";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-minus-square-o:before {
  content: "\f147";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-pencil-square:before {
  content: "\f14b";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150";
}
.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151";
}
.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152";
}
.fa-euro:before,
.fa-eur:before {
  content: "\f153";
}
.fa-gbp:before {
  content: "\f154";
}
.fa-dollar:before,
.fa-usd:before {
  content: "\f155";
}
.fa-rupee:before,
.fa-inr:before {
  content: "\f156";
}
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157";
}
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158";
}
.fa-won:before,
.fa-krw:before {
  content: "\f159";
}
.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-text:before {
  content: "\f15c";
}
.fa-sort-alpha-asc:before {
  content: "\f15d";
}
.fa-sort-alpha-desc:before {
  content: "\f15e";
}
.fa-sort-amount-asc:before {
  content: "\f160";
}
.fa-sort-amount-desc:before {
  content: "\f161";
}
.fa-sort-numeric-asc:before {
  content: "\f162";
}
.fa-sort-numeric-desc:before {
  content: "\f163";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-youtube-square:before {
  content: "\f166";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-youtube-play:before {
  content: "\f16a";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-stack-overflow:before
{
  font-family: 'Font Awesome 5 Brands';
  content: "\f16c";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-adn:before {
  content: "\f170";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitbucket-square:before {
  content: "\f172";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-apple:before {
  content: "\f179";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-android:before {
  content: "\f17b";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-trello:before
{
  font-family: 'Font Awesome 5 Brands';
  content: "\f181";
}
.fa-female:before {
  content: "\f182";
}
.fa-male:before {
  content: "\f183";
}
.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184";
}
.fa-sun-o:before {
  content: "\f185";
}
.fa-moon-o:before {
  content: "\f186";
}
.fa-archive:before {
  content: "\f187";
}
.fa-bug:before {
  content: "\f188";
}
.fa-vk:before {
  content: "\f189";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-arrow-circle-o-right:before {
  content: "\f18e";
}
.fa-arrow-circle-o-left:before {
  content: "\f190";
}
.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191";
}
.fa-dot-circle-o:before {
  content: "\f192";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195";
}
.fa-plus-square-o:before {
  content: "\f196";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-slack:before {
  content: "\f198";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "\f19c";
}
.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-spoon:before {
  content: "\f1b1";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-automobile:before,
.fa-car:before {
  content: "\f1b9";
}
.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-file-pdf-o:before {
  content: "\f1c1";
}
.fa-file-word-o:before {
  content: "\f1c2";
}
.fa-file-excel-o:before {
  content: "\f1c3";
}
.fa-file-powerpoint-o:before {
  content: "\f1c4";
}
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "\f1c5";
}
.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "\f1c6";
}
.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "\f1c7";
}
.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8";
}
.fa-file-code-o:before {
  content: "\f1c9";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-circle-o-notch:before {
  content: "\f1ce";
}
.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: "\f1d0";
}
.fa-ge:before,
.fa-empire:before {
  content: "\f1d1";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7";
}
.fa-send:before,
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "\f1d9";
}
.fa-history:before {
  content: "\f1da";
}
.fa-circle-thin:before {
  content: "\f1db";
}
.fa-header:before {
  content: "\f1dc";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-sliders:before {
  content: "\f1de";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "\f1e3";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-newspaper-o:before {
  content: "\f1ea";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bell-slash-o:before {
  content: "\f1f7";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-eyedropper:before {
  content: "\f1fb";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-area-chart:before {
  content: "\f1fe";
}
.fa-pie-chart:before {
  content: "\f200";
}
.fa-line-chart:before {
  content: "\f201";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bus:before {
  content: "\f207";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-cc:before {
  content: "\f20a";
}
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "\f20b";
}
.fa-meanpath:before {
  content: "\f20c";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-venus:before {
  content: "\f221";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-intersex:before,
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-facebook-official:before {
  content: "\f230";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-server:before {
  content: "\f233";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-hotel:before,
.fa-bed:before {
  content: "\f236";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-train:before {
  content: "\f238";
}
.fa-subway:before {
  content: "\f239";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-yc:before,
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-battery-2:before,
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\f244";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-sticky-note-o:before {
  content: "\f24a";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-hourglass-o:before {
  content: "\f250";
}
.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\f255";
}
.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "\f256";
}
.fa-hand-scissors-o:before {
  content: "\f257";
}
.fa-hand-lizard-o:before {
  content: "\f258";
}
.fa-hand-spock-o:before {
  content: "\f259";
}
.fa-hand-pointer-o:before {
  content: "\f25a";
}
.fa-hand-peace-o:before {
  content: "\f25b";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-safari:before {
  content: "\f267";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-tv:before,
.fa-television:before {
  content: "\f26c";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-500px:before {
  content: "\f26e";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-calendar-plus-o:before {
  content: "\f271";
}
.fa-calendar-minus-o:before {
  content: "\f272";
}
.fa-calendar-times-o:before {
  content: "\f273";
}
.fa-calendar-check-o:before {
  content: "\f274";
}
.fa-industry:before {
  content: "\f275";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-map-o:before {
  content: "\f278";
}
.fa-map:before {
  content: "\f279";
}
.fa-commenting:before {
  content: "\f27a";
}
.fa-commenting-o:before {
  content: "\f27b";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-vimeo:before {
  content: "\f27d";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-edge:before {
  content: "\f282";
}
.fa-credit-card-alt:before {
  content: "\f283";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-modx:before {
  content: "\f285";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-usb:before {
  content: "\f287";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-pause-circle-o:before {
  content: "\f28c";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stop-circle-o:before {
  content: "\f28e";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-percent:before {
  content: "\f295";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-envira:before {
  content: "\f299";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-wheelchair-alt:before {
  content: "\f29b";
}
.fa-question-circle-o:before {
  content: "\f29c";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-volume-control-phone:before {
  content: "\f2a0";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: "\f2a4";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-signing:before,
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "\f2b3";
}
.fa-fa:before,
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-handshake-o:before {
  content: "\f2b5";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-open-o:before {
  content: "\f2b7";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-book-o:before {
  content: "\f2ba";
}
.fa-vcard:before,
.fa-address-card:before {
  content: "\f2bb";
}
.fa-vcard-o:before,
.fa-address-card-o:before {
  content: "\f2bc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-circle-o:before {
  content: "\f2be";
}
.fa-user-o:before {
  content: "\f2c0";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-drivers-license:before,
.fa-id-card:before {
  content: "\f2c2";
}
.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: "\f2c3";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: "\f2cd";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-times-rectangle:before,
.fa-window-close:before {
  content: "\f2d3";
}
.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: "\f2d4";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-eercast:before {
  content: "\f2da";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-snowflake-o:before {
  content: "\f2dc";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-meetup:before {
  content: "\f2e0";
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
.webix_icon,
.webix_input_icon {
  font-size: 14px;
  display: inline-block;
  width: 20px;
}
.webix_input_icon {
  float: right;
  font-size: 16px;
  color: #888;
}
.webix_icon_button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
}
.webix_icon_button .webix_icon {
  font-size: 20px;
  height: 23px;
  width: 21px;
  display: inline-block;
  text-align: center;
  color: #e3dcd6;
}
.webix_icon_btn {
  font-size: 14px;
  display: inline-block;
  text-align: center;
  width: 24px;
  color: #e3dcd6;
}
.webix_el_iconBbutton .webix_icon_btn {
  text-align: center;
  width: 100%;
  padding: 5px 0px;
}
.webix_el_icon {
  cursor: pointer;
}
.webix_badge {
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 50%;
  font-size: 12px;
  height: 16px;
  min-width: 16px;
  box-sizing: border-box;
  padding: 0 4px;
  line-height: 18px;
  position: absolute;
  right: 6px;
  top: 6px;
}
.webix_list_item .webix_badge {
  position: static;
  float: right;
  margin: 4px -5px 0 10px;
}
.webixtype_base .webix_badge {
  top: 50%;
  margin-top: -11px;
  margin-right: 8px;
}
.webix_treemap {
  background-color: #f5f5f5;
}
.webix_treemap .webix_scroll_cont {
  position: relative;
  height: 100%;
  width: 100%;
}
.webix_treemap_item {
  position: absolute;
  color: #444;
  overflow: hidden;
  text-align: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 3px 5px;
  border-width: 0px 1px 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  background: transparent;
}
.webix_treemap_level_top {
  z-index: 1;
  border-color: rgba(0, 0, 0, 0.4);
}
.webix_treemap_item_bottom {
  border-bottom-width: 0;
}
.webix_treemap_item_right {
  border-right-width: 0;
}
.webix_treemap_item:hover {
  box-shadow: inset 0 0 5px #666;
}
.webix_treemap_item.webix_selected {
  border-color: #27ae60;
  box-shadow: inset 0 0 1px 2px #27ae60;
}
.webix_treemap_header {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_treemap_header {
  padding: 0 10px;
  border-bottom: 1px solid #dddddd;
}
.webix_treemap_header_item {
  cursor: pointer;
}
.webix_treemap_header_item:last-child {
  cursor: default;
}
.webix_treemap_reset {
  float: right;
  width: 25px;
  text-align: center;
}
.webix_treemap_reset:before {
  content: "\f00d";
  color: #666666;
  font-family: FontAwesome;
  font-size: 16px;
  display: block;
  cursor: pointer;
}
.webix_treemap_path_icon {
  width: 20px;
  text-align: center;
}
.webix_barcode {
  position: relative;
}
.webix_barcode .webix_canvas_text {
  font-size: 15px;
  padding: 0 2px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.portlet_drag {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
  width: 18px;
  opacity: 0.5;
  cursor: pointer;
}
.portlet_in_drag {
  opacity: 0.4;
}
.portlet_marker,
.portlet_markertop,
.portlet_markerbottom,
.portlet_markerleft,
.portlet_markerright {
  opacity: 0.5;
  background: #9169BE;
  position: absolute;
  transition: top 0.5s, left 0.5s, width 0.5s, height 0.5s;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.portlet_markertop {
  height: 50%;
}
.portlet_markerbottom {
  height: 50%;
  top: 50%;
}
.portlet_markerleft {
  width: 50%;
}
.portlet_markerright {
  width: 50%;
  left: 50%;
}
.webix_popup.webix_sidemenu.webix_sidemenu_left {
  border-width: 0 1px 0 0;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05);
}
.webix_sidemenu_left.webix_animate {
  transition: left 0.6s;
}
.webix_popup.webix_sidemenu.webix_sidemenu_right {
  left: auto;
  border-width: 0 0 0 1px;
  box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.05);
}
.webix_sidemenu_right.webix_animate {
  transition: right 0.6s;
}
.webix_popup.webix_sidemenu.webix_sidemenu_top {
  border-width: 0 0 1px 0 ;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
}
.webix_sidemenu_top .webix_win_body {
  position: relative;
}
.webix_sidemenu_top .webix_win_body > .webix_view {
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
}
.webix_sidemenu_top.webix_animate,
.webix_sidemenu_top.webix_animate .webix_win_body {
  transition: height 0.6s;
}
.webix_popup.webix_sidemenu.webix_sidemenu_bottom {
  border-width: 1px 0 0 0 ;
  top: auto;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.05);
}
.webix_sidemenu_bottom.webix_animate,
.webix_sidemenu_bottom.webix_animate .webix_win_body {
  transition: height 0.6s;
}
.webix_view.webix_pdf {
  background-color: #404040;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.webix_pdf .canvas_wrapper {
  margin: 0px auto;
  box-shadow: 5px 5px 15px #1c1c1c;
  width: 100%;
  height: 100%;
}
.webix_toolbar.pdf_bar {
  background-color: #474747;
}
.webix_toolbar.pdf_bar .webix_icon_btn {
  font-size: 15px;
}
.webix_toolbar.pdf_bar .webix_img_btn:focus,
.webix_toolbar.pdf_bar .webix_img_btn:hover {
  background-color: #404040;
}
.webix_toolbar.pdf_bar .webix_template {
  background-color: #3498db;
  color: #fff;
  line-height: 2em;
}
.webix_toolbar.pdf_bar .webix_el_box input,
.webix_toolbar.pdf_bar .webix_inp_static {
  background-color: #5c5c5c;
  color: #fff;
  border-color: #404040;
}
.webix_toolbar.pdf_bar .webix_el_box input:focus,
.webix_toolbar.pdf_bar .webix_inp_static:focus {
  border-color: #333;
}
.webix_toolbar.pdf_bar .webix_el_box .webix_input_icon {
  color: #fff;
}
.webix_view.webix_popup.pdf_opt_list {
  box-shadow: 5px 5px 15px #1c1c1c;
  border: none;
}
.pdf_opt_list .webix_list {
  background-color: #404040;
  color: #fff;
}
.pdf_opt_list .webix_list .webix_list_item {
  border-color: #474747;
  line-height: 1.5em;
}
.pdf_opt_list .webix_list .webix_list_item.webix_selected,
.pdf_opt_list .webix_list .webix_list_item:hover {
  color: #404040;
  background-color: #fff;
}
.webix_dbllist .webix_list {
  background: #ededed;
}
.webix_dbllist .webix_list_item {
  background: white;
  margin: 3px 4px 0px 4px;
  box-sizing: border-box;
}
.webix_dbllist .webix_list_item.webix_selected {
  color: #FFFFFF;
  background: #27ae60;
}
.webix_dbllist button {
  width: 45%;
  height: 30px;
  margin-top: 5px;
  background: #eef7fc;
  border-radius: 6px;
  border: 1px solid #dddddd;
  cursor: pointer;
  font-size: 15px;
  font-family: StolzlRegular,sans-serif;
}
.webix_dbllist button:active {
  box-shadow: inset 1px 1px 0px silver;
}
.webix_dbllist button .webix_icon {
  color: #888;
  font-size: 26px;
}
.webix_dbllist button:first-child {
  margin-top: 32px;
  margin-right: 5%;
}
.webix_dbllist button:last-child {
  margin-left: 5%;
}
.webix_dbllist .bottom_label {
  font-size: 10px;
  text-transform: uppercase;
  background: #ededed;
  padding-left: 13px;
}
.webix_invalid .webix_list {
  background: #f8e2e2;
}
.mainFont {
  font-family: StolzlRegular,sans-serif;
  font-size: 15px;
  color: #666666;
}
/*[L]*/
/*[L]*/
.webix_layout_toolbar.webix_toolbar {
  color: #ffffff;
  font-size: 18px;
  background-color: #f6f6f6;
  /* border-left: none; */
  /* border-right: none; */
  border-color: #e1e1e1;
  /*.webix_el_button input, .webix_el_toggle input {
		border: none;

	}*/
  /*.webixtype_prev:active, .webixtype_next:active, .webixtype_base:active, .webix_inp_counter_next:active, .webix_inp_counter_prev:active{
		background-color: @barButtonActiveColor;
	}*/
}
.webix_layout_toolbar.webix_toolbar .webix_el_button,
.webix_layout_toolbar.webix_toolbar .webix_el_label,
.webix_layout_toolbar.webix_toolbar .webix_inp_label {
  color: #656565;
}
.webix_layout_toolbar.webix_toolbar .webix_el_label {
  font-size: 12px;
}
.webix_layout_toolbar.webix_toolbar .webixtype_base,
.webix_layout_toolbar.webix_toolbar .webixtype_prev,
.webix_layout_toolbar.webix_toolbar .webixtype_next,
.webix_layout_toolbar.webix_toolbar .webix_inp_counter_next,
.webix_layout_toolbar.webix_toolbar .webix_inp_counter_prev,
.webix_layout_toolbar.webix_toolbar .webix_segment_0,
.webix_layout_toolbar.webix_toolbar .webix_segment_1,
.webix_layout_toolbar.webix_toolbar .webix_segment_N {
  border-color: #dadada;
  background-color: #f1f1f1;
  color: #787574;
}
.webix_layout_toolbar.webix_toolbar .webix_el_arrow.webixtype_next_arrow {
  border-left: none;
  border-bottom: none;
}
.webix_layout_toolbar.webix_toolbar .webix_el_arrow.webixtype_prev_arrow {
  border-right: none;
  border-bottom: none;
}
.webix_layout_toolbar.webix_toolbar .webixtype_base:hover,
.webix_layout_toolbar.webix_toolbar .webix_inp_counter_next:hover,
.webix_layout_toolbar.webix_toolbar .webix_inp_counter_prev:hover,
.webix_layout_toolbar.webix_toolbar .webix_segment_0:hover,
.webix_layout_toolbar.webix_toolbar .webix_segment_1:hover,
.webix_layout_toolbar.webix_toolbar .webix_segment_N:hover {
  color: #354866;
  background-color: #e0e0e0;
  border-color:#dadada;
}
.webix_layout_toolbar.webix_toolbar .webixtype_base:active,
.webix_layout_toolbar.webix_toolbar .webix_inp_counter_next:active,
.webix_layout_toolbar.webix_toolbar .webix_inp_counter_prev:active,
.webix_layout_toolbar.webix_toolbar .webix_segment_0:active,
.webix_layout_toolbar.webix_toolbar .webix_segment_1:active,
.webix_layout_toolbar.webix_toolbar .webix_segment_N:active {
  color: #354866;
  background-color: #e0e0e0;
  border-color:#c8c8c8;

}
.webix_layout_toolbar.webix_toolbar .webixtype_base:focus,
.webix_layout_toolbar.webix_toolbar .webix_inp_counter_next:focus,
.webix_layout_toolbar.webix_toolbar .webix_inp_counter_prev:focus,
.webix_layout_toolbar.webix_toolbar .webix_segment_0:focus,
.webix_layout_toolbar.webix_toolbar .webix_segment_1:focus,
.webix_layout_toolbar.webix_toolbar .webix_segment_N:focus {
  color: #354866;
  background-color: #e0e0e0;
}
.webix_layout_toolbar.webix_toolbar .webix_inp_static,
.webix_layout_toolbar.webix_toolbar input[type=text] {
  border-color: #ffffff;
}
.webix_layout_toolbar.webix_toolbar .webix_inp_static:focus,
.webix_layout_toolbar.webix_toolbar input[type=text]:focus {
  border-color: #ffffff;
  box-shadow: none;
}
.webix_layout_toolbar.webix_toolbar .webix_input_icon {
  border-color: #d9d9d9;
  background-color: transparent;
  color: #ffffff;
  color: #666666;
}
.webix_layout_toolbar.webix_toolbar .webix_input_icon:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_layout_toolbar.webix_toolbar .webix_input_icon:active,
.webix_layout_toolbar.webix_toolbar .webix_input_icon:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #666666;
}
.webix_layout_toolbar.webix_toolbar .webix_pressed .webixtype_base,
.webix_layout_toolbar.webix_toolbar .webix_pressed .webixtype_prev,
.webix_layout_toolbar.webix_toolbar .webix_pressed .webixtype_next,
.webix_layout_toolbar.webix_toolbar .webix_pressed .webix_el_arrow,
.webix_layout_toolbar.webix_toolbar .webix_pressed .webix_inp_counter_next,
.webix_layout_toolbar.webix_toolbar .webix_pressed .webix_inp_counter_prev,
.webix_layout_toolbar.webix_toolbar .webix_pressed .webix_input_icon,
.webix_layout_toolbar.webix_toolbar .webix_pressed .webix_segment_0,
.webix_layout_toolbar.webix_toolbar .webix_pressed .webix_segment_1,
.webix_layout_toolbar.webix_toolbar .webix_pressed .webix_segment_N {
  background-color: rgba(0, 0, 0, 0.07);
  color: #ffffff;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_0,
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_1,
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_N {
  border-color: #d9d9d9;
  background-color: #27ae60;
  color: #ffffff;
}
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_0:hover,
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_1:hover,
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_N:hover {
  background-color: #24a259;
}
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_0:active,
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_1:active,
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_N:active {
  background-color: #229955;
}
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_0:focus,
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_1:focus,
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_N:focus {
  background-color: #229955;
}
.webix_layout_toolbar.webix_toolbar .webix_segment_1,
.webix_layout_toolbar.webix_toolbar .webix_segment_N,
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_1,
.webix_layout_toolbar.webix_toolbar .webix_selected.webix_segment_N {
  margin-left: 0;
  border-left: none;
}
.webix_layout_toolbar.webix_toolbar .webix_img_btn:active,
.webix_layout_toolbar.webix_toolbar .webix_img_btn_top:active {
  box-shadow: none;
}
.webix_layout_toolbar.webix_toolbar .webix_img_btn:focus,
.webix_layout_toolbar.webix_toolbar .webix_img_btn_top:focus {
  background-color: #f6f6f6;
}
.webix_layout_toolbar.webix_toolbar .webix_img_btn:hover,
.webix_layout_toolbar.webix_toolbar .webix_img_btn_top:hover
{
  color: #354866;
  border: 1px solid #dadada;
  background-color: #e0e0e0;
}
.webix_layout_toolbar.webix_toolbar .webix_img_btn:hover:active,
.webix_layout_toolbar.webix_toolbar .webix_img_btn_top:hover:active
{
  color: #fff;
  border: 1px solid #41546d;
  background-color: #04597a;
}
.webix_layout_toolbar.webix_toolbar .webix_img_btn:hover > span,
.webix_layout_toolbar.webix_toolbar .webix_img_btn_top:hover > span {
  color: #2e2e38;
}
.webix_layout_toolbar.webix_toolbar .webix_img_btn:hover:active > span,
.webix_layout_toolbar.webix_toolbar .webix_img_btn_top:hover:active > span {
  color: #fff;
}
.webix_layout_toolbar.webix_toolbar .webix_icon_button {
  border-radius: 6px;
}
.webix_layout_toolbar.webix_toolbar .webix_img_btn_top .webix_img_btn_text,
.webix_layout_toolbar.webix_toolbar .webix_img_btn_top .webix_icon,
.webix_layout_toolbar.webix_toolbar .webix_el_icon,
.webix_layout_toolbar.webix_toolbar .webix_img_btn,
.webix_layout_toolbar.webix_toolbar .webix_icon_btn,
.webix_layout_toolbar.webix_toolbar .webix_icon_button {
  color: #656565;
}
.webix_layout_toolbar.webix_toolbar .webix_img_btn_abs .webix_icon,
.webix_layout_toolbar.webix_toolbar .webix_img_btn_abs .webix_img_btn_text {
  color: #ffffff;
}
.webix_layout_toolbar.webix_toolbar .webix_img_btn_abs {
  color: #ffffff;
}
.webix_layout_toolbar.webix_toolbar .webix_el_box .webix_img_btn_abs:active {
  background-color: #2489cc;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_layout_toolbar.webix_toolbar .webix_el_icon .webix_icon_button:focus {
  background-color: rgba(0, 0, 0, 0.07);
}
.webix_layout_toolbar.webix_toolbar .webix_el_box .webix_img_btn_abs:hover,
.webix_layout_toolbar.webix_toolbar .webix_el_icon .webix_icon_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_layout_toolbar.webix_toolbar .webix_control {
  border-color: #3498db;
}
.webix_layout_toolbar.webix_toolbar .webix_icon_button .webix_icon {
  font-size: 23px;
  height: 27px;
  width: 27px;
  color: #ffffff;
}
.webix_layout_toolbar.webix_toolbar .webix_img_btn,
.webix_layout_toolbar.webix_toolbar .webix_img_btn_top {
  border:1px solid #f6f6f6;
}
.webix_layout_space > .webix_toolbar {
  border-radius: 4px;
}
.webix_view.webix_layout_accordion {
  background-color: #dddddd;
}
/*.webix_layout_accordion > .webix_accordionitem > .webix_accordionitem_header,
.webix_layout_space > .webix_accordionitem > .webix_accordionitem_header,
.webix_layout_wide > .webix_accordionitem > .webix_accordionitem_header{
	#border > .top(@layoutRadius);
}
.webix_layout_accordion > .webix_accordionitem.collapsed,
.webix_layout_space > .webix_accordionitem.collapsed,
.webix_layout_wide > .webix_accordionitem.collapsed{
	#border > .radius(@layoutRadius);
}
.webix_layout_accordion > .webix_accordionitem > .webix_accordionitem_body,
.webix_layout_space > .webix_accordionitem > .webix_accordionitem_body,
.webix_layout_wide > .webix_accordionitem > .webix_accordionitem_body{
	#border > .bottom(@layoutRadius);
}*/
.webix_accordionitem .webix_accordionitem_button {
  float: right;
  position: relative;
  height: 19px;
  width: 19px;
  margin: 14px 13.5px;
}
.webix_accordionitem.collapsed .webix_accordionitem_button {
  float: right;
  position: relative;
  height: 19px;
  width: 19px;
  margin-top: 13px;
}
.webix_accordionitem.horizontal.collapsed,
.webix_accordionitem.collapsed {
  background-color: #f6f6f6;
}
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header,
.webix_accordionitem.collapsed .webix_accordionitem_header {
  background-color: #f6f6f6;
  color: #354866;
}
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header .webix_accordionitem_button,
.webix_accordionitem.collapsed .webix_accordionitem_header .webix_accordionitem_button {
  color: #354866;
}
.webix_accordionitem .webix_accordionitem_header .webix_accordionitem_button {
  color: #354866;
  background-image: none;
  font-family: FontAwesome;
  font-size: 24px;
  text-align: center;
  width: 24px;
  height: 45px;
  margin: 0 10.5px 0 10.5px;
}
.webix_accordionitem .webix_accordionitem_header .webix_accordionitem_button:before {
  content: '\f104';
}
.webix_accordionitem .webix_accordionitem_header.collapsed .webix_accordionitem_button:before {
  content: '\f106';
}
.webix_accordionitem.horizontal:last-child > .webix_accordionitem_header .webix_accordionitem_button:before {
  content: '\f105';
}
.webix_accordionitem.horizontal.collapsed:last-child > .webix_accordionitem_header .webix_accordionitem_button:before {
  content: '\f107';
}
.webix_accordionitem.vertical > .webix_accordionitem_header .webix_accordionitem_button {
  height: 42px;
  margin: 0px 10.5px 0 10.5px;
}
.webix_accordionitem.vertical > .webix_accordionitem_header .webix_accordionitem_button:before {
  content: '\f106';
}
.webix_accordionitem.vertical > .webix_accordionitem_header.collapsed .webix_accordionitem_button:before {
  content: '\f107';
}
.webix_toolbar.webix_layout_subbar {
  background: transparent;
}
.webix_toolbar.webix_layout_subbar .webix_view {
  color: #666666;
}
.webix_el_tabbar + .webix_multiview {
  background-color: #ffffff;
}
.webix_view.rounded_top {
  -moz-border-top-left-radius: 4px;
  -moz-border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.webix_view.rounded_right {
  -moz-border-top-right-radius: 4px;
  -moz-border-bottom-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}
.webix_view.rounded_bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-bottom-left-radius: 4px;
  -moz-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}
.webix_view.rounded_left {
  -moz-border-top-left-radius: 4px;
  -moz-border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
}
.webix_layout_space > .webix_view,
.webix_layout_wide > .webix_view {
  border-radius: 2px;
}
.webix_layout_space .webix_layout_accordion .webix_accordionitem,
.webix_layout_wide .webix_layout_accordion .webix_accordionitem {
  border-radius: 4px;
}
.webix_view .webix_win_head {
  border-bottom: 1px solid #dddddd;
}
.webix_view .webix_win_head > .webix_view > .webix_template {
  color: #666666;
  background: #ffffff;
  box-shadow: 0 -2px #3498db inset;
}
.webix_modal_box .webix_popup_title {
  color: #666666;
  background: #ffffff;
  font-size: 15px;
  text-shadow: none;
}
.webix_popup_text
{
  box-shadow: 0 3px #3498db inset;
  font-size: 15px;
}
.webix_alert-warning .webix_popup_text,
.webix_confirm-warning .webix_popup_text {
  box-shadow: 0 3px #ffd21a inset;
}
.webix_alert-error .webix_popup_text,
.webix_confirm-error .webix_popup_text {
  box-shadow: 0 3px #ff0000 inset;
}
.webix_view.webix_popup {
  border: 1px solid #dddddd;
}
.webix_view.webix_popup.app_top_submenu
{
  background-color:#f6f6f6;
  border-color:#cbcaca;
}
.webix_modal_box {
  border: 1px solid #dddddd;
}
.confirmButton {
  font-size: 15px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #3498db;
  border-bottom: 1px solid #268fd5;
  outline: none;
  background: #27ae60;
  border-color: #27ae60;
  border-bottom: 1px solid #209150;
  box-shadow: none;
  text-shadow: none;
  color: #ffffff;
}
.confirmButtonActive {
  background: #2ecb70;
}
.webix_popup_button {
  border: none;
}
.webix_dtable .webix_ss_left .webix_column.webix_last > div {
  border-right: 1px solid #d0d0d0;
}
.webix_dtable .webix_last_topcell {
  border-bottom: 1px solid #d0d0d0;
}
.webix_dtable .webix_ss_right .webix_column.webix_first > div {
  border-left: 1px solid #d0d0d0;
}
.webix_dtable .webix_hs_right td.webix_first {
  border-left: 1px solid #b0cee3;
}
.webix_dtable .webix_hcell.webix_ss_filter {
  padding: 0px 0px;
}
.webix_dtable .webix_hcell.webix_div_filter {
  padding: 0px 0px;
}
.webix_dtable .webix_ss_filter select,
.webix_dtable .webix_ss_filter input {
  height: 28px;
  vertical-align: middle;
  border:none;
}
.webix_ss_sort_asc,
.webix_ss_sort_desc {
  font-size: 14px;
  font-family: FontAwesome;
  top: 50%;
  line-height: 1px;
  bottom: auto;
  right: 3px;
  float: none;
  width: 12px;
}
.webix_ss_sort_asc:before {
  content: '\f106';
}
.webix_ss_sort_desc:before {
  content: '\f107';
}
/*.webix_ss_header tr:nth-child(2) td{
	border-top: 1px solid transparent ;
}*/
.dtBodyCell {
  border-bottom: 1px solid #ebebeb;
}
.webix_dtable .webix_ss_vscroll_header {
  border-bottom: 1px solid #cdcdcd;
}
.webix_view .webix_dt_editor input,
.webix_view .webix_dt_editor select {
  border: 1px solid #3498db;
}
.dataItemStyle {
  border-color: #ebebeb;
}
.webix_list_item.webix_group_back {
  color: #4a4a4a;
  background: #d2e3ef;
  border-bottom: 1px solid #b0cee3;
}
.webix_dataview_item {
  border-right-color: #ebebeb;
}
.webix_view .webix_pager_item {
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #dadada;
  outline: none;
  color: #787574;
  background: #f1f1f1;
  font-size: 13px;
}
.webix_view .webix_pager_item:hover {
  background-color: #e0e0e0;
  border: 1px solid #dadada;
  color:#354866;
}
.webix_view .webix_pager_item:focus {
  background-color: #e0e0e0;
  border: 1px solid #c8c8c8;
  color:#354866;
}
.webix_view .webix_pager_item_selected {
  font-size: 13px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #0079a5;
  outline: none;
  background: #0079a5;
  color: #ffffff;
  box-shadow: none;
}
.webix_view .webix_pager_item_selected:hover{
  background-color: #04597a;
}
.webix_view .webix_pager_item_selected:focus {
  background-color: #04597a;
}
.webix_view .webix_pager_item,
.webix_view .webix_pager_item_selected {
  border-radius: 2px;
}
.webix_list .webix_unit_header {
  font-weight: normal;
  text-shadow: none;
  background: #d2e3ef;
  font-size: 15px;
  color: #4a4a4a;
}
.webix_view .webix_tree_open,
.webix_view .webix_tree_close {
  background-image: none;
  font-family: FontAwesome;
  font-size: 14px;
  text-align: center;
  color: #666666;
}
.webix_view .webix_tree_open:before {
  content: "\f147";
}
.webix_view .webix_tree_close:before {
  content: "\f196";
}
.webix_menu-x .webix_submenu_icon,
.webix_view.webix_menu .webix_submenu_icon {
  width: 6px;
  height: auto;
  font-size: 14px;
  font-family: FontAwesome;
  line-height: 1px;
  margin-top: 3px;
}
.webix_menu.webix_view .webix_list_item:hover,
.webix_menu.webix_view .webix_list_item:active {
  /* background-color: #f5f5f5; */
}
.webix_menu.webix_view .webix_list_item .webix_submenu_icon:before {
  content: '\f105';
}
.webix_menu.webix_view .webix_list_item.webix_selected {
  background: #27ae60;
}
.webix_property .webix_property_label,
.webix_property .webix_property_value {
  border-color: #ebebeb;
}
.webix_view .webix_drag_over,
.webix_view .webix_drop_zone {
  background-color: #f4f4f4;
}
.propertyStyle {
  height: 28px;
  line-height: 27px;
}
.propertyTitle {
  line-height: 28px;
}
.webix_view button,
.webix_view input[type=button] {
  -webkit-appearance: none;
}
.webix_el_combo span.webix_input_icon,
.webix_el_richselect span.webix_input_icon,
.webix_el_datepicker span.webix_input_icon,
.webix_el_colorpicker span.webix_input_icon,
.webix_el_dbldatepicker span.webix_input_icon {
  background-color: transparent;
  color: #666b6b;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.focusStyle {
  border: 1px solid #3498db;
  box-shadow: 0 0 1px #3498db inset;
}
.webix_el_search input,
.webix_el_combo input,
.webix_el_colorpicker input {
  padding-right: 28px;
}
.webix_el_search input,
.webix_el_text input,
.webix_el_combo input,
.webix_el_colorpicker input,
.webix_el_datepicker input {
  border-radius: 2px;
}
.webix_el_search input:focus,
.webix_el_text input:focus,
.webix_el_combo input:focus,
.webix_el_colorpicker input:focus,
.webix_el_datepicker input:focus {
  border: 1px solid #3498db;
  box-shadow: 0 0 1px #3498db inset;
}
.webix_el_counter input[type=text]:focus {
  border-top: 1px solid #3498db;
  border-bottom: 1px solid #3498db;
  box-shadow: 0 0 1px #3498db inset;
}
.webix_el_richselect .webix_inp_static,
.webix_el_datepicker .webix_inp_static,
.webix_el_colorpicker .webix_inp_static,
.webix_el_dbldatepicker .webix_inp_static {
  border-radius: 2px;
  padding-right: 28px;
  white-space: nowrap;
}
.webix_el_richselect .webix_inp_static:focus,
.webix_el_datepicker .webix_inp_static:focus,
.webix_el_colorpicker .webix_inp_static:focus,
.webix_el_dbldatepicker .webix_inp_static:focus {
  border: 1px solid #3498db;
  box-shadow: 0 0 1px #3498db inset;
}
.webix_el_textarea textarea:focus {
  border: 1px solid #3498db;
  box-shadow: 0 0 1px #3498db inset;
}
.webix_el_select select:focus {
  border: 1px solid #3498db;
  box-shadow: 0 0 1px #3498db inset;
}
.webix_el_select select,
.webix_el_textarea textarea {
  border-radius: 2px;
}
.webix_el_combo span.webix_input_icon,
.webix_el_richselect span.webix_input_icon {
  width: 21px;
  padding-left: 8px;
}
.webix_el_datepicker span.webix_input_icon,
.webix_el_dbldatepicker span.webix_input_icon,
.webix_el_colorpicker span.webix_input_icon,
.webix_el_search span.webix_input_icon {
  width: 24px;
}
.webix_el_text .webix_inp_static {
  border-radius: 6px;
  white-space: nowrap;
}
.webix_focused .webix_inp_static {
  border: 1px solid #3498db;
  box-shadow: 0 0 1px #3498db inset;
}
.webix_multilist .fa-check-square {
  color: #3498db;
}
.buttonStyle {
  color: #ffffff;
  font-size: 15px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #3498db;
  border-bottom: 1px solid #268fd5;
  outline: none;
}
/*.webix_el_button input, .webix_el_toggle input, .webix_el_button button, .webix_el_toggle button, .webixbutton{
 	border-width:0px;
 }*/
.webix_el_colorpicker div.webix_inp_static div {
  margin: 2.5px -20px 0px -5px;
}
div.webix_inp_static,
.webix_el_textarea textarea {
  background: #ffffff;
  font-family: StolzlRegular,sans-serif;
  border-color: #cccccc;
  /*.altBackColor;
	.altBackBorderColor;*/
}
.webix_el_select select {
  font-family: StolzlRegular,sans-serif;
  border-color: #dddddd;
}
.webix_el_checkbox input {
  border-color: #dddddd;
}
.webix_el_counter .webix_inp_counter_value {
  width: 40px;
  background: #ffffff;
  font-family: StolzlRegular,sans-serif;
  border-color: #cccccc;
  /*.altBackColor;
	.altBackBorderColor;*/
}
.webix_el_counter .webix_inp_counter_prev,
.webix_el_counter .webix_inp_counter_next {
  background: #3498db;
  width: 29px;
  color: #ffffff;
  font-size: 15px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  border: 1px solid #3498db;
  border-bottom: 1px solid #268fd5;
  outline: none;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}
.webix_el_counter .webix_inp_counter_prev {
  font-size: 20px;
}
.webix_el_counter .webix_inp_counter_next {
  font-size: 20px;
}
.webix_view.webix_control.webix_el_tabbar {
  background: transparent;
}
div.webix_item_tab {
  border-color: #dadada;
  padding-top: 3px;
  font-size: 12px;
}
div.webix_item_tab.webix_selected {
  padding-top: 2px;
}
.webixtype_bottom .webix_item_tab,
.webixtype_bottom .webix_item_tab.webix_selected {
  border-color: #3498db;
  border-right: 1px solid #5faee3;
  background: #3498db;
  color: #ffffff;
  padding-top: 0;
}
.webixtype_bottom .webix_item_tab:last-child,
.webixtype_bottom .webix_item_tab.webix_selected:last-child {
  border-right: 1px solid #5faee3;
}
.webixtype_bottom .webix_item_tab.webix_selected {
  background: #2383c4;
  color: #ffffff;
}
.webix_el_tabbar .webixtype_icon {
  background: #3498db;
}
.webix_el_tabbar .webixtype_icon .webix_item_tab {
  white-space: nowrap;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  color: #ffffff;
  text-align: center;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn .webix_icon_btn {
  color: #ffffff;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn .webix_icon_btn {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 24px;
  height: 100%;
  line-height: inherit;
  margin: 0 3px;
  vertical-align: top;
  width: 100%;
}
.webix_el_tabbar .webixtype_icon .webix_item_tab.webix_selected {
  background: #2383c4;
}
.webix_accordionitem_label .webix_icon {
  font-size: 14px;
  display: inline-block;
  width: 20px;
  color: #2e2e38;
}
.webix_item_tab .webix_icon {
  width: 30px;
  font-size: 19px;
}
.webix_el_tabbar .webixtype_iconTop,
.webix_el_tabbar .webixtype_image {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_el_tabbar .webixtype_iconTop .webix_icon,
.webix_el_tabbar .webixtype_image .webix_icon {
  color: #ffffff;
  font-size: 18px;
  top: 1px;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab,
.webix_el_tabbar .webixtype_image .webix_item_tab {
  font-size: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.webix_el_tabbar .webixtype_iconTop .webix_img_btn_top .webix_icon,
.webix_el_tabbar .webixtype_image .webix_img_btn_top .webix_icon {
  margin-top: 2px;
}
.webix_el_tabbar .webixtype_iconTop .webix_img_btn_text,
.webix_el_tabbar .webixtype_image .webix_img_btn_text {
  bottom: 0px;
  font-size: 15px;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected {
  background: #2383c4;
  box-shadow: 0 0 0 1px #3498db inset;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_img_btn_text,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_img_btn_text {
  color: #ffffff;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_icon,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_icon {
  color: #ffffff;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_icon_btn,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_icon_btn {
  background-color: #2383c4;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_img_btn,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_img_btn {
  color: #ffffff;
}
.webix_el_tabbar .webixtype_icon,
.webix_el_tabbar .webixtype_iconTop,
.webix_el_tabbar .webixtype_image {
  background: #3498db;
}
.webix_el_tabbar .webixtype_icon .webix_tab_filler,
.webix_el_tabbar .webixtype_iconTop .webix_tab_filler,
.webix_el_tabbar .webixtype_image .webix_tab_filler {
  border-bottom-color: #3498db;
}
.webix_el_tabbar .webixtype_icon .webix_item_tab,
.webix_el_tabbar .webixtype_iconTop .webix_item_tab,
.webix_el_tabbar .webixtype_image .webix_item_tab {
  padding: 0;
  border-radius: 0;
}
.webix_el_tabbar .webixtype_icon .webix_item_tab,
.webix_el_tabbar .webixtype_iconTop .webix_item_tab,
.webix_el_tabbar .webixtype_image .webix_item_tab {
  color: #ffffff;
  background: #3498db;
  border-color: #3498db;
  border-bottom: 1px solid #3498db;
}
.webix_el_tabbar .webixtype_icon .webix_item_tab .webix_img_btn,
.webix_el_tabbar .webixtype_iconTop .webix_item_tab .webix_img_btn,
.webix_el_tabbar .webixtype_image .webix_item_tab .webix_img_btn {
  color: #ffffff;
}
.webix_tab_more_icon:hover {
  color: #4d4d4d;
}
.webix_el_tabbar .webixtype_icon .webix_tab_more_icon,
.webix_el_tabbar .webixtype_iconTop .webix_tab_more_icon,
.webix_el_tabbar .webixtype_image .webix_tab_more_icon,
.webix_el_tabbar .webixtype_bottom .webix_tab_more_icon {
  background: #3498db;
}
.webix_el_tabbar .webixtype_icon .webix_tab_more_icon .webix_icon,
.webix_el_tabbar .webixtype_iconTop .webix_tab_more_icon .webix_icon,
.webix_el_tabbar .webixtype_image .webix_tab_more_icon .webix_icon,
.webix_el_tabbar .webixtype_bottom .webix_tab_more_icon .webix_icon {
  color: #ffffff;
}
.webix_el_tabbar .webixtype_icon .webix_tab_more_icon:hover,
.webix_el_tabbar .webixtype_iconTop .webix_tab_more_icon:hover,
.webix_el_tabbar .webixtype_image .webix_tab_more_icon:hover {
  background-color: #268fd5;
}
.webix_tab_filler:first-child {
  border-right: 0;
}
.passiveBarBG {
  background: #a4b4bf;
  color: #ffffff;
}
.webix_all_tabs {
  padding-left: 1px;
  padding-right: 1px;
}
.webix_after_all_tabs {
  background: #cdcdcd;
}
.webix_el_box .webixtype_form:active {
  background-color: #04597a;
  border-color:#41546d;
  box-shadow: none;
}
.webix_el_box .webixtype_form:focus {
  background-color: #04597a;
}
.webix_el_box .webixtype_form:hover {
  background-color: #04597a;
  border-color:#04597a;
}
.webixtype_danger:hover {
  background-color: #ca4635;
}
.webixtype_danger:focus {
  background-color: #c14433;
}
.webixtype_danger:active {
  background-color: #c14433;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webixtype_prev:focus,
.webixtype_prev:focus + .webixtype_prev_arrow,
.webixtype_next:focus,
.webixtype_next:focus + .webixtype_next_arrow {
  background-color: #04597a;
}
.webixtype_prev:hover,
.webixtype_prev:hover + .webixtype_prev_arrow,
.webixtype_next:hover,
.webixtype_next:hover + .webixtype_next_arrow {
  background-color: #04597a;
}
.webix_el_box .webixtype_form {
  color: white;
  font-size: 13px;
  padding: auto 6px;
  font-family: StolzlRegular,sans-serif;
  outline: none;
  background: #0079a5;
  box-shadow: none;
  border:1px solid #0079a5;
}
.webixbutton:hover,
.webixtype_base:hover,
.webix_inp_counter_next:hover,
.webix_inp_counter_prev:hover,
.webix_segment_0:hover,
.webix_segment_1:hover,
.webix_segment_N:hover {
  background-color: #04597a;
}
.webixbutton:active,
.webixtype_base:active,
.webix_inp_counter_next:active,
.webix_inp_counter_prev:active,
.webix_segment_0:active,
.webix_segment_1:active,
.webix_segment_N:active {
  background-color: #04597a;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_el_text .webix_input_icon:hover,
.webix_el_text .webix_input_icon:active {
  color: #3498db;
  background: inherit;
  cursor: pointer;
}
.webixbutton:focus,
.webixtype_base:focus,
.webix_inp_counter_next:focus,
.webix_inp_counter_prev:focus,
.webix_segment_0:focus,
.webix_segment_1:focus,
.webix_segment_N:focus {
  background-color: #04597a;
}
.webix_selected.webix_segment_0:hover,
.webix_selected.webix_segment_1:hover,
.webix_selected.webix_segment_N:hover {
  background-color: #239d57;
}
.webix_selected.webix_segment_0:active,
.webix_selected.webix_segment_1:active,
.webix_selected.webix_segment_N:active {
  background-color: #219552;
}
.webix_selected.webix_segment_0:focus,
.webix_selected.webix_segment_1:focus,
.webix_selected.webix_segment_N:focus {
  background-color: #219552;
}
.webix_all_segments .webix_segment_1,
.webix_all_segments .webix_segment_N {
  margin-left: 1px;
  border-left: none;
}
.webix_el_slider .webix_slider_title {
  margin-bottom: -13px;
}
.activeShadow {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.toggleShadow {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.18);
}
.webix_el_toggle .webix_pressed .webix_img_btn,
.webix_el_toggle .webix_pressed .webix_img_btn_top {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_el_toggle .webix_el_box.webix_pressed .webixtype_base,
.webix_el_toggle .webix_el_box.webix_pressed .webix_img_btn_abs {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #2489cc;
}
.webix_img_btn_abs_top .webix_icon {
  font-size: 22px;
}
.webix_img_btn:hover,
.webix_img_btn_top:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_img_btn:active,
.webix_img_btn_top:active {
  background-color: rgba(0, 0, 0, 0.07);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_img_btn:focus,
.webix_img_btn_top:focus {
  background-color: rgba(0, 0, 0, 0.07);
}
.webix_view .webix_fieldset fieldset
{
  border:none;
  border-top: 1px solid #dedede;
}
.webix_view .webix_fieldset legend {
  color: #787878;
}
.webix_custom_checkbox:before {
  content: "\f00c";
  color: #354866;
  font-family: FontAwesome;
  font-size: 16px;
  height: 14px;
  display: block;
  line-height: 13px;
  width: 14px;
}
.webix_checkbox_0 .webix_custom_checkbox:before {
  visibility: hidden;
}
.webix_custom_checkbox {
  float: left;
  position: relative;
  top: 50%;
  width: auto;
  margin-top: -7px;
  padding: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #ffffff;
}
.webix_custom_checkbox:focus {
}
a:focus .webix_custom_checkbox,
a:active .webix_custom_checkbox {
  box-shadow: 0 0 1px #3498db inset;
}
.webix_el_radio .webix_custom_radio:before {
  content: " ";
  background-color: #ffffff;
}
.webix_el_radio .webix_custom_radio:before {
  content: " ";
  border-radius: 50%;
  display: block;
  width: 7px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 7px;
  background-color: #3498db;
}
.webix_el_radio .webix_radio_0 .webix_custom_radio:before {
  visibility: hidden;
}
.webix_el_radio .webix_custom_radio {
  display: block;
  height: 15px;
  width: 15px;
  padding: 3px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #ffffff;
}
.webix_el_radio .webix_custom_radio:focus {
  border-color: #3498db;
}
.webix_el_radio .webix_label_right {
  color: #666666;
  font-size: 15px;
}
a:focus .webix_custom_radio,
a:active .webix_custom_radio {
  border: 1px solid #3498db;
  box-shadow: 0 0 1px #3498db inset;
}
.webix_cal_body .webix_cal_today {
  /*background-color: transparent;
		color: @fontColor;*/
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #0079a5;
  border-radius: 2px;
}
.webix_cal_body .webix_cal_today span {
  /*#box > .sizing;
			border: 1px solid @selectColor;*/
}
.webix_cal_body .webix_cal_day,
.webix_cal_body .webix_cal_week_num {
  font-size: 13px;
}
.webix_segment_0,
.webix_segment_1,
.webix_segment_N {
  color: #1A84D8;
  background: #ffffff;
  border: none;
  border-top: 1px solid #c7d2e5;
  border-bottom: 1px solid #c7d2e5;
}
.webix_segment_0:first-child,
.webix_segment_1:first-child,
.webix_segment_N:first-child {
  border-left: 1px solid #c7d2e5;
}
.webix_segment_0:last-child,
.webix_segment_1:last-child,
.webix_segment_N:last-child {
  border-right: 1px solid #c7d2e5;
}
.webix_segment_0:active,
.webix_segment_1:active,
.webix_segment_N:active {
  color: #1A84D8;
  background: #edf9fe;
  border: none;
}
.webix_segment_0:hover,
.webix_segment_1:hover,
.webix_segment_N:hover {
  color: #1A84D8;
  background: #edf9fe;
}
.webix_segment_0.webix_selected,
.webix_segment_1.webix_selected,
.webix_segment_N.webix_selected {
  color: #ffffff;
  background: #1A84D8;
  border: none;
}
.webix_segment_0.webix_selected:hover:first-child,
.webix_segment_1.webix_selected:hover:first-child,
.webix_segment_N.webix_selected:hover:first-child {
  padding-left: 0px;
}
.webix_segment_0.webix_selected:hover:last-child,
.webix_segment_1.webix_selected:hover:last-child,
.webix_segment_N.webix_selected:hover:last-child {
  padding-right: 0px;
}
.webix_segment_0 {
  border-radius: 2px 0 0 2px;
}
.webix_segment_N {
  border-radius: 0 2px 2px 0;
}
.webix_view input.webix_inp_counter_prev,
.webix_view input.webix_inp_counter_next {
  width: 30px;
  font-size: 22.23px;
  line-height: 22.23px;
  border: none;
}
.webix_el_combo .webix_input_icon,
.webix_el_richselect .webix_input_icon,
.webix_el_datepicker .webix_input_icon,
.webix_el_colorpicker .webix_input_icon,
.webix_el_search .webix_input_icon {
  right: 0px;
  top: 5px;
}
.webix_el_slider .webix_slider_title {
  font-size: 11.18px;
  margin-bottom: -4px;
  margin-top: -6px;
}
.webix_slider_box .webix_slider_handle {
  background: #1A84D8;
}
.webix_accordionitem_label:hover {
  background: #f2f2f2;
}
.webix_accordionitem,
.webix_accordionitem.vertical,
.webix_accordionitem.horizontal
{
  transition:width 0.5s;
}
.webix_cal_month_name,
.webix_cal_icon {
  color: #1A84D8;
  text-decoration: none;
}
.webix_cal_month_name:visited,
.webix_cal_icon:visited,
.webix_cal_month_name:focus,
.webix_cal_icon:focus {
  color: #1A84D8;
  text-decoration: none;
}
.webix_cal_month_name:hover,
.webix_cal_icon:hover {
  color: #1A84D8;
  text-decoration: underline;
}
.webix_cal_day,
.webix_cal_week_num {
  line-height: 25px;
  background: none;
}
.webix_cal_body .webix_selected,
.webix_cal_body .webix_cal_select,
.webix_cal_body .webix_cal_select.webix_cal_today {
  /*background: transparent;
		color: @fontColor;*/
  border-radius: 2px;
}
.webix_cal_body .webix_selected,
.webix_cal_body .webix_cal_event.webix_cal_select {
  color: #ffffff;
  border-radius: 6px;
  background: #27ae60;
}
.webix_cal_event {
  color: #276fb1;
}
.webix_cal_outside {
  font-weight: normal;
  color: #bbb;
}
.webix_time_footer .webix_cal_done,
.webix_cal_footer .webix_cal_done {
  font-size: 13px;
  padding: auto 2px;
  font-family: StolzlRegular,sans-serif;
  outline: none;
  color: #ffffff;
  background: #0079a5;
  border-bottom: 1px solid #0079a5;
}
.webix_time_footer .webix_cal_done:hover,
.webix_cal_footer .webix_cal_done:hover {
  background-color: #268fd5;
}
.webix_time_footer .webix_cal_done:focus,
.webix_cal_footer .webix_cal_done:focus {
  background-color: #2489cc;
}
.webix_cal_month {
  line-height: 20px;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
}
.webix_view > .webix_cal_header div {
  font-size: 12px;
}
.webix_cal_month_name {
  font-size: 14px;
  font-weight: normal;
}
.webix_daterange .webix_range_timepicker .webix_cal_time {
  padding: 5px 15px;
}
.webix_cal_range {
  background-color: #e1e8f0;
}
.webix_progress_top .webix_progress_state,
.webix_progress_bottom .webix_progress_state {
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
}
.webix_progress_icon .webix_progress_state:before {
  content: '';
  width: 80px;
  height: 80px;
  display: block;
  fill: #1A84D8;
  color: #1A84D8;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJsb2FkZXItMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiICAgICB3aWR0aD0iODBweCIgaGVpZ2h0PSI4MHB4IiB2aWV3Qm94PSIwIDAgNTAgNTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUwIDUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+ICA8cGF0aCBmaWxsPSIjMzQ5OGRiIiBkPSJNMjUuMjUxLDYuNDYxYy0xMC4zMTgsMC0xOC42ODMsOC4zNjUtMTguNjgzLDE4LjY4M2g0LjA2OGMwLTguMDcxLDYuNTQzLTE0LjYxNSwxNC42MTUtMTQuNjE1VjYuNDYxeiI+ICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZVR5cGU9InhtbCIgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iICAgICAgdHlwZT0icm90YXRlIiAgICAgIGZyb209IjAgMjUgMjUiICAgICAgdG89IjM2MCAyNSAyNSIgICAgICBkdXI9IjAuNnMiICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4gICAgPC9wYXRoPiAgPC9zdmc+);
}
.webix_progress_bottom .webix_progress_state,
.webix_progress_top .webix_progress_state {
  background-size: 10px 10px;
}
.webix_progress_bottom .webix_progress_state {
  background-size: 14px 14px;
}
.webix_skin_mark {
  height: 200px;
}
.webix_sparklines {
  padding: 1px 0;
  box-sizing: border-box;
}
.webix_sparklines svg {
  box-sizing: border-box;
}
.webix_sparklines_line {
  stroke: #3498db;
  stroke-width: 1;
  fill: transparent;
  stroke-linecap: round;
}
.webix_sparklines_item {
  fill: #3498db;
  stroke-width: 1;
}
.webix_sparklines_bar {
  fill: #6dbcf0;
}
.webix_sparklines_bar_negative {
  fill: #d86c79;
}
.webix_sparklines_area {
  fill: #e5eef4;
}
.webix_sparklines_origin {
  stroke: #888;
  stroke-width: 1;
}
.webix_sparklines_event_area {
  fill: transparent;
}
.webix_sparklines_line_chart .webix_sparklines_event_area:hover,
.webix_sparklines_area_chart .webix_sparklines_event_area:hover,
.webix_sparklines_splinearea_chart .webix_sparklines_event_area:hover {
  fill: rgba(220, 220, 220, 0.4);
  stroke: rgba(255, 255, 255, 0.6);
}
.webix_sparklines_pie_chart .webix_sparklines_event_area:hover,
.webix_sparklines_bar_chart .webix_sparklines_event_area:hover {
  fill: rgba(255, 255, 255, 0.3);
}
/* print css rules*/
.webix_ui_print {
  display: none;
}
body.webix_print {
  margin-top: 0px;
}
@media print {
  body.webix_print {
    overflow: visible !important;
    background: none !important;
  }
  body.webix_print > * {
    display: none;
  }
  body.webix_print * {
    visibility: hidden;
  }
  .webix_ui_print {
    display: block !important;
    margin: 0 0 0 0!important;
    visibility: visible !important;
  }
  .webix_ui_print * {
    visibility: visible !important;
  }
  .webix_print_noscroll {
    height: auto !important;
    width: auto !important;
  }
  .webix_print_noscroll,
  .webix_print_noscroll .webix_scroll_cont {
    overflow: visible !important;
  }
  .webix_print_pagebreak {
    page-break-after: always;
  }
  .webix_print_header,
  .webix_print_footer {
    display: block !important;
    margin: 20px 0px;
    text-align: center;
    height: auto !important;
    visibility: visible !important;
  }
  .webix_print_header *,
  .webix_print_footer * {
    visibility: visible !important;
  }
  /*datatable specific*/
  .webix_table_print {
    display: table;
    visibility: visible !important;
    table-layout: fixed;
    width: 100%;
    border: 1px solid #ebebeb;
    margin-bottom: 20px;
    position: initial;
  }
  .webix_table_print td {
    overflow: hidden;
  }
  .webix_table_print tr {
    page-break-inside: avoid;
  }
  .webix_table_print .webix_cell {
    display: table-cell !important;
    border-right: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 0px 10px;
    white-space: nowrap;
  }
  .webix_table_print .webix_header_cell {
    display: table-cell;
    font-size: 15px;
    color: #4a4a4a;
    background: #d2e3ef;
    border-right: 1px solid #b0cee3;
    border-bottom: 1px solid #b0cee3;
  }
  .webix_table_print .webix_footer_cell {
    display: table-cell;
    background: #fafafa;
    border-right: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
  }
  .webix_table_print .webix_cell.webix_dtable_span {
    position: initial !important;
    white-space: normal;
  }
  .webix_table_print .webix_rotate {
    transform-origin: center 40% !important;
    -webkit-transform-origin: center !important;
  }
  .webix_table_print.borderless,
  .webix_table_print.borderless .webix_cell {
    border: none;
  }
  /* x-list specific*/
  .webix_table_print.webix_view.webix_list-x {
    white-space: normal;
  }
  .webix_table_print.webix_view.webix_list-x .webix_list_item {
    display: table-cell;
  }
}
/*predefined styles for text lables inside a item*/
.webix_strong {
  font-weight: bold;
  white-space: nowrap;
}
.webix_light {
  color: #b3b3b3;
}
/*debug message styles*/
.webix_debug div {
  border: 1px solid #FF9;
  background-color: #FF9;
  color: #000;
}
.webix_debug {
  background-color: #ff9;
  border: 1px solid #ff9;
}
/*contr-styles for bootstrap*/
.webix_view,
.webix_input_icon {
  box-sizing: content-box;
}
.webix_view.webix_el_label {
  box-sizing: border-box;
}
.webix_inp_label,
.webix_inp_top_label,
.webix_label_right {
  margin-bottom: 0px;
}
.webix_view table {
  border-collapse: initial;
}
.webix_hcell
{
  font-size: 12px;
  font-weight: bold;
}
.webix_hcell .webix_el_box
{
  padding: 0;
  height: 28px !important;
  border: none;
  border-radius: 0;
}
.webix_hcell .webix_input_icon
{
  padding-top: 0px !important;
  right: 0 !important;
  background-color: white;
}
.webix_hcell .webix_inp_static
{
  line-height: 28px !important;
  border: none;
  border-radius: 0;
  width: 100% !important;
  font-weight: normal;
}
.webix_cell
{
  font-size: 13px;
}
.webix_hcell .webix_richfilter
{
  margin: 0 !important;
}
/* </editor-fold desc="@group Webix"> */

/* <editor-fold desc="@group main_container"*/
.main_container {
  margin: 0!important;
}
/* </editor-fold desc="@group main_container"*/

/* <editor-fold desc="@group sidebar"> */
.app_top_version > div {
  color: white;
}
.main_menu_sidebar .webix_layout_line {
  background-color: #606062 !important;
}
.app_sidebar_collapse_button .webix_icon_btn {
  font-size: 20px;
}
.app_sidebar_collapse_button
{
  background-color: transparent;
}
.app_top_header .app_sidebar_collapse_button .webix_icon_btn {
  transform: translateY(1px) translateX(10px);
}
.app_logo + .app_sidebar_collapse_button .webix_icon_btn {
  transform: translateY(-2px) translateX(6px);
}
.app_sidebar_collapse_button button
{
  margin-left:-7px;
}
.app_sidebar_collapse_button *:hover {
  background-color: transparent !important;
}
.webix_icon_btn
{
  color: #e3dcd6;
}
.webix_sidebar{
  background: #606062;
}
.main_menu_sidebar > * {
  font-family: StolzlRegular, sans-serif !important;
}
.app_sidebar_search_input {
  width: 319px !important;
}
.webix_control .app_sidebar_search_input {
  overflow: hidden !important;
}
.app_sidebar_search_input > div {
  display: flex;
  justify-content: center;
  width: 320px !important;
  padding: 0;
  background-color: #606062;
}
.app_sidebar_search_input > div > input {
  width: 288px !important;
  font-size: 14px;
  color: white;
  height: 32px;
  border-color: transparent;
  border-radius: 4px;
  background-color: #6f6f72;
}
.app_sidebar_search_input > div > input::placeholder {
  font-size: 14px;
  color: #C2CAD9;
}
.app_sidebar_search_input > div > input + span {
  display: none;
}
.app_sidebar_search_clear_btn *:hover {
  background-color: transparent !important;
}
.app_sidebar_search_clear_btn {
  transform: translateX(-42px);
}
.app_sidebar_search_clear_btn .webix_el_box {
  width: 20px !important;
}
.app_sidebar_search_clear_btn span {
  transform: translateX(-2px);
}
.webix_sidebar .webix_tree_item {
  color: #f6f6f6;
  height: 35px;
  line-height: 35px;
  background-color: #606062;
}
.webix_sidebar_expanded .webix_scroll_cont > .webix_tree_leaves {
  padding-left: 15px !important;
}
.webix_sidebar .webix_scroll_cont > .webix_tree_leaves {
  padding: 0;
}
.webix_sidebar .webix_tree_leaves .webix_tree_leaves {
  margin-left: 0;
}
.webix_sidebar_selected,
.webix_sidebar_expanded .webix_tree_item:hover{
  background-color: rgba(0,0,0,0.02);
  cursor: pointer;
}
.webix_sidebar .webix_tree_item.webix_selected{
  background-color: #f6f6f6;
  padding-right:0;
}
.webix_tree_branch_1 > div[aria-expanded="true"] span:first-child {
  transform: translateX(-13px) rotate(180deg);
}
.webix_tree_branch_1 > div[aria-expanded="false"] span:first-child {
  transform: translateX(-1px) rotate(0deg);
}
.webix_sidebar .webix_tree_branch_1 .webix_tree_item{
  padding-left:54px;
}
.webix_sidebar .webix_tree_branch_1>.webix_tree_item{
  height: 54px;
  line-height: 54px;
  padding-left:0;
}
.webix_tree_branch_1 .webix_tree_branch_2 div[webix_tm_id*="SidebarSeparator"] {
  pointer-events: none;
  border-bottom: 1px #E0E5EF solid !important;
  height: 1px;
  width: 260px;
}
.webix_tree_branch_1 .webix_tree_branch_2 div[webix_tm_id*="SidebarSeparator"] > span {
  display: none;
}
.webix_sidebar .webix_tree_branch_1 > .webix_tree_item span:first-child {
  margin-right: 13px;
  font-size: 20px;
}
.webix_sidebar .webix_tree_branch_1 > .webix_tree_item span:nth-child(2) {
  font-size: 16px;
  margin-left: 1px;
}
.webix_sidebar .webix_tree_branch_1 > .webix_tree_item span:last-child {
  /*color: #354866;*/
  font-size: 14px;
  margin-left: 6px;
}
.webix_sidebar .webix_tree_branch_1{
  border-bottom:none;
}
/*.webix_tree_branch_1 .webix_tree_branch_2 div[webix_tm_id="DpiDscpConfig"],*/
/*.webix_tree_branch_1 .webix_tree_branch_2 div[webix_tm_id="QoEStorConfig"] {*/
/*  display: inline-block;*/
/*}*/
/*.webix_tree_branch_1 .webix_tree_branch_2 div[webix_tm_id="DpiDscpConfig"] {*/
/*  width: 240px;*/
/*}*/
/*.webix_tree_branch_1 .webix_tree_branch_2 div[webix_tm_id="FastPcrfConfig"],*/
/*.webix_tree_branch_1 .webix_tree_branch_2 div[webix_tm_id="QoEStorConfig"] {*/
/*  line-height: 18px;*/
/*  padding-right: 18px;*/
/*}*/
.webix_sidebar .webix_tree_branch_2 .webix_tree_item{
  color: #E6E6E6;
  padding-left: 12px;
  margin: 4px 15px 0 28px;
  border-radius: 5px;
  font-size: 12px;
}
.webix_sidebar .webix_tree_branch_2 .webix_tree_item span {
  white-space: break-spaces;
}
.webix_sidebar .webix_tree_branch_2 .webix_tree_item span {
  color: #E6E6E6;
}
.webix_sidebar .webix_tree_branch_2 .webix_selected span {
   color: #2e2e38;
 }
.webix_sidebar .webix_tree_item.webix_selected span:not([class])
{
  margin:0;
  padding:0px;
  font-weight:bold;
  /* text-transform:uppercase; */
  margin-left: -6px;
}
.webix_sidebar_icon{
  width: 40px;
  text-align: center;
  font-weight: lighter;
}

.webix_sidebar_dir_icon{
  float: right;
  line-height: inherit;
}
/*SubMenu (Popup) */
.webix_sidebar_popup{
  border:none !important;
  box-shadow: 2px 3px 3px #ddd;
  height: 55px!important;
}
.webix_sidebar_popup .webix_view.webix_sidebar_popup_title
{
  height: 55px!important;
  line-height:54px!important;
}
.webix_sidebar_popup .webix_view.webix_layout_line
{
  height: 55px!important;
  line-height:54px!important;
}
.webix_sidebar_popup, .webix_sidebar_popup .webix_list_item{
  border-radius:0;
}
.webix_sidebar_popup_right{
  box-shadow: -1px 3px 3px #ddd;
}
/*SubMenu: title*/
.webix_sidebar_popup_title{
  background-color: #606062;
  color: #f6f6f6;
}
.webix_sidebar_popup_title.webix_selected{
  border-left-color: #f6f6f6;
  background: #f6f6f6;
  color: #2e2e38;
}
.webix_sidebar_popup_title .webix_template{
  line-height: 54px;
  text-transform:uppercase;
  font-weight:bold;
  padding: 0 10px;
  border: 1px solid #E5E5E5;
  border-left:none;
}
.webix_sidebar_selected.webix_sidebar_popup_title .webix_template{
  background: rgba(0,0,0,0.03);
  border-left: none;
}
.webix_sidebar_popup_list .webix_list_item{
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
}
/*SubMenu: list*/
.webix_sidebar_popup_list .webix_list_item:first-child{
  border-top: 1px solid #E5E5E5;
}
.webix_sidebar_popup_list .webix_list_item:hover{
  background: #f6f9fb;
}

.webix_sidebar_popup_list .webix_list_item.webix_selected:hover{
  background: #f6f6f6;
}
/* </editor-fold desc="@group sidebar"> */

/* <editor-fold desc="@group app_top_header"> */
.app_title
{
  font-size: 15px;
}
.app_top_header.webix_toolbar .webix_el_label
{
  /* padding-left: 13px; */
}
.app_top_header
{
  background: rgba(0, 0, 0, 0) linear-gradient(0deg, rgb(24, 22, 25) 0%, rgb(61, 61, 61) 100%) repeat scroll 0 0;
  color: #e3dcd6;
  font-size: 15px;
}
.app_top_header .webix_spacer
{
  background:transparent;
}
.webix_layout_toolbar.webix_toolbar.app_top_header
{
  background: rgba(0, 0, 0, 0) linear-gradient(0deg, rgb(24, 22, 25) 0%, rgb(61, 61, 61) 100%) repeat scroll 0 0;
  color: #e3dcd6;
  font-size: 15px;
  border-bottom: none;
}
.app_top_header .webix_scroll_cont {
  padding-left: 8px;
}
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_el_button,
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_el_label,
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_inp_label
{
  color: #e3dcd6;
}
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_img_btn_top .webix_img_btn_text,
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_img_btn_top .webix_icon,
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_el_icon,
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_img_btn,
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_icon_btn,
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_icon_button
{
  color: #e3dcd6;
}
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_img_btn:hover > span,
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_img_btn_top:hover > span,
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_img_btn:focus > span,
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_img_btn_top:focus > span
{
  color: #2e2e38;
}
.webix_layout_toolbar.webix_toolbar.app_top_header .webix_el_label
{
  font-size: 15px;
}
.app_top_header .webix_icon_btn
{
  color: #e3dcd6;
}
/* </editor-fold desc="@group app_top_header"> */

/* <editor-fold desc="@group app_top_menu"> */
.app_top_menu
{
  padding-top:6px !important;
}

.app_top_menu.webix_menu-x
{
  background: rgba(0, 0, 0, 0) linear-gradient(0deg, rgb(24, 22, 25) 0%, rgb(61, 61, 61) 100%) repeat scroll 0 0;
  color: #e3dcd6;
/*   background : linear-gradient(0deg, rgba(0, 104, 158, 1) 0%, rgba(5, 133, 188, 1) 100%); */
}
.app_top_menu .top_root_item .webix_icon
{
  font-size:24px;
  color:#e3dcd6;
}
.app_top_menu .top_root_item .webix_submenu_icon
{
  top:3px;

  border:none;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  color:#e3dcd6;

}
.app_top_menu .top_root_item .webix_submenu_icon:before 
{
  content: "\f107";
}
.app_top_menu.webix_menu-x .webix_list_item
{
/*   border-right: 1px solid #0069a0; */
  border-left: 0px;
  background: transparent;
  color: #fff;
  font-size: 12px;
  font-weight:bold;
  display: inline-block;
  line-height: 35px;
  padding: 0 16px;
}
.app_top_menu.webix_menu-x .webix_list_item:first-child
{
  padding-left: 13px;
}
.app_top_menu.webix_menu-x .webix_list_item:first-child,
.app_top_menu.webix_menu-x .webix_list_item:last-child
{
  border-radius: 0;
}
.app_top_menu.webix_menu-x .webix_list_item:hover,
.app_top_menu.webix_menu-x .webix_list_item:active,
.app_top_menu.webix_menu-x .webix_list_item:focus
{
  background-color: #f6f6f6;
  color:#2e2e2e;
}
.app_top_menu.webix_menu-x .top_root_item:hover,
.app_top_menu.webix_menu-x .top_root_item:active,
.app_top_menu.webix_menu-x .top_root_item:focus
{
  background-color: transparent;
}
.app_top_menu.webix_menu-x .webix_list_item.webix_selected
{
  background-color: #f6f6f6;
  color:#2e2e2e;
}
.app_top_menu.webix_menu-x .webix_submenu_icon,
.app_top_menu.webix_view.webix_menu .webix_submenu_icon
{
  margin-top: 4px;
}
.app_top_submenu.webix_menu .webix_list_item:hover,
.app_top_submenu.webix_menu-x .webix_list_item:hover,
.app_top_submenu.webix_menu .webix_list_item:active,
.app_top_submenu.webix_menu-x .webix_list_item:active,
.app_top_submenu.webix_menu .webix_list_item:focus,
.app_top_submenu.webix_menu-x .webix_list_item:focus
{
  background-color: #7393bb;
  color: #ffffff;
}
.app_top_submenu .webix_list_item
{
  color:#354866;
}
.app_top_submenu .webix_point_top
{
  display:none;
}
/* </editor-fold desc="@group app_top_menu"> */

/* <editor-fold desc="@group app_view_title"> */
.app_view_title .webix_template
{
  background-color: #f6f6f6;
  padding: 0;
  color: #354866;
  font-size: 26px;
  white-space:nowrap;
}
.app_view_title .webix_template > div
{
  border-bottom: 1px solid #cdcdcd;
  height: 48px;
}
.app_view_title .app_view_title__highlight
{
  color:#354866;
}
/* </editor-fold desc="@group app_view_title"> */

/* <editor-fold desc="@group app_chart_block"> */
.app_chart_block__header
{
  background-color: #f1f1f1;
  font-size: 14px;
  color: #2e2e38;
  line-height: 23px;
}
.app_chart_block__header > .webix_template
{
  padding: 0;
  padding-left: 8px;
  line-height: 24px;
  font-weight: bold;
}
.app_chart_block__header_title
{
  margin-left: 8px;
  font-size: 14px;
  line-height: 23px;
  color: #2e2e38;
}
/* </editor-fold desc="@group app_chart_block"> */

/*<editor-fold desc="@group bread_crumbs_header">*/
.bread_crumbs_header {
  position:relative;
  z-index:102;
  box-shadow: 2px 2px 4px rgba(8, 16, 31, .15);
  background: rgb(61,61,61);
}
.bread_crumbs_header .webix_scroll_cont {
  display:flex;
  align-items:center;
  justify-content:flex-start;
  height:100%;
}
/*</editor-fold desc="@group bread_crumbs_header">*/

/*<editor-fold desc="@group top_icons">*/
.top_icons_container,
.top_icons {
  width: auto !important;
  max-height:32px;
}
.top_icons_container {
  margin:0 24px 0 auto!important;
}
.top_icons_container .webix_view {
  background-color: transparent;
}
.top_icons {
  display:grid;
  box-sizing: border-box;
  grid-template-rows: 1fr;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-items: center;
  align-items: center;
  grid-column-gap:8px;
}
/*</editor-fold desc="@group top_icons">*/

/*<editor-fold desc="@group top_icons header_lang"> */
.top_icons .header_lang {
  max-height:22px;
  padding-right:8px;
}
.top_icons .header_lang__wrapper {
  display:inline-block;
  box-sizing:border-box;
  width:100%;
  height:100%;
  border-radius: 50%;
  border:1px solid #f6f6f6;
}
.top_icons .header_lang .lang {
  display:block;
  box-sizing:border-box;
  width:100%;
  height:100%;
  border-radius: 50%;
  object-fit: cover;
  padding:2px;
}
.top_icons .header_lang .webix_template {
  line-height: 0;
}
/*</editor-fold desc="@group top_icons header_lang"> */

/* <editor-fold desc="@group app_button"> */
.app_button,
.app_button .webix_el_box {
  max-height:24px;
}
.app_button button {
  font-family: inherit;
}
.app_button .webix_el_box {
  padding: 0;
  overflow:visible;
}
.app_button .webix_img_btn {
  line-height:0 !important;
  text-align: center;
}
.app_button_bell {
  background:url("/assets/imgs/icons/bell_vulkan.svg") no-repeat center center !important;
}
.app_button_envelope {
  background:url("/assets/imgs/icons/envelope_vulkan.svg") no-repeat center center !important;
}
.app_button_bug {
  background:url("/assets/imgs/icons/bug_vulkan.svg") no-repeat center center !important;
}
/*</editor-fold desc="@group app_button">*/

/*<editor-fold desc="@group socket_notif">*/
.socket_notif .webix_el_box button .webix_badge {
  display:grid;
  grid-template:1fr/minmax(max-content,max-content);
  justify-items: center;
  background-color: transparent !important;
  left:12px;
  top:0;
  height:12px;
  padding:0;
}
.socket_notif .webix_el_box button .webix_badge .badge_inner {
  background-color: #606062;
  color: #f6f6f6;
  min-width:12px;
  box-sizing:border-box;
  padding: 0 2px;
  letter-spacing: -0.3px;
  border-radius:12px;
  font-size:9px;
  font-weight:normal;
  line-height: 12px;
}
/*</editor-fold desc="@group socket_notif">*/

/* <editor-fold desc="@group header_person"> */
.header_person {
  padding-left:8px;
  max-height: 32px;
  margin:0!important;
}
.app_top_header.vas-cloud_top-header .header_person {
  padding-left:0px;
  background:transparent;
}
.header_person .webix_template {
  padding: 0;
  line-height: 0px;
  wrap:nowrap;
  white-space:nowrap;
  width:100%;
}
.header_person span {
  padding-right: 8px;
  float:right;
  overflow:visible;
}
.header_person .avatar {
  color: #ffffff;
  font-size:12px;
  box-sizing:border-box;
  width:32px;
  height:32px;
  border-radius: 50%;
  border:0px solid #eaeef5;
  background-color:#c2cad9;
  padding:0px;
  text-align:center;
  line-height:33px;
  margin-top:0px;
  display:block;
  letter-spacing: -0.4px;
}
.header_person span.name {
  padding-left:8px;
  max-width:50%;
  overflow:hidden;
  text-overflow: ellipsis;
}
.header_person .webix_icon {
  line-height: inherit;
}
/* </editor-fold desc="@group header_person"> */

/*<editor-fold desc="@group bread_crumbs">*/
.bread_crumbs_container,
.crumb_container_wrapper,
.crumb_container,
.crumb_container .webix_el_box {
  width:auto !important;
  box-sizing: border-box;
}
.bread_crumbs_container {
  display:flex!important;
  align-items: center;
  margin-left:16px !important;
  padding: 0px 8px;
  max-height:44px;
  overflow:visible;
}
.bread_crumbs_container .webix_view {
  background-color: transparent;
}
.crumb_container_wrapper {
  display:flex!important;
  justify-content: center;
  align-items: center;
  max-height: 30px;
  border-radius:4px;
  overflow:visible;
}
.crumb_container .webix_image {
  display:none;
}
.crumb_container,
.crumb_container .webix_el_box {
  padding:0;
  height:100%!important;
}
.crumb_container .webix_img_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height:16px !important;
  padding: 0 8px;
  background: transparent;
}
.crumb_container .webix_img_btn:hover {
  background: transparent;
}
.crumb_container__caption {
  color:#f6f6f6;
  font-weight: normal;
  font-style: normal;
  font-size:14px;
  letter-spacing: -0.1px;
  white-space:nowrap;
}
.crumb_container_wrapper:hover,
.crumb_container_select {
  background-color: #606062 !important;
}
.crumb_container_wrapper:hover .crumb_container__caption,
.crumb_container_select .crumb_container__caption {
  color:#f6f6f6;
}
.delimiter {
  width:auto!important;
  max-height: inherit;
  font-size: 12px;
}
.delimiter .webix_template {
  display:flex;
  align-items: center;
  justify-content: center;
}
.delimiter__item {
  color:rgba(246,246,246,.6);
  margin:0 6px 0 7px;
  padding:0;
}
/*</editor-fold desc="@group bread_crumbs">*/

/*<editor-fold desc="@group hw_switch">*/
.hw_switch,
.hw_switch_wrapper {
  overflow:visible;
  width: auto !important;
  height:100% !important;
}
.hw_switch_wrapper {
  display:flex!important;
  justify-content: center;
  align-items: center;
  position:relative;
  margin-left:12px !important;
}
.hw_switch {
  display:flex!important;
  justify-content: flex-start;
  align-items:center;
  box-sizing:border-box!important;
}
.hw_switch_icon {
  height: 100% !important;
  cursor: pointer;
}
.hw_switch__dpi_icon {
  width: 16px!important;
  color: white;
  font-size:16px;
  font-weight:lighter;
  padding-top:14px;
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hw_switch__action_menu_icon,
.hw_switch__action_menu_icon .webix_icon {
  color: #f6f6f6;
  width: auto !important;
  background: transparent !important;
  margin: 0 !important;
}
.hw_switch__action_menu_icon {
  display: flex !important;
  align-items: center;
}
.hw_switch__action_menu_icon .webix_template {
  height: auto !important;
}
.hw_switch__action_menu_icon .webix_icon {
  padding: 0 12px 0 0;
  font-size: 16px;
}
.hw_switch__label {
  padding:0!important;
  height:100%!important;
  z-index: 102;
}
.hw_switch__label::after {
  position:absolute;
  top:-4px;
  left:9px;
  display: inline-block;
  content:"";
  width:16px;
  height:16px;
  border-radius: 50%;
}
.hw_switch__tooltip {
  width: auto !important;
  height: auto !important;
  opacity: 0;
  position: absolute;
  left: 24px;
  top: 4px;
  max-width:0;
  max-height:0;
  border-radius:4px;
  visibility: hidden;
  z-index:102;
  pointer-events: none;
}
.hw_switch__tooltip .webix_template {
  background: rgb(35,35,35);
  color: #f6f6f6;
  font-size:12px;
  font-weight: normal;
  letter-spacing: -0.1px;
  white-space: nowrap;
}
.hw_switch_wrapper:hover .hw_switch__tooltip {
  max-height: none;
  max-width: none;
  visibility: visible;
  animation: tooltip_show;
  animation-duration: 0.3s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
.hw_switch_wrapper:hover .hw_switch__tooltip .webix_template {
  padding: 4px 8px;
}
.hw_switch .label_vas_color::after {
  background-color:#2998f0;
}
.hw_switch .label_warning_color::after {
  background-color:#ff9800;
}
.hw_switch .label_error_color::after {
  background-color:#fc0033;
}
@keyframes tooltip_show {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@-webkit-keyframes tooltip_show {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@-moz-keyframes tooltip_show {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@-ms-keyframes tooltip_show {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@-o-keyframes tooltip_show {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
/*</editor-fold desc="@group hw_switch">*/

/*<editor-fold desc="@group app_header__loading_indicator">*/
.app_header__loading_indicator {
  margin-top:-2px!important;
}
.loading .indicator {
  width:0%;
  height:2px;
  position:relative;
  z-index:102;
  background:#1A84D8;
  animation: loading 3.5s ease-in-out infinite;
  -webkit-animation: loading 3.5s ease-in-out infinite;
  -moz-animation: loading 3.5s ease-in-out infinite;
  -ms-animation: loading 3.5s ease-in-out infinite;
  -o-animation: loading 3.5s ease-in-out infinite;
}
@keyframes loading {
  to {
    width:100%;
  }
}
@-webkit-keyframes loading {
  to {
    width:100%;
  }
}
@-moz-keyframes loading {
  to {
    width:100%;
  }
}
@-ms-keyframes loading {
  to {
    width:100%;
  }
}
@-o-keyframes loading {
  to {
    width:100%;
  }
}
/*</editor-fold desc="@group app_header__loading_indicator">*/

/*<editor-fold desc="@group app_header__border_bottom">*/
.app_header__border_bottom {
  background-color: #1A84D8;
  margin-top:-2px !important;
}
.app_header__border_bottom .break {
  position:absolute;
  background:#fff;
  width:6px;
  height:3px;
  display:none;
}
.app_header__border_bottom.loading .break {
  display:block;
}
.app_header__border_bottom .dot1 {
  animation: app_header_loading 4s infinite;
  -webkit-animation: app_header_loading 4s infinite;
  -moz-animation: app_header_loading 4s infinite;
  -ms-animation: app_header_loading 4s infinite;
  -o-animation: app_header_loading 4s infinite;
}
.app_header__border_bottom .dot2 {
  animation: app_header_loading 4s 1s infinite;
  -webkit-animation: app_header_loading 4s 1s infinite;
  -moz-animation: app_header_loading 4s 1s infinite;
  -ms-animation: app_header_loading 4s 1s infinite;
  -o-animation: app_header_loading 4s 1s infinite;
}
.app_header__border_bottom .dot3 {
  animation: app_header_loading 4s 2s infinite;
  -webkit-animation: app_header_loading 4s 2s infinite;
  -moz-animation: app_header_loading 4s 2s infinite;
  -ms-animation: app_header_loading 4s 2s infinite;
  -o-animation: app_header_loading 4s 2s infinite;
}
@keyframes app_header_loading {
  from {
    left: 0;
  }
  to {
    left: 50%;
  }
}
@-webkit-keyframes app_header_loading {
  from {
    left: 0;
  }
  to {
    left: 50%;
  }
}
@-moz-keyframes app_header_loading {
  from {
    left: 0;
  }
  to {
    left: 50%;
  }
}
@-ms-keyframes app_header_loading {
  from {
    left: 0;
  }
  to {
    left: 50%;
  }
}
@-o-keyframes app_header_loading {
  from {
    left: 0;
  }
  to {
    left: 50%;
  }
}
/*</editor-fold desc="@group app_header__border_bottom">*/

/*<editor-fold desc="@group bread_crumbs_popup">*/
.bread_crumbs_popup,
.bread_crumbs_popup_content_wrapper,
.bread_crumbs_popup_content_wrapper .webix_spacer,
.bread_crumbs_popup_content,
.bread_crumbs_popup__list,
.bread_crumbs_popup__list .webix_el_button,
.bread_crumbs_popup__list .webix_el_box {
  height:auto!important;
  transition: .2s ease;
}
.bread_crumbs_popup,
.bread_crumbs_popup .webix_win_content {
  padding:0 !important;
  max-height: 0;
  transition: max-height .3s ease;
}
.bread_crumbs_popup,
.bread_crumbs_popup .webix_win_content,
.bread_crumbs_popup .webix_win_body,
.bread_crumbs_popup .webix_win_body > .webix_view {
  background-color:#606062;
  border-radius:0;
}
.bread_crumbs_popup {
  display:block !important;
  margin: 0 0 0 0!important;
  box-shadow: 2px 2px 4px rgba(8, 16, 31, .15)!important;
  z-index:102!important;
  border:none!important;
}
.bread_crumbs_popup_content,
.crumb_chapter,
.crumb_list,
.bread_crumbs_popup__list .webix_el_button,
.bread_crumbs_popup__list .webix_el_box {
  box-sizing: border-box;
  max-width:200px;
}
.crumb_list {
  margin: 0 !important;
}
.bread_crumbs_popup_content_wrapper {
  padding: 16px 0 10px 0;
}
.bread_crumbs_popup_content .webix_view {
  background-color:transparent;
}
.bread_crumbs_popup__list .webix_image {
  display: none;
}
.bread_crumbs_popup__list .webix_el_button {
  margin-top: 8px!important;
}
.bread_crumbs_popup__list .webix_el_box {
  padding:0;
}
.bread_crumbs_popup__list .webix_img_btn {
  border-radius: 4px;
}
.bread_crumbs_popup__chapter {
  height:auto!important;
  padding: 8px 0 8px 8px;
  margin-bottom: -2px;
}
.crumb_chapter__item,
.crumb_list__item {
  color:#f6f6f6;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  white-space: normal;
}
.crumb_list__item {
  padding: 7px 0 7px 8px;
  border-radius: 4px;
}
.crumb_list__caption {
  color:#f6f6f6;
  text-decoration:none;
}
.bread_crumbs_popup__list .webix_img_btn:hover,
.bread_crumbs_popup__list .webix_img_btn_click,
.crumb_list__item_selected {
  background-color:#f6f6f6;
}
.bread_crumbs_popup__list .webix_img_btn:hover .crumb_list__caption,
.bread_crumbs_popup__list .webix_img_btn_click .crumb_list__caption,
.crumb_list__item_selected .crumb_list__caption,
.crumb_list__item_selected .fa-check {
  color:#2e2e38 !important;
}
.crumb_list__semi_transparent_background {
  position:absolute;
  width:0%;
  height:0%;
  top:0;
  left:0;
  background-image: linear-gradient(rgba(187,191,189,1),rgba(187,191,189,1));
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-position: 50% 0%;
}
.bread_crumbs_popup__list .webix_img_btn_click .crumb_list__semi_transparent_background {
  width:100%;
  height:100%;
  animation: background_animate .2s;
  -webkit-animation: background_animate .2s;
  -moz-animation: background_animate .2s;
  -ms-animation: background_animate .2s;
  -o-animation: background_animate .2s;
}
@keyframes background_animate {
  0%{
    opacity:1;
    background-size: 0% 100%;
  }
  50%{
    opacity:.1;
    background-size: 100% 100%;
  }
  100%{
    opacity:0
  }
}
@-webkit-keyframes background-animate {
  0%{
    opacity:1;
    background-size: 0% 100%;
  }
  50%{
    opacity:.1;
    background-size: 100% 100%;
  }
  100%{
    opacity:0
  }
}
@-moz-keyframes background-animate {
  0%{
    opacity:1;
    background-size: 0% 100%;
  }
  50%{
    opacity:.1;
    background-size: 100% 100%;
  }
  100%{
    opacity:0
  }
}
@-ms-keyframes background-animate {
  0%{
    opacity:1;
    background-size: 0% 100%;
  }
  50%{
    opacity:.1;
    background-size: 100% 100%;
  }
  100%{
    opacity:0
  }
}
@-o-keyframes background-animate {
  0%{
    opacity:1;
    background-size: 0% 100%;
  }
  50%{
    opacity:.1;
    background-size: 100% 100%;
  }
  100%{
    opacity:0
  }
}
.crumb_list__item_selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 8px 8px;
}
.crumb_list__item_selected .fa-check {
  margin-right:11.5px;
  padding-right:0;
  font-size: 12px;
}
.bread_crumbs_popup_show,
.bread_crumbs_popup_show .webix_win_content {
  max-height: 100%;
}
/*</editor-fold desc="@group bread_crumbs_popup">*/

/* <editor-fold desc="@group app_block"> */
.app_block__header
{
  background-color: #f1f1f1;
  font-size: 14px;
  line-height: 23px;
  color: #2e2e38;
}
.panel_drag_view .app_block__header
{
  cursor:move;
}
.app_block__header > .webix_template
{
  padding: 0;
  padding-left: 8px;
  line-height: 24px;
  /* font-weight: bold; */
}
.app_block__header_title
{
  margin-left: 8px;
}
/* </editor-fold desc="@group app_chart_block"> */

/* <editor-fold desc="@group progress"> */
.progress_result {
  height: 100%;
  float: right;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,0.15);
  background: #7393bb;
}
.progress_bar_element {
  background-color: #f5f5f5;
  border-radius: 4px;
  width: 150px;
  height: 6px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  margin-top: 16px;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
}
/* </editor-fold desc="@group progress"> */

/* <editor-fold desc="@group app_top_header"> */
.app_logo .webix_template
{
  padding: 8px 10px 16px 16px;
  background-color: #606062;
}
.app_logo img
{
  /*height:24px;*/
}
.app_top_header.webix_view
{
  border-color: white;
}
.app_top_header.webix_layout_toolbar.webix_toolbar
{
  color: #444444;
  font-size: 18px;
  background-color: white;
  border-color: white;
}
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_el_button,
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_el_label,
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_inp_label
{
  color: #444444;
}
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_img_btn_top .webix_img_btn_text,
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_img_btn_top .webix_icon,
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_el_icon,
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_img_btn,
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_icon_btn,
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_icon_button
{
  color: #444444;
}
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_img_btn:hover,
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_img_btn_top:hover,
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_icon_btn:hover,
.app_top_header.webix_layout_toolbar.webix_toolbar .webix_icon_button:hover
{
  background-color: #f7f6fa;
  color:white;
}
.webix_badge
{
  background-color: #e75218;
  color: #ffffff;
}
.app_top_menu .webix_list_item .webix_icon.fa-shopping-bag,
.webix_menu.webix_view .webix_icon.fa-shopping-bag,
.app_top_header .webix_img_btn .webix_icon_btn.fa-shopping-bag,
.app_top_header .webix_img_btn:hover .webix_icon_btn.fa-shopping-bag
{
  color: #e75218;
}
.app_top_menu .webix_list_item.webix_selected
{
  color: white;
  background-color: #1A84D8;
}
.app_top_menu .webix_list_item.webix_selected .webix_submenu_icon
{
  border-color: white transparent transparent transparent;
}
.app_top_menu .webix_list_item.webix_selected .webix_icon.fa-shopping-bag
{
  color: white;
}

.app_top_version
{
  padding: 0 16px;
  color:#e3dcd6;
/*   background : linear-gradient(0deg, rgba(0, 104, 158, 1) 0%, rgba(5, 133, 188, 1) 100%); */
  vertical-align:top;
  display:inline-block;
  padding-top:2px;
}
.app_top_version.label_vas_color .webix_icon
{
  color:white;
}
/* </editor-fold desc="@group app_top_header"> */

/* <editor-fold desc="@group header_dpi"> */
.header_dpi{
  background: transparent !important;
}
.header_dpi .webix_template{
  padding: 0;
  line-height: 46px;
}
.header_dpi span,
.header_dpi-span
{
  padding-left: 7px;
}
.header_dpi .splitter,
.header_dpi-splitter
{
  font-size:12px;
  font-weight:bold;
  vertical-align:top;
}
.header_dpi .webix_icon
{
  line-height: 44px;
  vertical-align:middle;
}
.header_dpi .webix_icon.fa-exclamation-triangle,
.header_dpi .dpi_notconnected
{
  color:#ff7043;
  padding:0 !important;
}
.header_dpi .app_title,
.header_dpi-app_title
{
  color:#1A84D8;
  font-size:15px;
  padding-left:0;
}
.header_dpi .dpi_title,
.header_dpi-dpi_title
{
  font-size:15px;
}

.header_dpi .many_messages_error
{
  cursor: pointer;
}
.header_dpi .many_messages_error .many_messages_block
{
  position: absolute;
  background-color: white;
  z-index: 10;
  margin-top: 40px;
  padding: 6px 14px;
  border: 1px solid red;
  display: none;
  color: black;
}
.header_dpi .many_messages_error:hover .many_messages_block
{
  display: inline-block;
}

/* </editor-fold desc="@group header_dpi"> */

/* <editor-fold desc="@group app_title_header"> */
.app_title_header{
  background: transparent !important;
}
.app_title_header .webix_template
{
  padding: 0;
  line-height: 36px;
}
.app_title_header span
{
  padding-left: 7px;
  color:#fff;
}
.app_title_header .splitter
{
  font-size:12px;
  font-weight:bold;
  vertical-align:top;
}
.app_title_header .webix_icon
{
  line-height: inherit;
}
.app_title_header .webix_icon.fa-exclamation-triangle,
.app_title_header .dpi_notconnected
{
  color:#ff7043;
  padding:0 !important;
}
.app_title_header .app_title
{
  color:#e3dcd6;
  font-size: 15px;
  padding-left:0;
  font-weight:bold;
}
.app_title_header .dpi_title
{
  color:#e3dcd6;
  font-size: 15px;
}
/* </editor-fold desc="@group app_title_header"> */

/* <editor-fold desc="@group header_person"> */
/*.header_person{
  background: transparent !important;
  color:#e3dcd6;
}
.header_person .webix_template{
  padding: 0;
  line-height: 36px;
  wrap:nowrap;
  white-space:nowrap;
  width:100%;
}
.header_person span
{
  padding-right: 8px;
  float:right;
  overflow:hidden;
}
.header_person .avatar
{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border:1px solid #eaeef5;
  padding:0;
  text-align:center;
  line-height:inherit;
  vertical-align:middle;
  display:block;
  font-size:12px;
  margin-top:8px;
  line-height:19px;
  box-sizing:border-box;
}
.header_person span.name
{
  padding-left:8px;
  max-width:50%;
  overflow:hidden;
  text-overflow: ellipsis;
  font-size:15px;
}
.header_person .webix_icon{
  line-height: inherit;
}*/
/* </editor-fold desc="@group header_person"> */

/* <editor-fold desc="@group header_lang"> */
.header_lang{
  background: transparent !important;
  color:#e3dcd6;
}
.header_lang.login
{
  color:#2f3438;
}
.header_lang .webix_template{
  padding: 0;
  line-height: 36px;
}
.header_lang .lang
{
  width:16px;
  vertical-align:top;
  padding-top:12px;
}
.header_lang span
{
  padding-left: 7px;
}
.header_lang .webix_icon{
  line-height: inherit;
}
/* </editor-fold desc="@group header_lang"> */

/* <editor-fold desc="@group toolbar_round_btn"> */
.toolbar_round_btn button.webix_img_btn
{
  border-radius: 4px;
  border: 1px solid white;
  line-height: 0 !important;
  text-align: center;
}

.toolbar_round_btn button.webix_img_btn .webix_icon_btn
{
  padding: 0;
  color: #2b303a;
}

.toolbar_round_btn button.webix_img_btn:hover
{
  background-color: rgba(41, 182, 246, 0.2);
  border-color: rgba(41, 182, 246, 0.2);

}

.toolbar_round_btn button.webix_img_btn:hover .webix_icon_btn
{

}
/* </editor-fold desc="@group toolbar_round_btn"> */

/* <editor-fold desc="@group Data templates"> */
.webix_cell .services .service
{
  margin-right: 8px;
}
.webix_cell .services
{
  line-height: 20px;
  position: relative;
  white-space:normal;
}
.webix_cell .services .service > .title
{
  color: #2b303a;
  /*   text-decoration: underline;
    cursor: pointer; */
}
.webix_cell .services .service.black_list > .fa
{
  color: #168073;
}
.webix_cell .services .service.ddos_guard > .fa
{
  color: #ff8a65;
}
.webix_cell .services .service.bandwidth > .fa
{
  color: #ffcc80;
}
.webix_cell .services .service.bandwidth > .fa
{
  color: #ffcc80;
}
.webix_cell .services .service.cgnat > .fa
{
  color: #aed581;
}
.webix_cell .services .service.bonus > .fa
{
  color: #ba68c8;
}
.webix_cell .services .service.adv > .fa
{
  color: #7986cb;
}
.webix_cell .services .service.adv_block > .fa
{
  color: #4fc3f7;
}
.webix_cell .services .service.white_list > .fa
{
  color: #ff8a65;
}
.webix_cell .services .service.user_alert > .fa
{
  color: #7e8b9f;
}
.webix_cell .services .service.cash > .fa
{
  color: #ab47bc;
}
.webix_cell .services .service.ddos_pass > .fa
{
  color: #5c6bc0;
}
.webix_cell .services .service.netflow > .fa
{
  color: #1A84D8;
}
.webix_cell .services > .btn
{
  position: absolute;
  top: 8px;
  right: 0;
}
.webix_cell .titleDescr
{
  line-height: 20px;
  position: relative;
}
.webix_cell .titleDescr > .title
{
  font-size: 15px;
  color: #2b303a;
}
.webix_cell .titleDescr > .descr
{
  color: #949fb1;
}
.webix_cell .titleDescr > .btn
{
  position: absolute;
  top: 8px;
  right: 0;
}
.webix_cell .tariff > .title
{
  color: #7e8b9f;
  text-decoration: underline;
  cursor: pointer;
}
.webix_cell .tariff > .fa
{
  color: #168073;
}

.webix_cell .adv_state.adv_state_1 > .fa
{
  color: #949fb1;
}
.webix_cell .adv_state.adv_state_2 > .fa
{
  color: #ffcc80;
}
.webix_cell .adv_state.adv_state_3 > .fa
{
  color: #ff8a65;
}
.webix_cell .adv_state.adv_state_4 > .fa
{
  color: #aed581;
}
.webix_cell .adv_state.adv_state_5 > .fa
{
  color: #ff0000;
}

.webix_cell .bl_state.bl_target_0 > .fa
{
  color: #949fb1;
}

.webix_cell .bl_state.bl_target_1 > .fa
{
  color: #aed581;
}
.webix_cell .bl_state.bl_target_2 > .fa
{
  color: #ff0000;
}

.webix_cell .policing_state.policing_status_0 > .fa
{
  color: #aed581;
}
.webix_cell .policing_state.policing_status_4 > .fa
{
  color: #ff0000;
}
/* </editor-fold desc="@group Data templates"> */
/* <editor-fold desc="@group progress"> */
.progress_result {
  height: 100%;
  float: right;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,0.15);
  background: #1A84D8;
}
.progress_bar_element {
  width:56px;
  background-color: #f5f5f5;
  border-radius: 4px;
  height: 6px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  margin-top: 16px;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
}
/* </editor-fold desc="@group progress"> */

/* <editor-fold desc="@group profile"> */
.profile-person .webix_template
{
  padding: 10px;
}
.profile-person .webix_view
{
  background-color:#f6f6f6;
}
.profile-person__avatar-placeholder {
  background-color: #fff;
  border: 1px solid #0079a5;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.profile-person__avatar {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-align: center;
  line-height: 123px;
  font-size: 24px;
  color: #0079a5;
}
.profile-person__name
{
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}
.profile-person__position
{
  text-align: center;
  font-size: 14px;
  line-height: 32px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}
.profile-person__company
{
  text-align: center;
  font-size: 12px;
  line-height: 32px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}
.profile-person__phone
{
  font-size: 14px;
  line-height: 32px;
}
.profile-person__email
{
  font-size: 14px;
  line-height: 32px;
}
.profile-person__phone .webix_icon,
.profile-person__email .webix_icon
{
  color: #0079a5;
}
/* </editor-fold desc="@group profile"> */

.webix_dtable .status
{
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 3px;
}
.status_1,
.status_ok,
.status_green
{
  background-color: #6cbe39;
  color:#fff;
}
.status_3,
.status_4,
.status_1000,
.status_1001,
.status_err,
.status_red
{
  background-color: #ca2807;
  color:#fff;
}
.status_0,
.status_2,
.status_orange
{
  background-color: #fb7c1b;
  color:#fff;
}
.spinner {
  display: inline-block;
}

.spinner > div
{
  width: 6px;
  height: 6px;
  background-color: #fb7c1b;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner.green > div
{
  background-color: #6cbe39;
}

.spinner .bounce1
{
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2
{
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay
{
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

.webix_message_area .webix_info {
  background: #ffffff;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.webix_message_area .webix_info div {
  padding: 10px 16px 12px 16px;
  border: none;
  background: none;
  color: #959fb0;
}
.webix_message_area .webix_debug {
  background: #fb7c1b;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.webix_message_area .webix_debug div {
  padding: 10px 16px 12px 16px;
  border: none;
  background: none;
  color: #ffffff;
}
.webix_message_area .webix_error {
  background: #ca2807;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.webix_message_area .webix_error div {
  padding: 10px 16px 12px 16px;
  border: none;
  background: none;
  color: #ffffff;
}
.webix_message_area p {
  margin: 10px 0 0 0;
}

.health_process
{
  background-color: #ffcc80;
  color:#fff;
}

.health_ok
{
  background-color: #aed581;
  color:#fff;
}

.health_fail
{
  background-color: #ff8a65;
  color:#fff;
}

.webix_view.webix_control.webix_el_tabbar
{
  background-color:#f6f6f6;
}

/* <editor-fold desc="@group id_dna"> */
.webix_view.if_dna
{

}
.webix_view.if_dna . webix_template
{

}
.webix_view.if_dna .if_dna_in,
.webix_view.if_dna .if_dna_out
{
  width:100%;
  height:100%;
  position: relative;
}
.webix_view.if_dna .if_dna_card
{
  width:100%;
  height:100%;
  position: absolute;
  background:url("/assets/imgs/icons/if_card.svg") no-repeat center center;
}
.webix_view.if_dna .if_dna_title
{
  display: inline-block;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}
.webix_view.if_dna .if_dna_in:before,
.webix_view.if_dna .if_dna_out:before
{
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.webix_view.if_dna .if_dna_connector_bottom
{
  width: 40px;
  height: 40px;
  background:url("/assets/imgs/icons/if_connector.svg");
  position: absolute;
  bottom: -0px;
  left:50%;
  margin-left: -20px;
  transform: rotate(90deg);
}
.webix_view.if_dna .if_dna_in .if_dna_connector_bottom
{
  bottom: -20px;
}
.webix_view.if_dna .if_dna_out .if_dna_connector_top
{
  top: -20px;
}
.webix_view.if_dna .if_dna_connector_top
{
  width: 40px;
  height: 40px;
  background:url("/assets/imgs/icons/if_connector.svg");
  position: absolute;
  top: -0px;
  left:50%;
  margin-left: -20px;
  transform: rotate(-90deg);
}
/* </editor-fold desc="@group id_dna"> */

.qoe_single_stat
{
  position: relative;
  box-sizing:border-box;
  height:100%;
}

.qoe_single_stat .icon
{
  position: absolute;
  margin-top:-40px;
  top: 50%;
  left: 8px;
  display: block;
  font-size: 80px;
  color: rgba(41, 182, 246, 0.05);
}
.qoe_single_stat .details
{
  position: absolute;
  margin-top:-40px;
  top: 50%;
  right: 8px;
  height:80px;
}
.qoe_single_stat .value
{
  font-size: 27px;
  text-align: right;
}
.qoe_single_stat .text
{
  width: 100%;
  font-size: 18px;
  text-align: right;
}
.qoe_single_stat.compact .icon
{
  font-size:40px;
  margin-top:-20px;
}
.qoe_single_stat.compact .details
{
  height:40px;
  margin-top:-20px;
}
.qoe_single_stat.compact .value
{
  font-size:21px;
}
.qoe_single_stat.compact .text
{
  font-size:13px;
}
.dragable{cursor:move;}
.portlet_drag{position:absolute;z-index:1;top:5px;right:5px;width:18px;opacity:.5;cursor:pointer}
.portlet_in_drag{opacity:.4}
.portlet_marker,.portlet_markerbottom,.portlet_markerleft,.portlet_markerright,.portlet_markertop{opacity:.5;background:#9169BE;position:absolute;z-index:2;transition:top .5s,left .5s,width .5s,height .5s;top:0;left:0;width:100%;height:100%}
.portlet_markertop{height:50%}
.portlet_markerbottom{height:50%;top:50%}
.portlet_markerleft{width:50%}
.portlet_markerright{width:50%;left:50%}
.panel_icon{position:absolute;z-index:100;top:5px;right:5px;line-height:normal;font-size:13px;width:18px;opacity:.5;cursor:pointer;}
.panel_target{position:absolute;background:rgba(41, 182, 246, 0.1);z-index:3}

.hcell_ckeckbox input[type="checkbox"]
{
  margin:0;
}

.shop_dataview_item
{
  padding:16px 8px;
  text-align:center;
}
.shop_dataview_item__icon .webix_icon
{
  font-size:64px;
  padding:0;
  margin:0;
  display:inline;
  color:#1A84D8;
}
.shop_dataview_item__title
{
  font-size:20px;
  margin-top:16px;
}
.shop_dataview_item__period
{
  margin-top:16px;
}
.shop_dataview_item__period span
{
  border:1px solid #eaeef5;
  margin:0 2px;
  padding:0 8px;
  white-space:nowrap;
}
.shop_dataview_item__period span.selected,
.shop_dataview_item__period.selected_1 span:nth-child(1),
.shop_dataview_item__period.selected_3 span:nth-child(2),
.shop_dataview_item__period.selected_6 span:nth-child(3),
.shop_dataview_item__period.selected_12 span:nth-child(4)
{
  background-color:#eaeef5;
  color:#1A84D8;
}
.shop_dataview_item__old_price
{
  margin-top:8px;
  text-decoration:line-through;
}
.shop_dataview_item__new_price
{
  margin-top:8px;
  font-size:20px;
  color:#1A84D8;
}
.shop_dataview_item__subscribe_btn
{
  height:34px;
  background-color:#1A84D8;
  color:#fff;
  line-height:33px;
  margin-top:16px;
}
.shop_dataview_item__more_btn
{
  margin-top:8px;
  text-decoration:underline;
}
.shop_dataview_item__device
{
  margin-top:8px;
  border-bottom: 1px dashed #1A84D8;
  display:inline-block;
  position:relative;
}
.shop_dataview_item__device:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 12px;
  position: absolute;
  top: 50%;
  margin-top: -2px;
  border: 5px solid transparent;
  border-top: 5px solid #000;
}

.app_view_title_shop_licence
{
  font-size:11px;
  text-transform:none;
  margin-left:32px;
}

.app_view_title_shop_licence .webix_icon
{
    line-height: inherit;
    margin-left:7px;
}

.app_view_title_shop_licence_state
{
  text-transform:uppercase;
  /*font-size:15px;*/
}

.app_view_title_shop_licence_state__active
{
  color:#4db6ac ;
}

.app_view_title_shop_licence_state__active_expire
{
  color:#ffb74d  ;
}

.app_view_title_shop_licence_state__no_active
{
  color:#ff7043;
}

/* <editor-fold desc="@group vas-cloud_top-header"> */
.vas-cloud_top-header.app_top_header
{
  background:#f6f6f6;
}
.vas-cloud_top-header.webix_view
{
  border-color: #f6f6f6;
  border-left-color: #f6f6f6;
}

.vas-cloud_top-header .header_person span,
.vas-cloud_top-header .header_wallet span,
.vas-cloud_top-header .header_wallet .webix_template
{
  line-height: 32px;
}

.vas-cloud_top-header .header_person .avatar
{
  margin-top: 0;
  width: 32px;
  height: 32px;
  line-height: 30px;
  border-color:#354866;
}

.vas-cloud_top-header .header_person span
{
  float:right;
  color:#354866;
}

.header_wallet{
  background: transparent !important;
}
.header_wallet span
{
  float:right;
}
.header_wallet .webix_template{
  padding: 0;
  line-height: 32px;
}
.header_wallet .lang
{
  width:16px;
}
.header_wallet span
{
  padding-left: 8px;
}
.header_wallet .webix_icon
{
  line-height: inherit;
}
.header_wallet .webix_icon.fa-wallet
{
  padding: 0;
  margin: 0;
}
/* </editor-fold desc="@group vas-cloud_top-header> */

.webix_switch_box {
  color: #475466;
  background-color: #F4F5F9;
}
.webix_switch_box.webix_switch_on {
  background-color: #1CA1C1;
}
.webix_switch_box .webix_switch_text {
  padding-top: 4px;
}
.webix_switch_toggle {
  position: absolute;
  visibility: hidden;
}
.webix_switch_box {
  display: block;
  position: relative;
  top: 50%;
  margin-top: -12px;
  height: 24px;
  cursor: pointer;
  background-color: #f1f1f1;
  border: 1px solid #DADEE0;
  border-radius: 60px;
  box-sizing: border-box;
  transition: background-color 0.4s ease;
  color: #666;
  text-align: center;
  float: left;
}
.webix_el_switch .webix_label_right {
  display: inline-block;
  padding-top: 3px;
  margin-top: 4px;
}
.webix_switch_handle {
  height: 22px;
  width: 22px;
  border-radius: 100%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #eee;
  position: absolute;
  top: 0px;
  background-color: #fff;
  transition: left 0.3s ease;
  cursor: pointer;
  box-sizing: border-box;
}
.webix_switch_handle:focus,
.webix_switch_box:active .webix_switch_handle {
  box-shadow: 0 0px 2px 2px rgba(0, 0, 0, 0.2);
}
.webix_switch_text {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 1px 0 0 18px;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}
.webix_switch_on .webix_switch_text {
  padding: 4px 18px 0 0;
}
.webix_switch_box.webix_switch_on {
  background-color: #1A84D8;
  color: #fff;
}

.webix_dtable .status
{
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 3px;
}
.status_0
{
  background:none;
}
.status_1,
.status_2,
.status_orange
{
  background-color: #ffcc80;
  color: white;
}
.status_3,
.status_ok,
.status_green,
.status_up
{
background-color: #aed581;
color: white;
}
.status_4,
.status_5,
.status_1000,
.status_1001,
.status_err,
.status_red,
.status_down
{
  background-color:#ff7043;
  color: #fff;
}
.spinner
{
  display: inline-block;
}

.spinner > div
{
  width: 6px;
  height: 6px;
  background-color: #1A84D8;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner.green > div
{
  background-color: #aed581;
}

.spinner .bounce1
{
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2
{
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay
{
0%, 80%, 100% { -webkit-transform: scale(0) }
40% { -webkit-transform: scale(1.0) }
}

@keyframes blink {
	0% {opacity: 0}
	49%{opacity: 0}
	50% {opacity: 1}
}

.blink{animation: blink 1s infinite;}

.webix_view .webix_layout_form input:read-only
{
  color: rgba(43, 48, 58, 0.7);
  font-weight: bold;
  border-bottom: 1px dashed #c7d2e5;
  background: #ffffff;
}

[view_id=VasCloudLoginViewContext].webix_popup.webix_view
{
  padding:0;
}

.vc_login-form__header .webix_template
{
  background-color:#eaeef5;
  padding:0 32px;
  text-align:center;
  word-break:normal;
  display:block;
  width:100%
}

.vc_logo
{
  font-size:24px;
  line-height:40px;
  vertical-align:middle;
  margin:16px 0;
  display:inline-block;
}

.vc_logo img,
.vc_logo span
{
  vertical-align:middle;
  margin:0 4px;
}

.multiline-header{
  line-height: 20px !important;
  position: relative;
  top: 24px;
  text-align: center;
}

.progress_result_red
{
  background: rgb(255, 30, 0);
  float:left;
}

.progress_result_green
{
  background: #aed581;
  float:left;
}

.progress_result_yellow
{
  background: #F2C94C;
  float:left;
}

.notifications-list .webix_list_item
{
  margin-bottom:8px;
}

.notifications-list .webix_list_item
{
  background-color: inherit;
  /* padding: 0px 16px; */
  border: none;
  cursor: default;
  /*pointer-events: none;*/
}

.notifications-list .webix_list_item.webix_selected
{
  background-color: #ffffff;
}


.websocket_notification
{
  background: inherit;;
  /*background-color: rgba(41, 182, 246, 0.2);*/
  color: #2f2f2f;
  cursor: pointer;
  border-radius: .5em;
  overflow: hidden;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: -webkit-transform 0.75s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0.75s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0.75s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.75s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  height: 96px;
  padding: 8px;
}

.notifications-list .webix_scroll_cont .webix_list_item:nth-child(2n)
{
  background-color: #f6f6f6;
}
.websocket_notification-header
{
  height: 18px;
  overflow: hidden;
  padding: 5px;
  padding-bottom: 0;
  font-size: 12px;
  line-height: 18px;
  height: 24px;
}
.websocket_notification-icon,.websocket_notification-event
{
  float: left;
  vertical-align: middle;
  display: inline-block;
  height: inherit;
}
.websocket_notification-event
{
  color: rgba(5, 133, 188, 1);
}

.websocket_notification-date
{
  float: right;
  height: inherit;
}

.websocket_notification-body
{
  padding: 2px 5px;
  height: 48px;
}

.websocket_notification-title{
  padding-bottom: 2px;
  font-weight: bold;
  line-height: 24px;
}

.websocket_notification-content{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 5px;
  line-height: 24px;
}

.websocket_notification-error{
  background-color: #fe4646;
}

.websocket_notification-success{
  background-color: #6ddd6d;
}

.websocket_notification-warning{
  background-color: #ffff009e;
}

.icon-error{
  color: #fe4646;
}

.icon-success{
  color: #aed581;
}

.icon-warning{
  color: #ffb74d;
}

.icon-info{
  color: inherit;
}

.websocket_notification-body-content{
  width: 95%;
  float: left;
  /*border-right: solid 1px #0c2b45;*/
}

.websocket_notification-body-buttons
{
  float: left;
  width: 16px;
  cursor: pointer;
  height: 48px;
}

/*.websocket_notification-body-buttons:hover*/
/*{*/
  /*background-color: rgba(41, 182, 246, 0.4);*/
/*}*/

.websocket_notification-body-buttons span
{
  /*margin: auto; */
  display: block;
  margin-top: 24px;
}

.webix_popup.webix_view.notification-suggest{
  border-radius: 10px;
  padding: 1px;
}

.webix_popup.webix_view.notification-suggest .webix_win_content{
  padding: 1px!important;
  margin-top:8px;
}

.webix_popup.webix_view.notification-suggest .webix_win_content .webix_win_body > .webix_view{
  width: 100%!important;
}

/*input:active, .webix_el_toggle input:active, .webixbutton input:active, .webix_el_button button:active, .webix_el_toggle button:active, .webixbutton button:active*/
.notifications-button .webix_el_button button:hover,.notifications-button .webix_el_button button:active{
  color:rgba(41, 182, 246, 0.2);
  background-color: #ffffff;
}

.notifications-button input:hover, .notifications-button input:hover, .notifications-button button:hover, .notifications-button button:hover, .notifications-button button:hover, .notifications-button input:active, .notifications-button input:active, .notifications-button input:active, .notifications-button button:active, .notifications-button button:active, .notifications-button button:active {
  color:rgba(41, 182, 246, 0.8);
  background-color: #ffffff;
  border: solid 1px rgba(41, 182, 246, 0.8);
  border-radius: 10px;
}

.notifications-button
{
  margin: auto!important;
}

.webix_message_area .webix_warning {
  background: #ffc166;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.webix_message_area .webix_warning div {
  padding: 10px 16px 12px 16px;
  border: none;
  background: none;
  color: black;
}

.socket_notif .webix_el_box button .webix_badge
{
  background-color: #ffb74d;
  color: #000000;
}

.notif-body {
  padding: 0 !important;
  margin: 0 !important;
}

.fir_tree_window
{
  box-shadow:none;
  background: linear-gradient(135deg, #FFFFFF00, #FFFFFFF0 30%, #FFFFFFFF);
  border-top-left-radius:100%;
}

.fir_tree_window .webix_view
{
  box-shadow:none;
  background:transparent;
}

.fir_tree_window .webix_win_head
{
  border-top:none;
}

.fir_tree_window .fir_tree
{
  background:url(/assets/imgs/fir_tree.png) no-repeat;
  background-position:0 -4px;
}

.fir_tree_window .fir_tree_en
{
  background:url(/assets/imgs/fir_tree_en.png) no-repeat;
}

.fir_tree_window .gift_btn .webix_icon_btn,
.fir_tree_window .gift_btn button.webix_img_btn .webix_icon_btn
{
  color:#1A84D8;
  font-size:32px;
  font-weight:100;
}

.fir_tree_window .gift_btn button.webix_img_btn
{
  border-color:transparent;
  background-color:rgba(255,255,255,0.7);
}
.fir_tree_window .gift_btn button.webix_img_btn:hover
{
    background-color: #1A84D8;
    color:white;
}

.fir_tree_window .gift_btn button.webix_img_btn:hover .webix_icon_btn
{
    color:white;
}

.santa_hat_window,
.ice_in_header_window
{
  box-shadow:none;
  background: transparent;
}

.santa_hat_window .webix_view,
.ice_in_header_window .webix_view
{
  box-shadow:none;
  background:transparent;
}

.santa_hat_window .webix_win_head,
.ice_in_header_window .webix_win_head
{
  border-top:none;
}

.ice_in_header_img
{
  height:100%;
  width:100%;
  background:url("/assets/imgs/ice.png") repeat-x;
}

.label_big_font,
.label_big_font .webix_icon
{
  font-size:72px;
}

.label_light_grey,
.label_light_grey .webix_icon,
.label_light_grey.webix_el_label
{
  color:rgba(41, 182, 246, 0.05);
}

.label_vas_color,
.label_vas_color .webix_icon,
.label_vas_color.webix_el_label
{
  color:#1A84D8;
  color:rgba(5, 133, 188, 1);
}

.label_solomon_color,
.label_solomon_color .webix_icon,
.label_solomon_color.webix_el_label
{
  color:#ff8a65;
}

.label_orange_color,
.label_orange_color .webix_icon,
.label_orange_color.webix_el_label
{
  color:#ff7043;
}

.label_warning_color,
.label_warning_color .webix_icon,
.label_warning_color.webix_el_label
{
  color:#ff9800;
}

.label_error_color,
.label_error_color .webix_icon,
.label_error_color.webix_el_label
{
  color:#fe4646;
}

.label_bold,
.label_bold .webix_icon,
.label_bold.webix_el_label
{
  font-weight:bold;
}

.label_thin,
.label_thin .webix_icon,
.label_thin.webix_el_label
{
  font-weight:100;
}

.btn_icon_spin .webix_icon_btn,
.label_icon_spin .webix_icon
{
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

.webix_disabled_view.webix_control.btn_icon_spin .webix_icon_btn
{
  color:#1A84D8;
}

.of-empty-folder
{
  height: 100%;
  position: relative;
}
.of-empty-folder .of-empty-pad
{
  width: 240px;
  height: 240px;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.01);
  border-radius: 50%;
  text-align: center;
  display: inline-block;
}
.of-empty-folder .of-empty-pad .of-empty-folder-bg
{
  position: absolute;
  font-size: 144px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-72px, -50%);
  color: lightgrey;
}
.of-empty-folder .of-empty-pad .of-empty-cloud-txt
{
  position: absolute;
  font-size: 24px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: lightgrey;
}

.messages-container {
  position: relative;
  width: 0;
  height: 0;
  overflow: visible;
}

.messages-container .webix_img_btn:hover {
  background-color: #ffffff;
  border-radius: 50%;
  color: #2f2f2f;
}

.messages-container .webix_img_btn:hover .webix_icon_btn
{
  color: #b2bac9;
}

.messages-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #eaeef5;
  color: #2f2f2f;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  pointer-events: all;
}

.messages-btn .webix_el_box .webix_img_btn
{
  border-radius: 50%;
  background-color: #606062;
}

.messages-btn .webix_el_box .webix_img_btn .webix_icon_btn
{
  color: #f6f6f6;
}

.messages-btn .webix_el_box .webix_img_btn:hover .webix_img_btn
{
  color: var(--color-red-light) !important;
}

.message_notif .webix_el_box button .webix_badge {
  display:grid;
  grid-template:1fr/minmax(max-content,max-content);
  justify-items: center;
  background-color: transparent;
  left:14px;
  top:0;
  height:12px;
  padding:0;
}

.message_notif .webix_el_box button .webix_badge .badge_inner {
  background-color: #606062;
  color: #f6f6f6;
  min-width:12px;
  box-sizing:border-box;
  padding: 0 2px;
  letter-spacing: -0.3px;
  border-radius:12px;
  font-size:9px;
  font-weight:normal;
  line-height: 12px;
}

.messages-list .webix_list_item
{
  margin-bottom: 0;
  border-bottom: 0;
}

.messages-list .webix_list_item:hover{
  background-color: inherit;
  /*padding: 0 16px;*/
  border: none;
  cursor: default;
}

.messages-list .webix_list_item.webix_selected
{
  background-color: #ffffff;
}

.messages
{
  background: inherit;
  color: #2f2f2f;
  cursor: pointer;
  border-radius: .5em;
  overflow: hidden;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: -webkit-transform 0.75s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0.75s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0.75s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.75s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  height: 80px;
  padding: 8px;
}

.messages-list .webix_scroll_cont .webix_list_item:nth-child(2n)
{
  background-color: #f6f6f6;
}

.messages-header
{
  height: 18px;
  overflow: hidden;
  padding: 5px;
  padding-bottom: 0;
  font-size: 12px;
  line-height: 18px;
  height: 24px;
}

.messages-icon
{
  float: left;
  vertical-align: middle;
  display: inline-block;
  height: inherit;
}

.messages-date
{
  float: right;
  height: inherit;
}

.messages-body
{
  padding: 0 5px;
  height: 48px;
}

.messages-content
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 5px;
  line-height: 24px;
}

.messages-body-content
{
  width: 95%;
  padding-top: 6px;
  float: left;
}

.messages-body-buttons
{
  float: left;
  width: 16px;
  cursor: pointer;
  height: 48px;
}

.messages-body-buttons span
{
  display: block;
  margin-top: 12px;
}

.webix_popup.webix_view.messages-suggest{
  border-radius: 10px;
  padding: 1px;
}

.webix_popup.webix_view.messages-suggest .webix_win_content{
  padding: 8px!important;
}

.webix_popup.webix_view.messages-suggest .webix_win_content .webix_win_body > .webix_view{
  width: 100%!important;
}

.messages-button .webix_el_button button:hover,.messages-button .webix_el_button button:active{
  color:rgba(41, 182, 246, 0.2);
  background-color: #ffffff;
}

.messages-button input:hover, .messages-button input:hover, .messages-button button:hover, .messages-button button:hover, .messages-button button:hover, .messages-button input:active, .messages-button input:active, .messages-button input:active, .messages-button button:active, .messages-button button:active, .messages-button button:active {
  color:rgba(41, 182, 246, 0.8);
  background-color: #ffffff;
  border: solid 1px rgba(41, 182, 246, 0.8);
  border-radius: 10px;
}

.messages-button
{
  margin: auto !important;
}

.message-content-label
{
  padding-left: 2px !important;
}

.message-content-info
{
  margin-left: 10px !important;
}

.sync_subs_btn .webix_badge
{
  height: 8px;
  min-width: 8px;
  right: 6px;
  top: 6px;
}

.sync_subs .label_vas_color::after,
.sync_subs_btn.label_vas_color .webix_badge
{
  background-color:#2998f0 !important;
}
.sync_subs .label_warning_color::after,
.sync_subs_btn.label_warning_color .webix_badge
{
  background-color:#ff9800 !important;
}
.sync_subs .label_error_color::after,
.sync_subs_btn.label_error_color .webix_badge
{
  background-color:#fc0033 !important;
}

.sync_subs .label_ok_color::after,
.sync_subs_btn.label_ok_color .webix_badge
{
  background-color:#aed581 !important;
}

.sync_subs_btn.webix_disabled_view .webix_badge
{
  /*color: #2998f0;*/
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 1;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  50% {
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    transform: scale(0.95);
    opacity: 0;
  }
}